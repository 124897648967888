import {Col, Row} from "react-bootstrap";
import {
    HiMiniArrowDown,
    HiMiniArrowDownRight,
    HiMiniArrowRight,
    HiMiniArrowUp,
    HiMiniArrowUpRight
} from "react-icons/hi2";
import {useTranslation} from "../../../Utils/UseTranslation";

const TrendArrows = () => {
    const {t} = useTranslation()
    return (
        <>
           <Row>
               <Col md={6}>
                   {t('trendArrows_text')}
               </Col>
               <Col md={6} className={'text-start ps-4'}>

                   <h6 className={'text-start '} ><HiMiniArrowUp className={'fs-4'}/> {t('trendArrows_up')}</h6>
                   <hr className={'text-orange my-3 opacity-100'}/>
                   <h6 className={'text-start '}><HiMiniArrowUpRight className={'fs-4'}/> {t('trendArrows_upRight')}</h6>
                   <hr className={'text-orange my-3 opacity-100'}/>
                   <h6 className={'text-start '}><HiMiniArrowRight className={'fs-4'}/> {t('trendArrows_right')}</h6>
                   <hr className={'text-orange my-3 opacity-100'}/>
                   <h6 className={'text-start '}><HiMiniArrowDownRight className={'fs-4'}/> {t('trendArrows_downRight')}</h6>
                   <hr className={'text-orange my-3 opacity-100'}/>
                   <h6 className={'text-start '}><HiMiniArrowDown className={'fs-4'}/> {t('trendArrows_down')}</h6>
               </Col>
           </Row>

        </>
    )
}
export default TrendArrows