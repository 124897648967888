import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import {useState} from "react";

const TargetsArrow = (props) => {
    const [dose, setDose]= useState()
    const handleUnitChange = (e) => {
        console.log('test')
        console.log(props.unit)
        const action = e.currentTarget.getAttribute('data-action')
        console.log(action)
        props.setUnitInitial(false)
      props.setIsChanged &&   props.setIsChanged(true)
        console.log('unit',props.unit, "initial", props.initialValue)
        if (action === 'grow') {
            props.setUnit(prevState => {
                if (props.unit === null ) {
                    return props.initialValue + 1
                } else {
                    return prevState + 1
                }
            })
            console.log(props.unit)
        } else if (action !== 'grow' && props.unit > props.initialValue) {
            props.setUnit(prevState => {
                if (props.unit === null) {
                    return props.initialValue - 1
                } else {
                    return prevState - 1
                }
            })
        } else if (props.action !== 'grow' && props.unit === (props.initialValue + 1)) {
            props.setUnitInitial(true)
        }
    }
    return (
        <div className={'mt-2 justify-content-around'}>
            <div className={'position-relative'}>
                    <span onClick={handleUnitChange} data-action={'grow'}>
                        <BiSolidUpArrow className={`arrowIcon `} style={{
                            fontSize: '50px',
                            color: 'var(--light-grey-2)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))',
                            cursor: 'pointer'
                        }}/>
                    <span className={'text-black fs-4 position-absolute'}
                          style={{left: '18px', top: '10px', cursor: 'pointer', userSelect: 'none'}}> + </span>

                    </span>

            </div>
            <div className={'position-relative'}>
                    <span onClick={handleUnitChange}>

                        <BiSolidDownArrow
                            className={`arrowIcon ${props.unit === props.initialValue && props.unitInitial && 'disabled'}`}
                            style={{
                                fontSize: '50px',
                                color: 'var(--light-grey-2)',
                                filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                            }}/>
                    <span className={'text-black fs-3 position-absolute'}
                          style={{
                              left: '20px',
                              top: '2px',
                              cursor: props.unit === props.initialValue ? 'pointer' : 'initial',
                              userSelect: 'none'
                          }}> - </span>

                    </span>

            </div>
        </div>
    )
}
export default TargetsArrow