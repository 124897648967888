import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import AdvancedSettings from "./CalculatorSettings/CalcSettingShow/AdvancedSettings";

const CalculatorSettingsPage = (props) => {
    const {t} = useTranslation()
    const containerRef = useRef(null);
    const [showPrevArrow, setShowPrevArrow] = useState(false);
    const [showNextArrow, setShowNextArrow] = useState(false);
    const [containerWidth, setContainerWidth] = useState(0); // Stato per la larghezza del contenitore


    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            setShowPrevArrow(container.scrollLeft > 0);
            setShowNextArrow(container.scrollLeft < container.scrollWidth - container.clientWidth);
        }
    }, [showPrevArrow, showNextArrow]);


    useEffect(() => {
        console.log(props.calculatorSettings)
    }, [props.calculatorSettings])

    return (

        <Row className={`mx-0 h-100 overflow-y-auto overflow-x-hidden mx-0 align-content-start`} ref={containerRef}>

            <>
                <Row className={'mx-0 px-0'}>
                <Col md={8} className={'align-items-center px-0 align-items-start text-start ps-2'}>
                    <b>  {t("insulinCalculatorSettings")}</b>
                </Col>

                <Col md={4} className={'text-end px-0 position-relative px-0'}>
                    <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                            style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                            onClick={() => {
                                props.navigateToComponent('settings')
                            }}>{t("ok")}</Button>
                </Col>
                </Row>

                {props.calculatorSettings.calculatorMode === 'easy' && (
                    <>
                        <Col md={12}
                             className={`align-content-start d-inline-flex flex-column col-md-12 justify-content-start `}>
                            <p className={'d-flex justify-content-between text-smaller'}>
                                <span className={'text-end d-block w-50'}>{t("mode")} :</span>
                                <span>{t(props.calculatorSettings.calculatorMode)}</span></p>
                            <p className={'d-flex justify-content-between text-smaller'}>
                                <span className={'text-end d-block w-50'}>{t("breakfast")} :</span>
                                <span> {t(props.calculatorSettings.easyMode.breakfast)} u</span></p>
                            <p className={'d-flex justify-content-between text-smaller'}>
                                <span className={'text-end d-block w-50'}>{t("lunch")} :</span>
                                <span> {t(props.calculatorSettings.easyMode.lunch)} u</span></p>
                            <p className={'d-flex justify-content-between text-smaller'}>
                                <span className={'text-end d-block w-50'}>{t("dinner")} :</span>
                                <span> {t(props.calculatorSettings.easyMode.dinner)} u</span></p>
                            <p className={'d-flex justify-content-between text-smaller'}>
                                <span className={'text-end d-block w-50'}>{t("correctionTarget")} :</span>
                                <span className={'text-end d-block w-50'}>
                                        {props.calculatorSettings.easyMode.correctionTarget && props.calculatorSettings.easyMode.correctionTarget.min &&
                                            props.calculatorSettings.easyMode.correctionTarget.min} to
                                    {props.calculatorSettings.easyMode.correctionTarget && props.calculatorSettings.easyMode.correctionTarget.max &&
                                        props.calculatorSettings.easyMode.correctionTarget.max}<br/>
                                    {t('dynamicValues.unit')}
                                    </span>
                            </p>
                            <p className={'d-flex justify-content-between text-smaller'}>
                                <span className={'text-end d-block w-50'}>{t("correctionFactor")} :</span>
                                <span>   {!props.calculatorSettings.easyMode.correctionFactor ? t('noCorrection') : props.calculatorSettings.easyMode.correctionFactor}</span>
                            </p>


                        </Col>
                    </>

                )}
                {props.calculatorSettings.calculatorMode === 'advanced' && (
                    <AdvancedSettings calculatorSettings={props.calculatorSettings}/>
                )}


            </>


        </Row>
    )
}

export default CalculatorSettingsPage