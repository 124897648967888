import {createBrowserRouter, createRoutesFromElements, Route, Navigate} from "react-router-dom";
import App from "../App";

import ProductUse from "../Pages/ProductUse/ProductUse";

import ReaderHomeScreen from "../Pages/Reader/ReaderHomeScreen";
import MoreInformation from "../Pages/MoreInformation/MoreInformation";
import Home from "../Pages/Home/Home";
import SystemOverview from "../Pages/ProductOverview/SystemOverview";
import GettingToKnow from "../Pages/ProductOverview/GettingToKnow";
import SensorGlucoseReading from "../Pages/ProductOverview/SensorGlucoseReading";
import SensorKit from "../Pages/ProductOverview/SensorKit";
import FirstTimeSetup from "../Pages/Reader/FirstTimeSetup";
import SettingAlarms from "../Pages/Reader/SettingAlarms";
import SettingReminders from "../Pages/Reader/SettingReminders";
import ChangeReaderSettings from "../Pages/Reader/ChangeReaderSettings";
import ReaderKit from "../Pages/ProductOverview/ReaderKit";
import FirstTimeAppSetup from "../Pages/App/FirstTimeAppSetup";
import AppAlarms from "../Pages/App/AppAlarms";
import SettingAppAlarms from "../Pages/App/SettingAppAlarms";
import SilentMode from "../Pages/App/SilentMode";
import SettingRemindersApp from "../Pages/App/SettingRemindersApp";
import ChangingAppSettings from "../Pages/App/ChangingAppSettings";
import ApplicationSiteSelection from "../Pages/Sensor/ApplicationSiteSelection";
import PreparingApplicationSite from "../Pages/Sensor/PreparingApplicationSite";
import ApplyingSensor from "../Pages/Sensor/ApplyingSensor";
import StartingSensor from "../Pages/Sensor/StartingSensor";
import StartingSensorWithApp from "../Pages/Sensor/StartingSensorWithApp";
import StartingSensorAndroid from "../Pages/Sensor/StartingSensorWithAppAndroid";
import RemovingSensor from "../Pages/Sensor/RemovingSensor";
import ReplacingSensor from "../Pages/Sensor/ReplacingSensor";
import CheckingGlucoseWSensor from "../Pages/ProductUse/CheckingGlucoseWSensor";
import UnderstaingReadings from "../Pages/ProductUse/UnderstaingReadings";
import UnderstaingReadingsApp from "../Pages/ProductUse/UnderstaingReadingsApp";
import UsingGlucoseAlarms from "../Pages/ProductUse/UsingGlucoseAlarms";
import TreatmentDecisionGuide from "../Pages/ProductUse/TreatmentDecisionGuide";
import TreatmentKnowledgeCheck from "../Pages/ProductUse/TreatmentKnowledgeCheck";
import CheckingGlucoseWStrip from "../Pages/ProductUse/CheckingGlucoseWStrip";
import ControlSolutionTesting from "../Pages/ProductUse/ControlSolutionTesting";
import LivingWithSystem from "../Pages/ProductUse/LivingWithSystem";
import ChargingReader from "../Pages/ProductUse/ChargingReader";
import ReaderSettingsInfo from "../Pages/ProductUse/ReaderSettingsInfo";
import AppSettings from "../Pages/ProductUse/AppSettings";
import Maintenance from "../Pages/ProductUse/Maintenance";
import AddingNotes from "../Pages/ProductUse/AddingNotes";
import ReviewingHistory from "../Pages/ProductUse/ReviewingHistory";
import ReviewingHistorySummary from "../Pages/ProductUse/ReviewingHistorySummary";
import ImportantInformations from "../Pages/MoreInformation/ImportantInformations";
import ReaderSymbols from "../Pages/MoreInformation/ReaderSymbols";
import AppSymbols from "../Pages/MoreInformation/AppSymbols";
import SystemSpecifications from "../Pages/MoreInformation/SystemSpecifications";
import AppHomeScreen from "../Pages/App/AppHomeScreen";
import AccessingHcp from "../Pages/Reader/AccessingHcp";
import UsingGlucoseAlarmsHiLo from "../Pages/ProductUse/UsingGlucoseAlarmsHILO";
import UsingSignalLoss from "../Pages/ProductUse/UsingSignalLoss";


export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={'/'} element={<App/>}>
            <Route path={'/home'} element={<Home/>}></Route>
            <Route path={'/choose-lang'} element={<Home/>}></Route>

            {/*Sezione Product Overview*/}
            <Route path={'/system-overview'} element={<SystemOverview/>}></Route>
            <Route path={'/getting-to-know-reader'} element={<GettingToKnow/>}></Route>
            <Route path={'/sensor-kit'} element={<SensorKit/>}></Route>
            <Route path={'/reader-kit'} element={<ReaderKit/>}></Route>
            <Route path={'/sensor-glucose-reading'} element={<SensorGlucoseReading/>}></Route>

            {/*Sezione ReaderHomeScreen*/}
            <Route path={'/first-time-reader-setup'} exact element={<FirstTimeSetup/>}></Route>
            <Route path={'/reader-homescreen'} exact element={<ReaderHomeScreen/>}></Route>
            <Route path={'/setting-alarms'} exact element={<SettingAlarms/>}></Route>
            <Route path={'/setting-reminders'} exact element={<SettingReminders/>}></Route>
            <Route path={'/change-reader-settings'} exact element={<ChangeReaderSettings/>}></Route>
            <Route path={'/professional-options'} element={<AccessingHcp/>}></Route>
            <Route path={'/product-use'} element={<ProductUse/>}></Route>
            <Route path={'/more-informations'} element={<MoreInformation/>}></Route>

            {/* Add Redirect for path '/' */}

            {/*Sezione app*/}
           {/* <Route path={'/first-time-app-setup'} exact element={<FirstTimeAppSetup/>}></Route>
            <Route path={'/app-home-screen'} exact element={<AppHomeScreen/>}></Route>
            <Route path={'/app-alarms'} exact element={<AppAlarms/>}></Route>
            <Route path={'/setting-app-alarms'} exact element={<SettingAppAlarms/>}></Route>
            <Route path={'/silent-mode'} exact element={<SilentMode/>}></Route>
            <Route path={'/app-setting-reminders'} exact element={<SettingRemindersApp/>}></Route>
            <Route path={'/changing-app-settings'} exact element={<ChangingAppSettings/>}></Route>*/}

            {/*Sezione Sensore */}
            <Route path={'/application-site-selection'} exact element={<ApplicationSiteSelection/>}></Route>
            <Route path={'/preparing-application-site'} exact element={<PreparingApplicationSite/>}></Route>
            <Route path={'/applying-sensor'} exact element={<ApplyingSensor/>}></Route>
            <Route path={'/starting-sensor'} exact element={<StartingSensor/>}></Route>
         {/*   <Route path={'/starting-sensor-app'} exact element={<StartingSensorWithApp/>}></Route>*/}
         {/*   <Route path={'/starting-sensor-app-android'} exact element={<StartingSensorAndroid/>}></Route>*/}
            <Route path={'/removing-sensor'} exact element={<RemovingSensor/>}></Route>
            <Route path={'/replacing-sensor'} exact element={<ReplacingSensor/>}></Route>

            {/*Sezione Product Use */}
            {/*<Route path={'/checking-glucose'} exact element={<CheckingGlucoseWSensor/>}></Route>*/}
            <Route path={'/understanding-readings'} exact element={<UnderstaingReadings/>}></Route>
        {/*    <Route path={'/understanding-readings-app'} exact element={<UnderstaingReadingsApp/>}></Route>*/}
            <Route path={'/using-glucose-alarms'} exact element={<UsingGlucoseAlarmsHiLo/>}></Route>
            <Route path={'/using-signalLoss-alarms'} exact element={<UsingSignalLoss/>}></Route>
            {/*<Route path={'/using-glucose-alarms'} exact element={<UsingGlucoseAlarms/>}></Route>*/}
            <Route path={'/treatment-decision-guide'} exact element={<TreatmentDecisionGuide />}></Route>
         {/*   <Route path={'/treatment-knowledge-check'} exact element={<TreatmentKnowledgeCheck />}></Route>*/}
            <Route path={'/checking-with-strip'} exact element={<CheckingGlucoseWStrip />}></Route>
            <Route path={'/control-solution-testing'} exact element={<ControlSolutionTesting />}></Route>
            <Route path={'/living-with-your-system'} exact element={<LivingWithSystem />}></Route>
            <Route path={'/charging-reader'} exact element={<ChargingReader />}></Route>
            <Route path={'/reader-settings-info'} exact element={<ReaderSettingsInfo />}></Route>
{/*            <Route path={'/app-settings-info'} exact element={<AppSettings />}></Route>*/}
            <Route path={'/maintenance'} exact element={<Maintenance />}></Route>
            <Route path={'/adding-notes'} exact element={<AddingNotes />}></Route>
            <Route path={'/reviewing-history'} exact element={<ReviewingHistory />}></Route>
            <Route path={'/reviewing-history-summary'} exact element={<ReviewingHistorySummary />}></Route>

            {/*More  Informations Section*/}
            <Route path={'/important-informations'} exact element={<ImportantInformations />}></Route>
            <Route path={'/reader-symbols'} exact element={<ReaderSymbols />}></Route>
{/*            <Route path={'/app-symbols'} exact element={<AppSymbols />}></Route>*/}
            <Route path={'/system-specifications'} exact element={<SystemSpecifications />}></Route>

            <Route path={'/'} element={<Navigate to={'/home'}/>}/>
        </Route>
    )
)