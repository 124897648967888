import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";

const AuthContext = React.createContext();

export const AuthContextProvider = (props) => {
    const navigate = useNavigate()
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated'));
    const [user, setUser] = useState([]);
    const [jwt, setJwt] = useState('');
    const [expiration, setExpiration] = useState();

    const login = (user, jwt, expiration) => {
        setUser(user);
        setJwt(jwt);
        setExpiration(expiration);

        if (!!jwt && !!expiration) {
            localStorage.setItem('jwt', jwt);
            localStorage.setItem('user', user)
            localStorage.setItem('isAuthenticated', true);
            localStorage.setItem('expiration', expiration)
            setIsAuthenticated(localStorage.getItem('isAuthenticated'));
        }
    }

    const logout =() => {
        setIsAuthenticated(false);
        setUser(null);
        setJwt('');
        localStorage.clear();

        navigate('/')
    }

    //controlla lo stato del token, se è autenticato e se è un ruolo esistente per reindirizzare alla home
    useEffect(() => {
        let expiration = localStorage.getItem('expiration');
        let isAuthenticated = localStorage.getItem('isAuthenticated');
        let user = localStorage.getItem('user');

        if(isAuthenticated !== 'true' || expiration === null || new Date() > new Date(expiration)){
            logout()
        }
    }, []);

    return (
        <AuthContext.Provider value={{login, logout, isAuthenticated, user, jwt, expiration}}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContext