import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword  } from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_GOOGLE_LOGIN_API_KEY,
    authDomain: process.env.REACT_APP_GOOGLE_LOGIN_AUTH_DOMAIN
};

const tenantId = process.env.REACT_APP_GOOGLE_LOGIN_TENANT_ID

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp)
auth.tenantId = tenantId;

export { auth, signInWithEmailAndPassword };
export default firebaseApp;
