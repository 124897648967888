import {useTranslation} from "react-i18next";
import {Button, Col, Row} from "react-bootstrap";
import logo from '../../assets/img/fsl3Logo.png'
import roundBg from '../../assets/img/Home/round-bg.png'
import readerWithSensor from '../../assets/img/Home/fsl3WithSensor.png'
import readerWithSensorMmol from '../../assets/img/Home/fsl3WithSensor-mmol.png'
import {Link} from "react-router-dom";

const Home = () => {
    const {t, i18n} = useTranslation();
    return (

        <>
            <Row className={'mx-0 align-content-stretch mx-0 px-0'} style={{

                zIndex: '10',
                background: 'linear-gradient(180deg, #FFD100 0%,  #FFF0AA 97%)'
            }}>

                <Col className={'justify-content-between flex-column d-inline-flex'} md={6} >

                    <Row></Row>
                    <Row className={'mx-0 align-self-center'}>
                        <Col className={'text-start'}>
                            <img src={t('dynamicValues.unit') === 'mg/dL' ? readerWithSensor : readerWithSensorMmol} className={'img-fluid w-75'}
                            /></Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className={'text-start'}>{t('homeAdc')}</p>
                        </Col></Row>
                </Col>
                <Col md={6} className={'d-flex  flex-column justify-content-center px-0 mx-0 roundBg'} style={{background:roundBg}}>
                    <Row className={'mt-5 h-100 justify-content-center'}>
                        <Col md={8} className={'offset-2 h-100 d-flex align-self-center flex-column justify-content-between px-0 mx-0 '}>
                            <Row></Row>
                            <Row className={'mx-0 text-center'}>
                                <img src={logo} className={'img-fluid'} style={{width: '300px'}}/>
                                {/*<h2 className={'text-grey text-start fs-1 fw-bold'}>{t('fsl3ReaderSimulator')}</h2>*/}
                                <Link to={'/system-overview'}
                                      className={'bkg-primary text-white border-0 py-3 text-center rounded-3 w-100'}>{t("startSimulation")}</Link>
                            </Row>


                            <Row className={'mx-0 justify-content-end '}> {/*<img src={abbotLogo} className={'img-fluid '}
                                                                               style={{width: '200px'}}/>*/}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* <Col md={12} className={'bkg-secondary text-end d-flex justify-content-end align-items-end'}>
                <p><img src={abbotLogo} className={'img-fluid '} style={{width: '300px'}}/></p>

            </Col>*/}

        </>

    )
}

export default Home