import {useState} from "react";
import {useTranslation} from "../../Utils/UseTranslation";
import {Col} from "react-bootstrap";

import {HiExclamationTriangle} from "react-icons/hi2";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import ReaderEmpty from "../../Components/Reader/ReaderEmpty";
import ModalNote from "../../Components/Modal/ModalNote";
import parse from "html-react-parser";
import ScreenContainerFirstTime from "../../Components/Reader/FirstTimeSetup/ScreenContainerFirstTime";


const SettingAlarms = () => {
    const {t} = useTranslation()
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [turnOnOff, setTurnOnOff] = useState(true)
    const [homeBtnBehavior, setHomeBtnBehavior] =useState()
    const [currentStep, setCurrentStep] = useState('homeScreen');
    const [fromSettingAlarms, setFromSettingAlarms]=useState(true)
    const handleClose = () => {
        setShow(false)
    }


    const settingList_1 = t('settingList_1', {
        settingsImageToken: '%SETTINGS_IMG%',
        settingsImageValue: '<img src="/icons/settings.png" />'
    });

    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("settingAlarms")}</h2>
                {/*<p>{t('infoList4_text_2', { measureUnit: i18next.t('dynamicValues.unita_misura')})}</p>*/}

                <p className={''}>{t("settingAlarmsText")}</p>

                <p className={'text-orange fw-bold mb-0 mt-2'}> {t("howTo")}</p>

                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                    <span>{settingList_1}</span>

                    <span>
                        <HiExclamationTriangle className={'fs-3 text-end text-orange'} onClick={() => {
                            setShow(true)
                            setTitle(t('caution'))
                            setText(<ul>
                                <li>
                                    {t('settingAlarmsCaution_1')}
                                </li>
                                <li>
                                    {t('settingAlarmsCaution_2')}
                                </li>
                                <li>
                                    {t('settingAlarmsCaution_3')}
                                </li>

                            </ul>)
                        }}/>
                    </span>


                </p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 '}>
                    <span>{t("settingAlarmsList_2")} </span>
                </p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0'}>{t("settingAlarmsList_3")}</p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                    <span>{t("settingAlarmsList_4")}</span>

                </p>
                <hr className={'text-orange my-1 opacity-100'}/>
            </Col>
            <Col md={6} className={'align-self-center'}>
                <ReaderEmpty turnOnOff={turnOnOff} setTurnOnOff={setTurnOnOff} homeBtnBehavior={homeBtnBehavior} setCurrentStep={setCurrentStep} currentStep={currentStep}>
                    {turnOnOff && <ScreenContainerFirstTime setHomeBtnBehavior={setHomeBtnBehavior} currentStep={currentStep} setCurrentStep={setCurrentStep} fromSettingAlarms={fromSettingAlarms}/>}

                </ReaderEmpty>

                <p className={'fw-bold text-start mt-4 text-smaller'}>{t('disclaimer')}</p>

            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/reader-homescreen'} nextUrl={'/setting-reminders'}/>
            </Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>

    )
}

export default SettingAlarms