import {Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReaderBottomButtons from "../../../../../Layout/Elements/ReaderBottomButtons";

const ReaderTestSteps = (props) => {
    return (

            <>
                <Col>
                    <b>{props.stepInfo.title}</b>
                </Col>
                <Col md={12} className={"mt-4 text-start d-flex justify-content-between"}>
                    <span className={'small'}>{props.stepInfo.text}</span> <span>{props.stepInfo.text2}</span>
                </Col>

                <ReaderBottomButtons hideBack={true} prevOnClick={()=>{
                    props.handleBackBtn()
                }} nextOnClick={() => {
                    // localStorage.setItem('isActive'+props.optionalSettingsAdv.timeOfDay, true)
                    props.handleButtonClick(props.stepInfo.nextStep)
                }
                } backBtnText={''} nextBtnText={props.stepInfo.buttonText} />
                {/*<Col md={6} className={"offset-6 align-self-end mb-2 px-0"}>
                    <Link onClick={() => props.handleButtonClick(props.stepInfo.nextStep)} className={"btn btn-primary w-100"}
                          to={""}>
                        {props.stepInfo.buttonText}
                    </Link>
                </Col>*/}
            </>

    )
}
export default ReaderTestSteps