import { Col, Form, Row } from "react-bootstrap";
import {useTranslation, withTranslation} from "react-i18next"; // Importa withTranslation
import i18n from "../../../../../i18n";
import {useState} from "react";

const LanguageSettings = (props) => {


    return (
        <Row className={`mx-0 h-100 text-start overflow-hidden`}>
            <Col md={8} className={'align-items-center'}>
                <b>{props.t('settings')}</b><br />

            </Col>
            <Col md={12} className={`align-content-start d-inline-flex flex-column col-md-12`}>
                <Row className={'align-items-center mx-0'}>
                    <Col md={12} className={'px-1'}>
                        <Form.Check type={'radio'} value={'en'} label={'en'} onClick={() => props.changeLanguage('en')} />
                        <Form.Check type={'radio'} value={'it'} label={'it'} onClick={() => props.changeLanguage('it')} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default withTranslation('reader')(LanguageSettings); // Utilizza withTranslation per isolare il contesto di traduzione