import {useTranslation} from "../../Utils/UseTranslation";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import './../../assets/css/Sensor/Sensor.css'
import ModalNote from "../../Components/Modal/ModalNote";
import {CiCircleInfo} from "react-icons/ci";
const StartingSensor = () => {
    const {t, i18n} = useTranslation()
    const [show, setShow]= useState(false);
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState(null);

    useEffect(() => {
        setImage(`/translations/${lang}/Sensor/reader_scanning_readerscreen.png`)
    }, [lang])
    useEffect(() => {
        console.log(image)
    }, [image])
    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("sensorStart")}</h2>
                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{t("sensorStart_Text")}  </span>

                </p>
                <p className={'text-orange fw-bold mb-0 mt-4'}> {t("howToWithReader")}</p>
                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{t("sensorStart_List_1")}  </span>
                </p>
                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{t("sensorStart_List_2")}  </span>

                </p>
                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{t("sensorStart_List_3")}  </span>
                    <span>
                         <CiCircleInfo className={'fs-3 ms-2 text-end text-orange'} onClick={() => {
                             setShow(true)
                             setTitle(t('note'))
                             setText(t('sensorStart_Info'))
                         }}/>
                    </span>
                </p>

                <hr className={'text-orange my-1 opacity-100 mb-3'}/>


            </Col>
            <Col md={5} className={'align-self-start position-relative'}>

                <img src={image} className={'img-fluid'}/>
            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/applying-sensor'} nextUrl={'/removing-sensor'}/>
            </Col>
            <ModalNote show={show}  handleClose={handleClose} text={text} title={title}></ModalNote>
        </>


    )
}

export default StartingSensor