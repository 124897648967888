
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "../../Utils/UseTranslation";


const ReaderSpecifications = () => {
    const {t} = useTranslation()
    return (
        <>
            <Row >

                <Col md={6} className={'text-start justify-content-start ps-4 align-self-start'}>

                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("bloodAssayRange")}</span>
                        <span> {t("bloodAssayRange_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("bloodKetoneRange")}</span>
                        <span> {t("bloodKetoneRange_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("readerSize")}</span>
                        <span> {t("readerSize_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("readerWeight")}</span> <span> {t("readerWeight_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("readerPowerSource")}</span> <span> {t("readerPowerSource_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("readerBatteryLife")}</span>
                        <span> {t("readerBatteryLife_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("readerMemory")}</span> <span> {t("readerMemory_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("readerOperatingTemp")}</span> <span> {t("readerOperatingTemp_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>

                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("readerStorageTemp")}</span>
                        <span> {t("readerStorageTemp_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("operatingHumidity")}</span>
                        <span> {t("operatingHumidity_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("readerMoistureProtection")}</span> <span> {t("readerMoistureProtection_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("operatingStorageAltitude")}</span>    <span className={'text-end'}>  {t("operatingStorageAltitude_Text")}</span></p>


                </Col>
                <Col md={6} className={'text-start justify-content-start ps-4 align-self-start'}>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("readerDisplayTimeout")}</span>
                        <span className={'text-end'}> {t("readerDisplayTimeout_text")}</span></p>

                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("radioFrequency")}</span>
                        <span className={'text-end'}>  {t("readerRadioFrequencyText")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("dataPort")}</span>
                        <span> {t("dataPort_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("minimumCompRequire")}</span>
                        <span className={'text-end'}> {t("minimumCompRequire_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("meanServiceLife")}</span> <span> {t("meanServiceLife_text")}</span></p>
                   {/* <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>*/}
                   {/* <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("readerCleaning")}</span>    <span className={'text-end'}>  {t("readerCleaning_Text")}</span></p>*/}
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("powerAdapter")}</span>    <span className={'text-end'}>  {t("powerAdapter_Text")}</span></p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}><span
                        className={'fw-bold'}> {t("usbCable")}</span>    <span className={'text-end'}> {t("usbCable_text")}</span></p>
                </Col>
                <Col md={12}><p>{t("referToManual")}</p></Col>
                {/*<Col md={12}>
                    <p>{t("electromagneticComp")}</p>
                    <p>{t("electromagneticComp_Reader")}</p>
                    <p>{t("electromagneticComp_Sensor")}</p>

                    <ul>
                        <li>{t("electromagneticComp_List_1")}</li>
                        <li>{t("electromagneticComp_List_2")}</li>
                        <li>{t("electromagneticComp_List_3")}</li>
                        <li>{t("electromagneticComp_List_4")}</li>
                        <li>{t("electromagneticComp_List_5")}</li>
                        <li>{t("electromagneticComp_List_6")}</li>
                    </ul>
                    <p className={'fw-bold'}>{t("securityMeasureQuality")}</p>
                    <p>{t("securityMeasure")}</p>
                    <p>{t("securityMeasure_Text")}</p>
                    <p>{t("qualityOfService")}</p>
                    <p>{t("qualityOfService_Text_1")}</p>
                    <p>{t("qualityOfService_Text_2")}</p>
                    <p>{t("qualityOfService_Text_3")}</p>
                    <p>{t("qualityOfService_Text_4")}</p>

                </Col>*/}
            </Row>


        </>
    )
}

export default ReaderSpecifications