import AdvancedUnitRanges from "../Elements/AdvancedUnitRanges";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import {init} from "i18next";
import {Link} from "react-router-dom";
import SetupComplete from "./SetupComplete";
import ReaderBottomButtons from "../../../../../Layout/Elements/ReaderBottomButtons";

const CorrectionFactor = (props) => {


    const {t, i18n} = useTranslation();

    const [calcMode, setCalcMode] = useState(props.calculatorSettings && props.calculatorSettings.calculatorMode)
    const [unit, setUnit] = useState(
        props.calculatorSettings &&
        props.calculatorSettings[calcMode + 'Mode'].correctionFactor > 49
            ? props.calculatorSettings[calcMode + 'Mode'].correctionFactor
            : 49
    );
    const [initial, setInitial] = useState(true)
    const [setupComplete, setSetupComplete] = useState(false)

    useEffect(() => {
        setUnit(
            props.calculatorSettings &&
            props.calculatorSettings[calcMode + 'Mode'].correctionFactor > 49
                ? props.calculatorSettings[calcMode + 'Mode'].correctionFactor
                : 49
        );
        console.log(unit)
    }, [props.calculatorSettings]);
    const handleUnitChange = (e) => {
        setInitial(false)
        const action = e.currentTarget.getAttribute('data-action')
        if (action === 'grow') {
            setUnit(prevState => prevState + 1)
        } else if (action !== 'grow' && unit > 49) {
            setUnit(prevState => prevState - 1)
        } else if (action !== 'grow' && unit === 49) {
            setInitial(true)
        }

    }

    const handleSaveUnit = () => {

          localStorage.setItem(props.noteDetails, unit)
          props.navigateToComponent('notes')



    }
    console.log(props.noteDetails, props.noteDetails === 'food')
    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden align-content-stretch`}>
            {!setupComplete ? (<>
                <Col md={8} className={'align-items-center'}>
                    <b> {t('correctionFactor')} </b>
                </Col>
                <Col md={4} className={'text-end px-0 position-relative'}>
                    {localStorage.getItem('nextLink') === 'correctionFactor' ?  <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                                                                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                                                                        onClick={()=> {} }> ? </Button> :  <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                            style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                            onClick={handleSaveUnit}>OK</Button> }
                </Col>

                <Col md={12}
                     className={`align-items-center d-inline-flex justify-content-between flex-row col-md-12`}>
                    <div className={'mt-2 justify-content-around'}>
                        <div className={'position-relative'}>
                    <span onClick={handleUnitChange} data-action={'grow'}>
                        <BiSolidUpArrow style={{
                            fontSize: '50px',
                            color: 'var(--light-grey-2)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))',
                            cursor: 'pointer'
                        }}/>
                    <span className={'text-black fs-4 position-absolute'}
                          style={{left: '18px', top: '10px', cursor: 'pointer', userSelect: 'none'}}> + </span>

                    </span>

                        </div>
                        <div className={'position-relative'}>
                    <span onClick={handleUnitChange}>

                        <BiSolidDownArrow style={{
                            fontSize: '50px',
                            color: 'var(--light-grey-2)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                        }}/>
                    <span className={'text-black fs-3 position-absolute'}
                          style={{left: '20px', top: '2px', cursor: 'pointer', userSelect: 'none'}}> - </span>

                    </span>

                        </div>
                    </div>

                    <Row className={'align-items-center text-end'}>
                        <p className={'small mb-1'}>{t("insulinFor")}</p>
                        <Col md={8} className={'pe-0'}>
                            <h2 className={'text-end fw-bold '}
                                style={{fontSize: '70px'}}>

                                {props.calculatorSettings &&
                                props.calculatorSettings[calcMode + 'Mode'].correctionFactor === null && initial ? (
                                    '--'
                                ) : unit}</h2>
                        </Col>
                        <Col md={3} className={'pe-1 text-center'}>
                            <p className={'mb-0 px-1'}
                               style={{borderBottom: '1px solid #333'}}>{t("dynamicValues.splittedUnit.first")}</p>
                            <p className={'mb-0 px-1'}>{t("dynamicValues.splittedUnit.second")}</p>
                        </Col></Row>


                </Col>
                <ReaderBottomButtons hideNext={unit === null } prevOnClick={() =>{
                    // props.navigateToComponent('calculatorSetup')
                    props.navigateToComponent(props.previousComponent)
                }} nextOnClick={() => {
                    const updatedSettings = {...props.calculatorSettings};
                    // Modifichiamo solo la proprietà doseIncrement con il nuovo valore
                    updatedSettings[calcMode + 'Mode'].correctionFactor = unit;
                    // Aggiorniamo lo stato con il nuovo oggetto
                    props.setCalculatorSettings(updatedSettings);
                    {
                        (props.calculatorSettings.calculatorMode === 'easy') ? setSetupComplete(true) : props.navigateToComponent('setInsulinDuration')
                    }
                }
                } backBtnText={'back'} nextBtnText={'next'} />

            </>) : (<SetupComplete navigateToComponent={props.navigateToComponent} t={t}
                                   setSetupComplete={setSetupComplete}/>)}
        </Row>

    )
}

export default CorrectionFactor