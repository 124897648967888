import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import controlSolution_1 from "../../../assets/img/Reader/Settings/control_Solution_1.jpg";
import controlSolution_2 from "../../../assets/img/Reader/Settings/control_Solution_2.jpg";
import controlSolution_3 from "../../../assets/img/Reader/Settings/control_Solution_3.jpg";
import waiting_1 from "../../../assets/img/Reader/Settings/waiting1.jpg";
import waiting_2 from "../../../assets/img/Reader/Settings/waiting2.jpg";
import waiting_3 from "../../../assets/img/Reader/Settings/waiting3.jpg";
import {Button, Col, Row} from "react-bootstrap";
import {CiBatteryFull} from "react-icons/ci";
import sensorCommunicating from "../../../assets/img/Icons/SensorCommunicating_header.png";
import soundsONVibrOFF from "../../../assets/img/Icons/SoundONVibrationOff_header.png";
import sensorImg from "../../../assets/img/Reader/homeScreen/sensor.png";
import settings from "../../../assets/img/Icons/settings.png";
import {FaPencil} from "react-icons/fa6";

const CheckingAnimation = (props) => {
    const {t, i18n} = useTranslation();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const [foodIcon, setFoodIcon] = useState(false)
    const [insulinIcon, setInsulinIcon] = useState(false)
    const [image, setImage] = useState(null);

    const [loopImage, setLoopImage] = useState(false)
    let imageArray = [waiting_1, waiting_2, waiting_3, waiting_1, waiting_2, waiting_3];
    const intervalIdRef = useRef(null);

    const [currentHour, setCurrentHour] = useState(new Date().getHours());
    const [currentMinute, setCurrentMinute] = useState(new Date().getMinutes());

    console.log(props.sensorIsActive)
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentHour(new Date().getHours());
            setCurrentMinute(new Date().getMinutes());
        }, 60000); // Aggiorna ogni minuto

        return () => clearInterval(intervalId);
    }, []);

    const [repeatCount, setRepeatCount] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % imageArray.length);
        }, 1000); // Cambia immagine ogni 3 secondi

        // Salva intervalId nella variabile ref
        intervalIdRef.current = intervalId;

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {

        // Controlla se abbiamo visualizzato l'ultima immagine e ferma l'intervallo
        if (currentImageIndex >= imageArray.length - 1) {
            setTimeout(() => {
                if (repeatCount < 1) {
                    setCurrentImageIndex(3); // Ripeti le ultime tre immagini
                    setRepeatCount(prevCount => prevCount + 1);
                } else {
                    clearInterval(intervalIdRef.current);
                    setLoopImage(true);
                }
            }, 500);
        }
    }, [currentImageIndex, repeatCount]);


    return (
        <Row className={'mx-0 h-100 text-start justify-content-start align-items-start overflow-hidden'}>


            {!loopImage ? (
                <Col className={'px-0'}>
                    <img src={imageArray[currentImageIndex]} alt="Immagine" className={'img-fluid'}/>
                </Col>
            ) : (
                <>
                  <Row className={'bkg-ligth-grey-2 mx-0 py-1'}>
                      <Col md={8}><span className={'small me-2'}>{currentHour}:{currentMinute}</span>
                          <CiBatteryFull/>

                          {/* <Row className={'mx-0 text-start align-content-end'}>
                            <Col className={'px-0'}><img src={sensorImg} className={'img-fluid'}
                                                         style={{width: '20px'}}/>

                            </Col>

                        </Row>*/}
                      </Col>
                      <Col md={4} sm={4} xs={4} className={'px-1 text-end'}> <Button
                          className={`w-100 border-groove`}><FaPencil className={'text-white'}/></Button></Col>
                  </Row>
                    <Row className={'align-self-start mb-5'}>
                        <Col className={'d-flex flex-row justify-content-end align-items-center'}>
                            <h2 className={'d-inline-block mb-0'} style={{
                                fontSize: (props.isStatic && props.behavior[props.behaviorIndex] === 'reader_110') ? '40px' : '65px',
                                fontWeight: 800
                            }}><span>{t(`dynamicValues.inRange`)}</span>
                                {/*<FiArrowRight className={'fs-1 fw-bold'}/>*/}


                            < /h2>
                            <div className={'text-center'}>
                                <img src={'/icons/blood.png'}/><p className={'mb-0 px-1'}
                                                                  style={{borderBottom: '1px solid #333'}}>{t("dynamicValues.splittedUnit.first")}</p>
                                <p className={'mb-0 px-1'}>{t("dynamicValues.splittedUnit.second")}</p>
                            </div>
                        </Col>
                        {props.prevStep === 'notes' && (
                            <Row className={'position-relative mx-0'}>
                                <div style={{top: '10px', right: '25px'}}
                                     className={'position-absolute img-fluid'}>
                                    {foodIcon && <img src={'/icons/food.png'} className={'img-fluid'}/>}
                                    {insulinIcon &&
                                        <img src={'/icons/insulin.png'} className={'img-fluid'}/>}

                                </div>
                                <img src={image} className={'img-fluid'}/>
                            </Row>
                        )}

                    </Row>
                </>
            )}
        </Row>

    )
}

export default CheckingAnimation