import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import {HiOutlineBookOpen} from "react-icons/hi2";
import {BsGraphUp} from "react-icons/bs";
import {VscGraph} from "react-icons/vsc";
import {FiWifi} from "react-icons/fi";
import {TfiTarget} from "react-icons/tfi";
import {BsArrowDownSquare} from "react-icons/bs";
import {Link} from "react-router-dom";
import {CiLock} from "react-icons/ci";


const Settings = (props) => {
    const {t, i18n} = useTranslation();
    const lang = localStorage.getItem('lang');
    const [triggerRender, setTriggerRender] = useState(false); // Stato di trigger per forzare il re-rendering
    const [isDetail, setIsDetail] = useState(true)
    const [steps, setStep] = useState(1)
    console.log(props.calculatorOff)
    const handleNextStep = () => {
        setStep(prevStep => prevStep < 4 ? prevStep + 1 : prevStep)

    }

    const handlePrevStep = () => {
        setStep(prevStep => prevStep > 1 ? prevStep - 1 : prevStep)

    }

    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden align-content-stretch`}>
            <Col md={8} className={'align-items-center'}>
                <b> {t('settings')}</b>
            </Col>
            <Row className={'justify-content-center text-center mb-0 mx-0'}
                 style={steps === 1 ? {visibility: 'hidden'} : {}}>
                <div className={'text-center'}>
                           <span onClick={handlePrevStep}>
                                 <BiSolidUpArrow style={{
                                     fontSize: '30px',
                                     color: 'var(--light-blue)',
                                     filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                                 }}/>
                            </span>
                </div>
            </Row>

            <Col md={12}
                 className={`align-content-start d-inline-flex flex-column col-md-12 justify-content-between `}>

                {steps === 1 && (
                    <>
                        <Row className={'align-items-center mx-0 mt-3'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                      onClick={() => {
                                          props.navigateToComponent('alarmsSettings')
                                      }}>
                                   <span
                                       className={'small'}>{t("alarms")}</span></Link>

                            </Col>

                        </Row>
                        <Row className={'align-items-center mx-0  mt-3'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                      onClick={() => {
                                          props.navigateToComponent('soundsAndVibration')

                                      }}>
                                    <span
                                        className={'small'}>{t("soundVibr")}</span></Link>
                            </Col>

                        </Row>
                        <Row className={'align-items-center mx-0  mt-3 mb-3'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                      onClick={() => {
                                          props.navigateToComponent('timeDate')

                                      }}>
                                   <span
                                       className={'small'}> {t("timeDate")}</span></Link>
                            </Col>

                        </Row>


                    </>
                )}
                {steps === 2 && (
                    <>


                        <Row className={'align-items-center mx-0 mt-3'}>
                            <Col md={12} className={'px-1 '}>
                                <Link
                                    className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                    onClick={() => {
                                        props.navigateToComponent('reminderSettings')
                                    }}> <span
                                    className={'small'}>{t("reminders")}</span></Link>
                            </Col>

                        </Row>
                        <Row className={'align-items-center mx-0 mt-3'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                      onClick={() => {
                                          props.navigateToComponent('controlSolution')
                                      }}>
                                   <span
                                       className={'small'}> {t("controlSolution")} </span></Link>
                            </Col>

                        </Row>

                        <Row className={'align-items-center mx-0 mt-3 mb-3'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                      onClick={() => {
                                          props.navigateToComponent('language')
                                      }}>
                                   <span
                                       className={'small'}>{t("language")}</span></Link>
                            </Col>

                        </Row>


                    </>
                )}
                {steps === 3 &&
                    (

                        <>

                            <Row className={'align-items-center mx-0 mt-3'}>
                                <Col md={12} className={'px-1'}>
                                    <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                          onClick={() => {
                                              props.navigateToComponent('systemStatus')
                                          }}>
                                   <span
                                       className={'small'}>{t("systemStatus")}</span></Link>
                                </Col>

                            </Row>
                            <Row className={'align-items-center mx-0 mt-3'}>
                                <Col md={12} className={'px-1'}>
                                    <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                          onClick={() => {
                                              props.navigateToComponent('reportSettings')
                                          }}>

                                   <span
                                       className={'small'}>{t("reportSettings")} </span></Link>
                                </Col>

                            </Row>
                            {!props.calculatorOff && (
                                <Row className={'align-items-center mx-0 mt-3 mb-3'}>
                                    <Col md={12} className={'px-1'}>
                                        <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                              onClick={() => {
                                                  localStorage.removeItem('isFromAdv')
                                                  props.navigateToComponent('calculatorSettings')
                                              }}>
                                   <span
                                       className={'small'}>{t("calculatorSettings")}</span></Link>
                                    </Col>

                                </Row>
                            )}


                        </>
                    )}
                {
                    steps === 4 && (
                        (

                            <>


                                <Row className={'align-items-center mx-0 mt-3'}>
                                    <Col md={12} className={'px-1'}>
                                        <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                              onClick={() => {
                                                  props.setIsFromSettings('true')
                                                  props.navigateToComponent('arrowDesc')
                                              }}>
                                   <span
                                       className={'small'}>{t("readerBasics")}</span></Link>
                                    </Col>

                                </Row>
                                <Row className={'align-items-center mx-0 mt-3'}>
                                    <Col md={12} className={'px-1'}>
                                        <Link onClick={() => {
                                            props.navigateToComponent('reportingLimits')
                                        }} className={'btn btn-secondary w-100 text-black border-groove text-start'}>
                                   <span
                                       className={'small'}>{t("reportingLimits")} </span></Link>
                                    </Col>

                                </Row>
                                <Row className={'align-items-center mx-0 mt-3 mb-3'}>
                                    <Col md={12} className={'px-1'}>
                                        <Link className={'btn btn-secondary w-100 text-black border-groove text-start align-content-center'}
                                              onClick={() => {
                                                  props.navigateToComponent('professionalOptions')
                                              }}>
                                   <span
                                       className={'small'}>{t("professionalOptions")}</span> <span className={'text-end'}>{props.isFromProfessional &&  <CiLock className={'fs-4 '}/> }</span></Link>
                                    </Col>

                                </Row>

                            </>
                        )
                    )
                }

            </Col>
            <Row className={'justify-content-center text-center mb-0 mx-0'}
                 style={steps === 4 ? {visibility: 'hidden'} : {}}>
                <div className={'text-center'}>
                    <span onClick={handleNextStep}>
                    <BiSolidDownArrow style={{
                        fontSize: '30px',
                        color: 'var(--light-blue)',
                        filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                    }}/>

                        </span>

                </div>

            </Row>
            <div>{steps && (
                <p className={'text-end small mb-0'}>{steps} / 4 </p>
            )}</div>
        </Row>
    )
        ;

}

export default Settings