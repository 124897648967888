import './App.css';
import Layout from "./Components/Layout/Layout";
import React from "react";
import {AuthContextProvider} from "./Context/AuthContext";

function App() {
    return (
        <AuthContextProvider>
            <div className="App ">
                <Layout/>
            </div>

        </AuthContextProvider>
    );
}

export default App;
