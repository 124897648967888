import {Button, Col, Form, Row} from "react-bootstrap";
import {useState} from "react";
import {Link} from "react-router-dom";
import ReaderBottomButtons from "../../../../../Layout/Elements/ReaderBottomButtons";

const DoseIncrement = (props) => {
    const [doseInfo, setDoseInfo] = useState(false)
    const handleRadioChange = (value) => {
        // Cloniamo lo stato corrente per evitare la mutazione diretta dello stato
        const updatedSettings = {...props.calculatorSettings};
        // Modifichiamo solo la proprietà doseIncrement con il nuovo valore
        updatedSettings.doseIncrement = value;
        // Aggiorniamo lo stato con il nuovo oggetto
        props.setCalculatorSettings(updatedSettings);
    };

    return (
        !doseInfo ? (
            <Row className={`mx-0 h-100 text-start align-content-between overflow-hidden`}>
                <Col md={8} className={'align-items-center'}>
                    <b>{props.t('doseIncrement')}</b><br/>
                </Col>
                <Col md={4} className={'text-end px-0 position-relative px-0'}>
                    <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                            style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                            onClick={() => {
                                setDoseInfo(true)
                            }}> ? </Button>
                </Col>
                <Col md={12} className={`align-items-between d-inline-flex flex-column col-md-12 `}>
                    <Row className={'align-items-center mx-0'}>
                        <Col md={12} className={'px-1'}>
                            <Form.Check
                                type={'radio'}
                                value={'1'}
                                label={'1 ' + props.t("unit")}
                                onClick={() => handleRadioChange('1')}
                                checked={props.calculatorSettings.doseIncrement === '1'}
                            /> {}
                            <Form.Check
                                type={'radio'}
                                value={'0.5'}
                                label={'0.5 ' + props.t("unit")}
                                onClick={() => handleRadioChange('0.5')}
                                checked={props.calculatorSettings.doseIncrement === '0.5'}
                            />
                            {/* Aggiungi altri Form.Check per altri valori di doseIncrement se necessario */}
                        </Col>

                    </Row>
                </Col>
                <ReaderBottomButtons hideBack={true}  prevOnClick={''} nextOnClick={() => {
                    props.navigateToComponent('calculatorSettings')
                }
                } backBtnText={''} nextBtnText={'done'} />
               {/* <Col md={6} className={'offset-6 mb-2'}><Link onClick={() => {

                    props.navigateToComponent('calculatorSettings')
                }} className={'btn btn-primary w-100'} to={''}>{props.t('done')}</Link></Col>*/}
            </Row>
        ) : (<Row className={`mx-0 h-100 text-start align-content-start overflow-hidden`}>
            <Col md={8} className={'align-items-center'}>
                <b>{props.t('doseIncrement')}</b><br/>
            </Col>
            <Col md={4} className={'text-end px-0 position-relative px-0'}>
                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                        onClick={() => {
                         setDoseInfo(false)
                        }}> {props.t("ok")} </Button>
            </Col>
            <Col md={12} className={`align-content-start d-inline-flex flex-column col-md-12 mt-5`}>
                <p>{props.t("doseIncrementInfo")}</p>
            </Col>
        </Row>)

    );
}

export default DoseIncrement;