import {Button, Col, Row} from "react-bootstrap";
import {CiBatteryFull} from "react-icons/ci";
import sensorCommunicating from "../../../../assets/img/Icons/SensorCommunicating_header.png";
import soundsONVibrOFF from "../../../../assets/img/Icons/SoundONVibrationOff_header.png";
import sensorImg from "../../../../assets/img/Reader/homeScreen/sensor.png";
import settings from "../../../../assets/img/Icons/settings.png";
import {FaPencil} from "react-icons/fa6";
import {HiExclamationTriangle} from "react-icons/hi2";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

const HomeScreenHeader = (props) => {
    const [currentHour, setCurrentHour] = useState(new Date().getHours());
    const [currentMinute, setCurrentMinute] = useState(new Date().getMinutes());

    console.log(props.sensorIsActive)
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentHour(new Date().getHours());
            setCurrentMinute(new Date().getMinutes());
        }, 60000); // Aggiorna ogni minuto

        return () => clearInterval(intervalId);
    }, []);
    useEffect(() => {
        console.log('behavior', props.behavior && props.behavior[props.behaviorIndex], props.behaviorIndex && props.behaviorIndex)
    }, [props.behaviorIndex && props.behaviorIndex])

    return (
        <Row
            className={'bg-light align-items-center justify-content-stretch align-self-end text-light-grey-2 shadow-sm pb-1'}>
        {(!props.behavior || props.isStatic ) &&
                <Col md={8}><span className={'small me-2'}>{currentHour}:{currentMinute}</span>
                    <CiBatteryFull/>
                    <img src={sensorCommunicating} className={'img-fluid'} style={{width: '20px'}}/>
                    <img src={soundsONVibrOFF} className={'img-fluid'} style={{width: '20px'}}/>
                    <Row className={'mx-0 text-start align-content-end'}>
                        <Col className={'px-0'}><img src={sensorImg} className={'img-fluid'}
                                                     style={{width: '20px'}}/>

                            {!props.sensorIsActive ? (<span
                                className={'text-smaller'}>{props.t('noActiveSensor')}</span>) : (
                                <span
                                    className={'text-smaller'}>{props.t('sensorEnd')}</span>
                            )}
                        </Col>

                    </Row>
                </Col>
            }
            {(props.behavior && !props.isStatic) && (
                props.behavior[props.behaviorIndex] === 'inRange' || props.behavior[props.behaviorIndex] === 'noActive') && ( (
                <Col md={8}><span className={'small me-2'}>{currentHour}:{currentMinute}</span>
                    <CiBatteryFull/>
                    <img src={sensorCommunicating} className={'img-fluid'} style={{width: '20px'}}/>
                    <img src={soundsONVibrOFF} className={'img-fluid'} style={{width: '20px'}}/>
                    <Row className={'mx-0 text-start align-content-end'}>
                        <Col className={'px-0'}><img src={sensorImg} className={'img-fluid'}
                                                     style={{width: '20px'}}/>

                            {(!props.sensorIsActive) ? (<span
                                className={'text-smaller'}>{props.t('noActiveSensor')}</span>) : (
                                <span
                                    className={'text-smaller'}>{props.t('sensorEnd')}</span>
                            )}
                        </Col>

                    </Row>
                </Col>
            ))}
            {!props.isStatic && props.behavior && props.behavior[props.behaviorIndex] !== 'inRange' && props.behavior && props.behavior[props.behaviorIndex] !== 'noActive' && (
                <Col md={8} sm={8} xs={8}  className={'px-1 py-1'}><Link
                    className={`px-0 w-100 btn btn-secondary border-groove text-black text-smaller`} style={{ pointerEvents : props.isFromAddingNotes && 'none', fontSize: props.isFromAddingNotes && '10px'}} onClick={ () => {props.navigateToComponent('glucoseAlerts')}}>
                    <HiExclamationTriangle className={'text-yellow fs-4'}/><span style={{fontSize: '11px'}}> {props.t(props.behavior[props.behaviorIndex])}</span>
                </Link>
                </Col>
            )}


            <Col md={4} sm={4}  xs={4} className={'px-1 text-end'}> {!props.isDetail  ? <span style={{ pointerEvents : props.isStatic && 'none'}} onClick={ () => {props.navigateToComponent('settings')}}>
                    <img src={settings} className={'img-fluid'}/> </span>:
                 props.behavior && props.behavior[props.behaviorIndex] !== 'noActive' &&  <Button className={`w-100 border-groove`}  onClick={() => {
                    !props.isStatic && props.navigateToComponent('notes')
                }}><FaPencil className={'text-white'} /></Button>}</Col>
        </Row>
    )
}

export default HomeScreenHeader