import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {Link} from "react-router-dom";
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import CustomSwitcher from "../../../../../Layout/Elements/CustomSwitcher";
import {use} from "i18next";
import UnitRangeOptional from "./UnitRangeOptional";
import TargetsArrow from "../../../../../Layout/Elements/TargetsArrow";
import ReaderBottomButtons from "../../../../../Layout/Elements/ReaderBottomButtons";

const AdvancedUnitRanges = (props) => {
    const {t, i18n} = useTranslation();
    const [unit, setUnit] = useState(props.unit);
    const [unitInitial, setUnitInitial] = useState(true)
    const [isInfo, setIsInfo] = useState(false)
    const [isChanged,setIsChanged]=useState(false)

    useEffect(() => {
        setUnit(props.unit)
        console.log(props.unit)
    }, [props.unit])
    console.log(props.typeMacro, props.type, props.typeToDelete, props.typeMacroToDelete, props.calculatorSettings.advancedMode[props.typeMacroToDelete])

    useEffect(() => {

            props.setCalculatorSettings({
                    ...props.calculatorSettings,
                    advancedMode: {
                        ...props.calculatorSettings.advancedMode,
                        [props.typeMacroToDelete]:null,
                        [props.typeToDelete]: {
                            morning: null,
                            evening:null,
                            midday:null,
                            night:null
                        },
                        [props.typeMacro]: unit
                    }
                }
            );
        console.log(props.calculatorSettings)


    }, [unit]);

    useEffect(() => {
        console.log(props.isByTime)
    }, [props.isByTime])
    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden align-content-stretch`}>
            {!isInfo ? (

                <>
                    {!props.isByTime && (
                        <>
                            <Col md={8} className={'align-items-center'}>
                                <b> {t(props.title)}</b>
                            </Col>

                            {!props.isAddictionalOptions ? (<Col md={4} className={'text-end px-0 position-relative'}>
                                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                        onClick={() => {
                                            setIsInfo(true)
                                        }}>?</Button>
                            </Col>) : (<Col md={4} className={'text-end px-0 position-relative'}>
                                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                        onClick={() => {
                                            localStorage.setItem('backFrom', props.title)
                                            props.setIsByTime(true)
                                            console.log(props.isByTime)

                                        }}>{t("ok")}</Button>
                            </Col>)}

                            <Col md={12}
                                 className={`align-items-start d-inline-flex mt-2 justify-content-between flex-row col-md-12`}>
                                <TargetsArrow unit={unit} setUnit={setUnit} initialValue={props.initialValue}
                                              setUnitInitial={setUnitInitial} unitInitial={unitInitial} setIsChanged={setIsChanged}/>
                                <div className={'mt-2 text-end justify-content-around'}>
                                    {props.prevUnitText && <span className={'small'}>{t(props.prevUnitText)}</span>}
                                    <h2>  {unit  === null ? '--' : unit}
                                        <br/>
                                        <span className={'fs-6'}>{t(props.unitText)}</span>
                                    </h2>
                                </div>


                            </Col>
                        </>)}
                    {props.hasOption &&
                        (

                            <>
                                <Col md={4}
                                     className={`align-items-start d-inline-flex mt-2 justify-content-between flex-row `}>

                                    <span className={'small'}> {props.t("optional")}</span>

                                </Col>
                                <Col md={8}
                                     className={`align-items-start d-inline-flex mt-2 justify-content-between flex-row `}>
                                    <Link
                                        className={'btn btn-secondary w-100 text-black border-groove text-center px-1 small'}
                                        onClick={() => {
                                            props.setOptionalSettingsAdv( {
                                                ...props.optionalSettingsAdv,
                                                title: props.title,
                                                prevUnitText: props.prevUnitText,
                                                unitText: props.unitText,
                                                type:props.type,
                                                typeMacro:props.typeMacro,

                                            })
                                            localStorage.removeItem('nextLink')
                                            localStorage.setItem('nextLink', props.nextLink)
                                            props.navigateToComponent('optionalRange')

                                        }}>

                                   <span
                                       className={'small'}> {props.t("byTime")}</span></Link>


                                </Col>

                            </>

                        )}

                    {!props.isByTime && (
                            <>
                                <ReaderBottomButtons hideNext={unit === null } prevOnClick={()=>{
                                    props.handleBackBtn()
                                }} nextOnClick={() => {

                                    props.setCalculatorSettings({
                                            ...props.calculatorSettings,
                                            advancedMode: {
                                                ...props.calculatorSettings.advancedMode,
                                                [props.typeMacroToDelete]:null,
                                                [props.typeToDelete]: {
                                                    morning: null,
                                                    evening:null,
                                                    midday:null,
                                                    night:null
                                                },
                                                [props.type]: {
                                                    morning: null,
                                                    evening:null,
                                                    midday:null,
                                                    night:null
                                                },
                                                [props.typeMacro]: unit
                                            }
                                        }
                                    );
                                    props.nextLink ?  props.navigateToComponent(props.nextLink) :  props.handleNextBtn()
                                }
                                } backBtnText={'back'} nextBtnText={'next'} />
                            </>

                    )}
                </>
            ) : isInfo && (
                <>
                    <Col md={8} className={'align-items-start px-0'}>
                        <p className={'text-start fw-bold px-2'}>  {t(props.infoTitle)}</p>
                    </Col>

                    <Col md={4} className={'text-end px-0 position-relative px-0'}>
                        <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                onClick={() => {
                                    setIsInfo(false)
                                }}>{t("ok")}</Button>
                    </Col>
                    <Col md={12}>
                        <p className={'text-start'}>{t(props.infoText)}</p>
                    </Col>
                </>
            )}

        </Row>

    )

        ;
}

export default AdvancedUnitRanges