import {Button, Col, Row} from "react-bootstrap";
import {BiSolidUpArrow} from "react-icons/bi";
import {BiSolidDownArrow} from "react-icons/bi";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ReaderBottomButtons from "../../../Layout/Elements/ReaderBottomButtons";

const DateSelection = (props) => {
    const {t, i18n} = useTranslation();
    const monthName = props.monthsFormat && props.monthsFormat.format(new Date(props.currentYear, props.currentMonth));
    console.log(monthName)
    const handlePrev = () => {
        if (props.currentMonth === 0) {
            props.setCurrentYear(prevYear => prevYear - 1);
            props.setCurrentMonth(11);
        } else {
            props.setCurrentMonth(prevMonth => prevMonth - 1);
        }
    };

    const handleNext = () => {
        if (props.currentMonth === 11) {
            props.setCurrentYear(prevYear => prevYear + 1);
            props.setCurrentMonth(0);
        } else {
            props.setCurrentMonth(prevMonth => prevMonth + 1);
        }
    };

    return (<Row className={'mx-0 h-100 overflow-hidden'}>

        {props.isFromSettings ?  (<>
            <Col md={8} className={'align-items-center px-0'}>
                <b>  {t("currentDate")}</b>
            </Col>

            <Col md={4} className={'text-end px-0 position-relative px-0'}>
                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                        onClick={() => {
                            props.navigateToComponent('timeDate')
                        }}>{t("ok")}</Button>
            </Col>
        </>) :   <p className={'fw-bold text-start'}>{t('currentDate')}</p>}
        <div className={'mt-2 d-flex justify-content-around w-100'}>

            <div className={'position-relative d-inline-flex'}>
                    <span onClick={() => {
                        props.setDay((prevDay) => (prevDay % 31) + 1);
                    }}>
                        <BiSolidUpArrow style={{
                            fontSize: '50px',
                            color: 'var(--light-grey-2)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))',
                            cursor: 'pointer'
                        }}/>
                    <span className={'text-black fs-4 position-absolute'}
                          style={{left: '18px', top: '10px', cursor: 'pointer', userSelect: 'none'}}> + </span>

                    </span>

            </div>

            <div className={'position-relative d-inline-flex'}>
                      <span onClick={handleNext}>
                    <BiSolidUpArrow style={{
                        fontSize: '50px',
                        color: 'var(--light-grey-2)',
                        filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                    }}/>

                    <span className={'text-black fs-4 position-absolute'}
                          style={{left: '18px', top: '10px', userSelect: 'none'}}> + </span>
                            </span>
            </div>
        </div>
        <div className={'mt-2 d-flex justify-content-around'}>
            <div className={'col-md-6 position-relative text-center'}>
                <h2 className={'fw-bold mb-0'}>{props.day}</h2>
            </div>

            <div className={'col-md-6 position-relative  text-center'}>
                <h5 className={'mb-0'}><span>   {monthName}
                    <br/>
                    {props.currentYear}</span></h5>
            </div>
        </div>

        <div className={'mt-2 d-flex justify-content-around'}>
            <div className={'position-relative d-inline-flex'}>
                    <span onClick={() => {
                        props.setDay((prevDay) => (prevDay === 1 ? 31 : prevDay - 1));
                    }}>

                        <BiSolidDownArrow style={{
                            fontSize: '50px',
                            color: 'var(--light-grey-2)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                        }}/>
                    <span className={'text-black fs-3 position-absolute'}
                          style={{left: '20px', top: '2px', cursor: 'pointer', userSelect: 'none'}}> - </span>

                    </span>

            </div>

            <div className={'position-relative d-inline-flex'}>
                    <span onClick={handlePrev}>
                    <BiSolidDownArrow style={{
                        fontSize: '50px',
                        color: 'var(--light-grey-2)',
                        filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                    }}/>
                <span className={'text-black fs-3 position-absolute'} style={{left: '20px', top: '2px'}}> - </span>
                        </span>
            </div>
        </div>
        {!props.isFromSettings &&
            <ReaderBottomButtons prevOnClick={''} nextOnClick={() => {props.navigateToComponent('time')}} backBtnText={''} nextBtnText={'next'} hideBack={true}/>
 /*           <Col md={6} className={'text-end offset-6 align-content-end mt-2'}>

            <Link onClick={() => {props.navigateToComponent('time')}} className={'btn btn-primary mb-0 w-100'} to={''}>{t('next')}</Link>
        </Col>*/
        }
    </Row>)
}

export default DateSelection