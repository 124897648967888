import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {Link} from "react-router-dom";
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import correctionTarget from "../CalculatorSettings/CorrectionTarget";
import ReaderBottomButtons from "../../../../../Layout/Elements/ReaderBottomButtons";

const TargetComponent = (props) => {

    const {t, i18n} = useTranslation();
    const [isInfo, setIsInfo] = useState(false)
    const handleRangeChange = (key, operation) => {
        // Ottieni il valore minimo e massimo preimpostati
        const lowMinValue = parseInt(t(props.lowMinValue));
        const highMinValue = parseInt(t(props.highMinValue));
        const lowHighRange = props.lowMaxRange && parseInt(t(props.lowMaxRange))
        const highHighRange = props.highMaxRange && parseInt(t(props.highMaxRange))
        console.log('valori minimi', highMinValue, lowMinValue)
        console.log('valori massimi', lowHighRange, highHighRange)
        console.log(props.targetRange[key])

        const newValue = operation === "increment"
            ? props.targetRange[key] +
            ((key === 'min' && props.incrementMinValue) ? props.incrementMinValue :
                ((key === 'max' && props.incrementMaxValue) ? props.incrementMaxValue : 1))
            : props.targetRange[key] - ((key === 'min' && props.incrementMinValue) ? props.incrementMinValue :
            ((key === 'max' && props.incrementMaxValue) ? props.incrementMaxValue : 1));


        // const updatedValue = key === "min" ?   Math.min(Math.max(newValue, lowMinValue), lowHighRange) : Math.min(Math.max(newValue, highMinValue), highHighRange)
        const updatedValue = key === "min" ? props.lowMaxRange ? Math.min(Math.max(newValue, lowMinValue), lowHighRange) : Math.max(newValue, lowMinValue) :  props.highMaxRange ? Math.min(Math.max(newValue, highMinValue), highHighRange) : Math.max(newValue, highMinValue);
        console.log(updatedValue)

        props.setTargetRange((prevRange) => ({
            ...prevRange,
            [key]: updatedValue
        }));

        /*Se sono nelle impostazioni del calcolatore Avanzate aggiorno anche lo stato coi dati del calcolatore*/
        if (props.isTargetRangeAdv) {
            console.log(props.type)
            props.setCalculatorSettings(prevSettings => ({
                ...prevSettings,
                advancedMode: {
                    ...prevSettings.advancedMode,
                    correctionTarget: null,
                    correctionByTime: {
                        morning: null,
                        midday: null,
                        evening: null,
                        night: null
                    },
                    [props.type]: {
                        ...prevSettings.advancedMode[props.type],
                        morning: null,
                        midday: null,
                        evening: null,
                        night: null
                    },
                    [props.typeMacro]: {
                        ...prevSettings.advancedMode[props.typeMacro],
                        [key]: updatedValue
                    },
                }
            }));
        }

    };

    useEffect(() => {

    }, [props.targetRange])
    return (
        <Row className={`mx-0 h-100 overflow-hidden ${isInfo && 'align-content-start'}`}>

            {!isInfo ? (
                <>
                    <Col md={8} className={'align-items-center px-0'}>
                        <b>  {t(props.title)}</b>
                    </Col>

                    <Col md={4} className={'text-end px-0 position-relative px-0'}>
                        <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                onClick={() => {
                                    setIsInfo(true)
                                }}>?</Button>
                    </Col>
                    <div className={'mt-2 d-flex justify-content-around align-items-center w-100'}>

                        <div className={'position-relative d-inline-flex'}>
                    <span onClick={() => handleRangeChange("min", "increment")}>
                        <BiSolidUpArrow style={{
                            fontSize: '50px',
                            color: 'var(--light-grey-2)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))',
                            cursor: 'pointer'
                        }}/>
                    <span className={'text-black fs-4 position-absolute'}
                          style={{left: '18px', top: '10px', cursor: 'pointer', userSelect: 'none'}}> + </span>

                    </span>

                        </div>
                        <div></div>
                        <div className={'position-relative d-inline-flex'}>
                      <span onClick={() => handleRangeChange("max", "increment")}>
                    <BiSolidUpArrow style={{
                        fontSize: '50px',
                        color: 'var(--light-grey-2)',
                        filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                    }}/>

                    <span className={'text-black fs-4 position-absolute'}
                          style={{left: '18px', top: '10px', userSelect: 'none'}}> + </span>
                            </span>
                        </div>
                        <div></div>
                    </div>
                    <div className={'mt-2 d-flex justify-content-around align-items-center'}>
                        <Col md={4} className={' position-relative text-center'}>

                            <h2 className={'fw-bold mb-0'}>{props.targetRange && props.targetRange.min} </h2>


                        </Col>
                        <Col md={1} className={' position-relative text-center'}>
                            <p className={'small mb-0'}>{t("to")}</p>
                        </Col>
                        <Col md={4} className={'position-relative  text-center'}>
                            <h2 className={'fw-bold mb-0'}> {props.targetRange && props.targetRange.max} </h2>

                        </Col>
                        <Col md={2} className={'position-relative  text-center'}>
                            <p className={'mb-0 fw-bold'}
                               style={{borderBottom: "1px solid #333"}}> {t("dynamicValues.splittedUnit.first")}</p>
                            <p className={'mb-0 fw-bold'}> {t("dynamicValues.splittedUnit.second")}</p>
                        </Col>
                    </div>

                    <div className={'d-flex justify-content-around align-items-center '}>
                        <div className={'position-relative d-inline-flex'}>
                    <span onClick={() => handleRangeChange("min", "decrement")}>

                        <BiSolidDownArrow o style={{
                            fontSize: '50px',
                            color: 'var(--light-grey-2)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                        }}/>
                    <span className={'text-black fs-3 position-absolute'}
                          style={{left: '20px', top: '2px', cursor: 'pointer', userSelect: 'none'}}> - </span>

                    </span>

                        </div>
                        <div></div>
                        <div className={'position-relative d-inline-flex'}>
                    <span onClick={() => handleRangeChange("max", "decrement")}>
                    <BiSolidDownArrow style={{
                        fontSize: '50px',
                        color: 'var(--light-grey-2)',
                        filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                    }}/>
                <span className={'text-black fs-3 position-absolute'} style={{left: '20px', top: '2px'}}> - </span>
                        </span>
                        </div>
                        <div></div>
                    </div>
                    {props.hasOption &&
                        (

                            <>
                                <Col md={4}
                                     className={`align-items-start d-inline-flex mt-2 justify-content-between flex-row `}>

                                    <span className={'small'}> {props.t("optional")}</span>

                                </Col>
                                <Col md={8}
                                     className={`align-items-start d-inline-flex mt-2 justify-content-between flex-row `}>
                                    <Link
                                        className={'btn btn-secondary w-100 text-black border-groove text-center px-1 small'}
                                        onClick={() => {
                                            props.setOptionalSettingsAdv( {
                                                ...props.optionalSettingsAdv,
                                                title: props.title,
                                                prevUnitText: props.prevUnitText,
                                                unitText: props.unitText,
                                                type:props.type,
                                                typeMacro:props.typeMacro,
                                                typeToDelete:props.typeToDelete
                                            })
                                            localStorage.removeItem('nextLink')
                                            localStorage.setItem('nextLink', props.nextLink)
                                            localStorage.setItem('isFromAdv', true)
                                            props.navigateToComponent('optionalRange')

                                        }}>

                                   <span
                                       className={'small'}> {props.t("byTime")}</span></Link>


                                </Col>

                            </>

                        )}
                    <ReaderBottomButtons hideBack={!props.showBack}  prevOnClick={()=>{
                        props.actionBack()
                    }} nextOnClick={() => {

                        props.actionTodo()
                    }
                    } backBtnText={props.backText} nextBtnText={props.btnText} />

                </>
            ) : (
                <>
                    <Col md={8} className={'align-items-start px-0'}>
                        <p className={'text-start fw-bold px-2'}>  {t(props.infoTitle)}</p>
                    </Col>

                    <Col md={4} className={'text-end px-0 position-relative px-0'}>
                        <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                onClick={() => {
                                    setIsInfo(false)
                                }}>{t("ok")}</Button>
                    </Col>
                    <Col md={12}>
                        <p className={'text-start'}>{t(props.infoText)}</p>
                    </Col>
                </>
            )}

        </Row>

    )
        ;
}

export default TargetComponent