import {useTranslation} from "../../Utils/UseTranslation";
import {useEffect, useState} from "react";
import parse from "html-react-parser";
import {Col, Row} from "react-bootstrap";
import {HiExclamationTriangle} from "react-icons/hi2";
import {CiCircleInfo} from "react-icons/ci";
import ModalNote from "../../Components/Modal/ModalNote";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import ReaderEmpty from "../../Components/Reader/ReaderEmpty";
import CheckingAnimation from "../../Components/ProductUse/CheckingGlucose/CheckingAnimation";
const CheckingGlucoseWStrip = () => {
    const {t, i18n} = useTranslation()
    const [turnOnOff, setTurnOnOff] = useState(false)
    const [homeBtnBehavior, setHomeBtnBehavior] = useState()
    const [currentStep, setCurrentStep] = useState('homeScreen');
    const lang = localStorage.getItem('lang');
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [step, setStep] = useState(1)
    const [prevUrl, setPrevUrl] = useState(step === 1 ? '/treatment-knowledge-check' : null)
    const [nextUrl, setNextUrl] = useState(step === 3 ? '/control-solution-testing' : null)
    const [video, setVideo] = useState(null);
    let checkingWithTestStrip3_Info_List = []

    for (let i = 1; i < 4; i++) {
        checkingWithTestStrip3_Info_List.push('checkingWithTestStrip3_Info_List_'+i)
    }
 const checkingWithTestStrip3_List_2 = t('checkingWithTestStrip3_List_2', {
     settingsImageToken: '%BLOOD%',
     settingsImageValue: '/icons/blood.png'
 });

    useEffect(() => {
        setVideo(`/translations/${lang}/video/blood-glucose-test.mp4`)
    }, [lang])
    useEffect(() => {
        console.log(video)
    }, [video])
    const handleClose = () => {
        setShow(false)
    }

    useEffect(() => {
        setPrevUrl(step === 1 ? '/treatment-decision-guide' : null)
        setNextUrl(step === 2 ? '/control-solution-testing' : null)
    }, [step])
    return (
        <>
            <Col md={12} className={'text-start'}>
                {step === 1 && (
                    <Row className={''}>
                        <h2> {t("checkingWithTestStrip")}</h2>
                        <p className={'mn-0'}>{t("checkingWithTestStrip_Text")}</p>
                        <Col md={6} className={'text-start'}>


                            <p className={'d-flex align-items-center justify-content-between text-orange fw-bold mb-0'}>{t("howTo")}
                                <HiExclamationTriangle className={'fs-3 text-end text-orange'} onClick={() => {
                                    setShow(true)
                                    setTitle(t('caution'))
                                    setText(<>
                                        <p>{t('checkingWithTestStrip_Warning')}</p>
                                       {/* <p>{t('intendedUse')}</p>
                                        <p>{t('intendedUse_Text')}</p>*/}
                                    </>)
                                }}/></p>
                            <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                            <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                                <span>{t("checkingWithTestStrip_List_1")}  </span>
                                {/*<span>      <CiCircleInfo
                                    className={'fs-3 ms-2 text-end text-orange'} onClick={() => {
                                    setShow(true)
                                    setTitle(t('note'))
                                    setText(t('checkingWithTestStrip_Info_1'))
                                }}/></span>*/}
                            </p>
                            <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                            <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                                <span>{t("checkingWithTestStrip_List_2")}  </span></p>
                            <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                            <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                                <span>{t("checkingWithTestStrip_List_3")}  </span></p>
                            <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                            <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                                <span>{t("checkingWithTestStrip_List_4")}  </span>
                                {/*<span>      <CiCircleInfo
                                    className={'fs-3 ms-2 text-end text-orange'} onClick={() => {
                                    setShow(true)
                                    setTitle(t('note'))
                                    setText(t('checkingWithTestStrip_Info_2'))
                                }}/></span>*/}
                            </p>

                        </Col>
                        <Col md={6} className={'align-self-start position-relative'}>

                            {video && <video controls height={'auto'} width={'100%'}>

                                <source src={video} type="video/mp4"/>
                                Il tuo browser non supporta il tag video.
                            </video>}


                        </Col>


                        <ModalNote dimension={'modal-lg'} show={show} handleClose={handleClose} text={text}
                                   title={title}></ModalNote>

                    </Row>
                )}

                {step === 2 && (
                    <Row className={''}>

                        <Col md={6} className={'text-start'}>

                            <h2> {t("checkingWithTestStrip")}</h2>
                            <p className={'d-flex align-items-center justify-content-between text-orange fw-bold mb-0'}>{t("howTo")}
                                <HiExclamationTriangle className={'fs-3 text-end text-orange'} onClick={() => {
                                    setShow(true)
                                    setTitle(t('caution'))
                                    setText(<>
                                        <p>{t('checkingWithTestStrip_Warning_1')}</p>
                                       {/* <p>{t('intendedUse')}</p>
                                        <p>{t('intendedUse_Text')}</p>*/}
                                    </>)
                                }}/></p>
                            <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                            <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                                <span>{parse(t("checkingWithTestStrip2_List_1"))}  </span>

                            </p>
                            <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                            <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                                <span>{parse(t("checkingWithTestStrip2_List_2"))}  </span>
                                <span>      <CiCircleInfo
                                    className={'fs-3 ms-2 text-end text-orange'} onClick={() => {
                                    setShow(true)
                                    setTitle(t('note'))
                                    setText(<ul>
                                        <li>{t('checkingWithTestStrip2_Info_1')}</li>
                                        <li>{t('checkingWithTestStrip2_Info_2')}</li>
                                    </ul>)
                                }}/></span>
                            </p>
                            <hr className={'text-orange my-1 opacity-100 mb-3'}/>

                                {/*<p className={'d-flex align-items-center justify-content-between text-orange fw-bold mb-0'}>{t("howTo")}
                                    <HiExclamationTriangle className={'fs-3 text-end text-orange'} onClick={() => {
                                        setShow(true)
                                        setTitle(t('caution'))
                                        setText(<>
                                            <p>{t('checkingWithTestStrip_Warning_2')}</p>
                                              <p>{t('intendedUse')}</p>
                                        <p>{t('intendedUse_Text')}</p>
                                        </>)
                                    }}/></p>*/}
                              {/*  <hr className={'text-orange my-1 opacity-100 mb-3'}/>*/}
                                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                                    <span>{parse(t("checkingWithTestStrip3_List_1"))}  </span>
                                    <span>      <CiCircleInfo
                                        className={'fs-3 ms-2 text-end text-orange'} onClick={() => {
                                        setShow(true)
                                        setTitle(t('note'))
                                        setText(<>
                                                <p>{t('checkingWithTestStrip3_Info_List')}</p>
                                                <ul> {checkingWithTestStrip3_Info_List.map((text, index) => {
                                                    return <li key={index}>{t(text)}</li>
                                                })} </ul>
                                            </>
                                        )
                                    }}/></span>
                                </p>

                                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                                    <span>{checkingWithTestStrip3_List_2}  </span>
                                </p>



                        </Col>
                        <Col md={6} className={'text-center align-items-start'}>
                            <ReaderEmpty turnOnOff={turnOnOff} setTurnOnOff={setTurnOnOff}
                                         homeBtnBehavior={homeBtnBehavior} setCurrentStep={setCurrentStep}
                                         currentStep={currentStep}>
                                {turnOnOff && <CheckingAnimation/>
                                }
                            </ReaderEmpty>

                            <p className={'fw-bold text-start mt-4 text-smaller'}>{t('disclaimer')}</p>

                        </Col>


                        <ModalNote show={show} handleClose={handleClose} text={text}
                                   title={title}></ModalNote>

                    </Row>
                )}
                {step === 3 && (
                    <Row className={''}>
                        <h2> {t("checkingWithTestStrip")}</h2>
                        <Col md={6} className={'text-start'}>


                            <p className={'d-flex align-items-center justify-content-between text-orange fw-bold mb-0'}>{t("howTo")}
                                <HiExclamationTriangle className={'fs-3 text-end text-orange'} onClick={() => {
                                    setShow(true)
                                    setTitle(t('caution'))
                                    setText(<>
                                        <p>{t('checkingWithTestStrip_Warning_2')}</p>
                                      {/*  <p>{t('intendedUse')}</p>
                                        <p>{t('intendedUse_Text')}</p>*/}
                                    </>)
                                }}/></p>
                            <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                            <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                                <span>{parse(t("checkingWithTestStrip3_List_1"))}  </span>
                                <span>      <CiCircleInfo
                                    className={'fs-3 ms-2 text-end text-orange'} onClick={() => {
                                    setShow(true)
                                    setTitle(t('note'))
                                    setText(<>
                                        <p>{t('checkingWithTestStrip3_Info_List')}</p>
                                        <ul> {checkingWithTestStrip3_Info_List.map((text, index) => {
                                            return <li key={index}>{t(text)}</li>
                                        })} </ul>
                                    </>
                                )
                                }}/></span>
                            </p>

                            <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                            <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                                <span>{checkingWithTestStrip3_List_2}  </span>
                            </p>

                        </Col>
                        <Col md={6} className={'align-self-start position-relative'}>
                            {/*   <img src={stringTesting} className={'img-fluid pe-2'}/>*/}




                        </Col>


                        <ModalNote dimension={`${step !== 3 ? 'modal-lg' : 'modal-xl'}`} show={show} handleClose={handleClose} text={text}
                                   title={title}></ModalNote>

                    </Row>
                )}
            </Col>
            <Col className={'text-start align-self-end'}>
                <NavigationButtons prevUrl={prevUrl} nextUrl={nextUrl} t={t}
                                   onClickPrev={() => {
                                       setStep(prevState => prevState - 1);

                                   }} onClickNext={() => {
                    if (step !== 3) {
                        setStep(prevState => prevState + 1)

                    }
                }}/>
            </Col>
        </>


    )
}

export default CheckingGlucoseWStrip