import {useTranslation} from "react-i18next";
import {Button, Col, Row} from "react-bootstrap";
import {HiExclamationTriangle} from "react-icons/hi2";
import {FiArrowDownRight} from "react-icons/fi";
import {Link} from "react-router-dom";
import loss from './../../assets/sounds/loss.mp3'
import high from './../../assets/sounds/high.mp3'
import low from './../../assets/sounds/low.mp3'
import useSound from "use-sound";
import {useEffect, useState} from "react";

const GlucoseAlarms = (props) => {
    const {t} = useTranslation()

    const [audio, setAudio] = useState(null);


    useEffect(() => {
        // Crea una nuova istanza di Audio quando props.alarmSound cambia
        if (props.alarmSound) {
            const newAudio = new Audio(getSoundPath(props.alarmSound));
            newAudio.play();

            // Aggiorna lo stato con la nuova istanza di Audio
            setAudio(newAudio);

            // Funzione di cleanup per fermare il suono quando il componente viene smontato
            return () => {
                newAudio.pause();
                setAudio(null); // Rimuovi l'istanza di Audio quando il suono è stato fermato
            };
        }
    }, [props.alarmSound]);

    // Funzione di utilità per ottenere il percorso del suono in base al tipo di allarme
    const getSoundPath = (alarmSound) => {
        switch (alarmSound) {
            case "low":
                return low;
            case "high":
                return high;
            case "loss":
                return loss;
            default:
                return null;
        }
    };

       /* useEffect(() => {
            let soundToPlay;
            if (props.alarmSound === 'low') {
                soundToPlay = low;
            } else if (props.alarmSound === 'high') {
                soundToPlay = high;
            } else {
                soundToPlay = loss;
            }


            playSound(soundToPlay); // Riproduci il nuovo suono
            console.log(playSound)
            // Pulisci l'effetto per interrompere il suono quando il componente si smonta o quando cambia alarmSound
            return () => {
                stop();
            };
        }, [props.alarmSound, playSound, stop]);*/
    return (
        <Row className={'mx-0 justify-content-between h-100'}>
            <Col md={12}>
                <HiExclamationTriangle className={'text-warning fs-4'}/>
                <span
                    className={'fw-bold'}> {t(props.alarmSound === 'low' ? 'lowAlarm' : props.alarmSound === 'high' ? 'highAlarm' : 'signalLossAlarm')}</span>
            </Col>
            <Row className={'fw-bold small mx-0'}>

                {props.alarmSound !== 'loss' ? (
                    <h2 className={'d-flex justify-content-center align-items-center fw-bold'}
                        style={{fontSize: '60px'}}>
                        {t(props.alarmSound === 'low' ? 'dynamicValues.usingAlarmsValues.low' : 'dynamicValues.usingAlarmsValues.high')}
                        <span
                            className={'fs-6 d-inline-flex flex-column'}> <FiArrowDownRight/> <span>{t('dynamicValues.splittedUnit.first')}
                            <hr className={'m-0'}/>
                            {t('dynamicValues.splittedUnit.second')}</span>
                                  </span></h2>
                ) : (
                    <Col><img src={'/icons/reader_screen_signal_loss_alarm.jpg'} className={'img-fluid'}/></Col>
                )}


            </Row>
            <Row className={'fw-bold small mx-0'}>
                <Col className={'text-center'}>

                    <Link className={' btn border-groove bkg-ligth-grey-2 text-black fw-400  w-100 py-1'}
                          style={{fontSize: '20px'}} onClick={() => {
                        props.setAlarmSound('')
                        props.navigateToComponent('homeScreen')

                    }}>{t("dismissAlarm")}</Link>
                </Col>
            </Row>


        </Row>


    )
}

export default GlucoseAlarms