import {Button, Col, Row} from "react-bootstrap";
import {BiSolidUpArrow} from "react-icons/bi";
import {BiSolidDownArrow} from "react-icons/bi";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ReaderBottomButtons from "../../../Layout/Elements/ReaderBottomButtons";

const TimeSelection = (props) => {
    const {t, i18n} = useTranslation();


    const handlePrevHour = () => {
        if (!props.fromReminder) {
            props.setCurrentHour(prevHour => (prevHour === 0 ? 23 : prevHour - 1));
        } else {
            props.setReminderHour(prevHour => (prevHour === 0 ? 23 : prevHour - 1))
        }

    };

    const handleNextHour = () => {
        if (!props.fromReminder) {
            props.setCurrentHour(prevHour => (prevHour === 23 ? 0 : prevHour + 1));
        } else {
            props.setReminderHour(prevHour => (prevHour === 23 ? 0 : prevHour + 1))
        }
    };

    const handlePrevMinute = () => {
        if (!props.fromReminder) {
            props.setCurrentMinute(prevMinute => (prevMinute === 0 ? 59 : prevMinute - 1));
        } else {
            props.setReminderMinute(prevMinute => (prevMinute === 0 ? 59 : prevMinute - 1))
        }
    };

    const handleNextMinute = () => {
        if (!props.fromReminder) {
            props.setCurrentMinute(prevMinute => (prevMinute === 59 ? 0 : prevMinute + 1));
        } else {
            props.setReminderMinute(prevMinute => (prevMinute === 59 ? 0 : prevMinute + 1))
        }

    };


    return (<Row className={'mx-0 h-100 overflow-hidden'}>

        {!props.isFromSettings ? (
            <p className={'fw-bold text-start'}>{t('currentTimeReader')}</p>
        ) : <>
            <Col md={8} className={'align-items-center px-0'}>
                {!props.fromReminder ? <b>  {t("currentDate")}</b> : <b>  {t("reminderTime")}</b>}
            </Col>

            <Col md={4} className={'text-end px-0 position-relative px-0'}>
                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                        onClick={() => {
                            props.fromReminder ? props.navigateToComponent('reminderSettings') : props.navigateToComponent('timeDate')
                        }}>{t("ok")}</Button>
            </Col>
        </>}
        <div className={'mt-2 d-flex justify-content-around w-100'}>

            <div className={'position-relative d-inline-flex'}>
                    <span onClick={handleNextHour}>
                        <BiSolidUpArrow style={{
                            fontSize: '50px',
                            color: 'var(--light-grey-2)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))',
                            cursor: 'pointer'
                        }}/>
                    <span className={'text-black fs-4 position-absolute'}
                          style={{left: '18px', top: '10px', cursor: 'pointer', userSelect: 'none'}}> + </span>

                    </span>

            </div>

            <div className={'position-relative d-inline-flex'}>
                      <span onClick={handleNextMinute}>
                    <BiSolidUpArrow style={{
                        fontSize: '50px',
                        color: 'var(--light-grey-2)',
                        filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                    }}/>

                    <span className={'text-black fs-4 position-absolute'}
                          style={{left: '18px', top: '10px', userSelect: 'none'}}> + </span>
                            </span>
            </div>
        </div>
        <div className={'mt-2 d-flex justify-content-around'}>
            <div className={'col-md-6 position-relative text-center'}>
                {!props.fromReminder ? (
                    <h2 className={'fw-bold mb-0'}>   {props.currentHour < 10 ? '0' + props.currentHour : props.currentHour}</h2>
                ) : (
                    <h2 className={'fw-bold mb-0'}>   {props.reminderHour < 10 ? '0' + props.reminderHour : props.reminderHour}</h2>
                )}


            </div>

            <div className={'col-md-6 position-relative  text-center'}>
                {!props.fromReminder ? (
                        <h2 className={'fw-bold mb-0'}>
                            {props.currentMinute < 10 ? '0' + props.currentMinute : props.currentMinute}</h2>
                    )
                    : (
                        <h2 className={'fw-bold mb-0'}>   {props.reminderMinute < 10 ? '0' + props.reminderMinute : props.reminderMinute}</h2>

                    )}

            </div>
        </div>

        <div className={'mt-2 d-flex justify-content-around'}>
            <div className={'position-relative d-inline-flex'}>
                    <span onClick={handlePrevHour}>

                        <BiSolidDownArrow style={{
                            fontSize: '50px',
                            color: 'var(--light-grey-2)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                        }}/>
                    <span className={'text-black fs-3 position-absolute'}
                          style={{left: '20px', top: '2px', cursor: 'pointer', userSelect: 'none'}}> - </span>

                    </span>

            </div>

            <div className={'position-relative d-inline-flex'}>
                    <span onClick={handlePrevMinute}>
                    <BiSolidDownArrow style={{
                        fontSize: '50px',
                        color: 'var(--light-grey-2)',
                        filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                    }}/>
                <span className={'text-black fs-3 position-absolute'} style={{left: '20px', top: '2px'}}> - </span>
                        </span>
            </div>
        </div>
        {!props.isFromSettings && (<>
            <ReaderBottomButtons prevOnClick={()=>{props.navigateToComponent('date')}} nextOnClick={() => {
                props.navigateToComponent('arrowDesc')
            }} backBtnText={'back'} nextBtnText={'next'} />

        </>)}
    </Row>)
}

export default TimeSelection