import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import {Col, Row} from "react-bootstrap";

const AdvancedSettings = (props) => {
    const {t} = useTranslation();
    console.log(props.calculatorSettings)
/*    const renderNestedItems = (nestedObject, mainLabel) => {
        return Object.entries(nestedObject).map(([period, value]) => {
            if (Object.keys(value).length !== 0) { // Verifica se l'oggetto nested non è vuoto
                const {min, max} = value;
                if (min !== null && max !== null) {
                    return {
                        label: `${mainLabel} - ${t(period)}`,
                        value: `${min}-${max} ${t('dynamicValues.unit')}`
                    };
                } else {
                    return null; // Ignora se min o max è null
                }
            } else {
                return null; // Ignora oggetto vuoto
            }
        }).filter(item => item !== null); // Rimuovi gli elementi null
    };*/
    const renderedItems = [
        {
            key: 'mode',
            label: t("mode"),
            value: t(props.calculatorSettings.calculatorMode),
            displayCondition: true
        },
        {
            key: 'activeInsulinSymbol',
            label: t("activeInsulinSymbol"),
            value: t(props.calculatorSettings.advancedMode.activeInsulinSymbol),
            displayCondition: true
        },
        {
            key: 'insulinDuration',
            label: t("insulinDuration"),
            value: props.calculatorSettings.advancedMode.insulinDuration.hrs && props.calculatorSettings.advancedMode.insulinDuration.min != null ?
                `${props.calculatorSettings.advancedMode.insulinDuration.hrs}:${props.calculatorSettings.advancedMode.insulinDuration.min} ${t("hrs")}` :
                "",
            displayCondition: props.calculatorSettings.advancedMode.insulinDuration.hrs && props.calculatorSettings.advancedMode.insulinDuration.min != null
        },
        {
            key: 'carbRatio',
            label: t("carbRatio"),
            value: (props.calculatorSettings.advancedMode.carbRatio === null && props.calculatorSettings.advancedMode.servingsOrCarboRatio !== 'servings') ? (
                Object.entries(props.calculatorSettings.advancedMode.carbRatioByTime).map(([period, value]) => {
                    console.log(period,value)
                    return {
                        label: `${t("carbRatio")} - ${t(period)}`,
                        value: value != null ? `${value} ${t('dynamicValues.unit')}` : (Object.values(props.calculatorSettings.advancedMode.carbRatioByTime).find(v => v !== null) + ` ${t('dynamicValues.unit')}`)
                    }
                })
            ) : (
                `1 ${t('unitFor')} ${props.calculatorSettings.advancedMode.carbRatio} ${t('g')}`
            ),
            displayCondition: props.calculatorSettings.advancedMode.carbRatio != null || Object.values(props.calculatorSettings.advancedMode.carbRatioByTime).some(v => v !== null)
        },
        {
            key: 'corTarget',
            label: t("corTarget"),
            value: props.calculatorSettings.advancedMode.correctionTarget === null ? (
                Object.entries(props.calculatorSettings.advancedMode.correctionByTime).map(([period, value]) => ({
                    label: `${t("corTarget")} - ${t(period)}`,
                    value: value != null ? `${value} ${t('dynamicValues.unit')}` : (Object.values(props.calculatorSettings.advancedMode.correctionByTime).find(v => v !== null) + ` ${t('dynamicValues.unit')}`)
                }))
            ) : (
                `1 ${t('unitFor')} ${props.calculatorSettings.advancedMode.correctionTarget} ${t('g')}`
            ),
            displayCondition: (props.calculatorSettings.advancedMode.servingsOrCarboRatio === "grams" || props.calculatorSettings.advancedMode.servingsOrCarboRatio === "carbo" && (props.calculatorSettings.advancedMode.correctionTarget !== null) || Object.values(props.calculatorSettings.advancedMode.correctionByTime).find(v => v !== null) )
        },
        {
            key: 'servingsRatio',
            label: t("servingsRatio"),
            value: (props.calculatorSettings.advancedMode.servingsRatio === null && props.calculatorSettings.advancedMode.servingsOrCarboRatio == 'servings') ? (
                Object.entries(props.calculatorSettings.advancedMode.servingsRatioByTime).map(([period, value]) => ({
                    label: `${t("servingsRatio")} - ${t(period)}`,
                    value: value !== null ? `${value} ${t('dynamicValues.unit')}` : (Object.values(props.calculatorSettings.advancedMode.servingsRatioByTime).find(v => v !== null) + ` ${t('dynamicValues.unit')}`)
                }))
            ) : (

                `1 ${t('unitFor')} ${props.calculatorSettings.advancedMode.servingsRatio} ${t('servings')}`
            ),
            displayCondition: props.calculatorSettings.advancedMode.servingsRatio !== null || props.calculatorSettings.advancedMode.servingsOrCarboRatio === "servings"
        },
        {
            key: 'correctionTargetRange',
            label: t("corTarget"),
            value: (props.calculatorSettings.advancedMode.correctionTargetRange.min === null || props.calculatorSettings.advancedMode.correctionTargetRange.max === null) ? (
                Object.entries(props.calculatorSettings.advancedMode.correctionTargetRangeByTime).map(([period, range]) => {
                    console.log(range)
                    if (range && range.min !== undefined && range.max !== undefined && range.min !== null && range.max !== null) {
                        return {
                            label: `${t("corTarget")} - ${t(period)}`,
                            value: `${range.min}-${range.max} ${t('dynamicValues.unit')}`
                        };
                    } else {
                        return {
                            label: `${t("corTarget")} - ${t(period)}`,
                            value: Object.values(range).find(v => {
                                console.log(v)
                                return `${v} ${t('dynamicValues.unit')}`
                            })
                        }
                    }
                    // return null; // Return null if range is undefined or range.min or range.max is null

                }).filter(item => item !== null) // Filter out null values
            ) : (
                `${props.calculatorSettings.advancedMode.correctionTargetRange.min}-${props.calculatorSettings.advancedMode.correctionTargetRange.max} ${t('dynamicValues.unit')}`
            ),
            displayCondition: (
                (props.calculatorSettings.advancedMode.correctionTargetRange.min !== null || props.calculatorSettings.advancedMode.correctionTargetRange.max !== null) ||
                Object.values(props.calculatorSettings.advancedMode.correctionTargetRangeByTime).some(range => range && range.min !== null && range.max !== null)
            )
        }
    ];
    const elementsPerStep = 5; // Numero desiderato di elementi per step
    
    let totalElements;
    let totalSteps;

    // Utilizza useState per definire step come variabile di stato
    const [step, setStep] = useState(1);

    // Funzione per renderizzare gli elementi
    // Funzione per renderizzare gli elementi per lo step corrente
    const renderItemsForStep = () => {
        // Calcola gli step e i sottostep con la condizione di visualizzazione vera
        const filteredItems = renderedItems.filter(item => item.displayCondition);

        // Costruisci un array di elementi da renderizzare per lo step corrente
        const renderedElements = [];

        // Itera su tutti gli elementi da mostrare
        for (let i = 0; i < filteredItems.length; i++) {
            const item = filteredItems[i];

            // Controlla se l'elemento ha subitems
            if (Array.isArray(item.value)) {
                // Se l'elemento ha subitems, aggiungi ciascun subitem separatamente
                for (let j = 0; j < item.value.length; j++) {
                    const subItem = item.value[j];
                    renderedElements.push(
                        <p key={`${item.key}-${subItem.label}`} className={'d-flex justify-content-between text-smaller mb-1'}>
                            <span style={{ fontSize: '12px' }} className={'text-end d-block w-50 break-world'}>{subItem.label}</span>
                            <span style={{ fontSize: '12px' }}>{subItem.value}</span>
                        </p>
                    );
                }
            } else {
                // Se l'elemento non ha subitems, aggiungi l'elemento
                renderedElements.push(
                    <p key={item.key} className={'d-flex justify-content-between text-smaller mb-1'}>
                        <span style={{ fontSize: '12px' }} className={'text-end d-block w-50 break-world'}>{item.label}</span>
                        <span style={{ fontSize: '12px' }}>{item.value}</span>
                    </p>
                );
            }
        }

        // Ora che abbiamo tutti gli elementi renderizzati, possiamo procedere con il calcolo degli step e dividere gli elementi
        totalElements = renderedElements.length;
        totalSteps = Math.ceil(totalElements / elementsPerStep);

        // Calcola l'indice di inizio e fine per gli elementi da visualizzare per lo step corrente
        const startIndex = (step - 1) * elementsPerStep;
        const endIndex = Math.min(step * elementsPerStep, totalElements);
        console.log(step, totalSteps, totalElements)
        // Ritorna solo gli elementi per lo step corrente
        return renderedElements.slice(startIndex, endIndex);
    };

    return (
        <>

            <Row className={'justify-content-center text-center mb-0 mx-0'}>
                <div className={'text-center'}>
                    {step > 1 && (
                        <span onClick={() => setStep(step - 1)}>
                            {/* Aggiungi l'icona per tornare indietro */}
                            <BiSolidUpArrow style={{
                                fontSize: '30px',
                                color: 'var(--light-blue)',
                                filter: 'drop-shadow(0px 0px 1px rgba(0, 0, 0, 1))'
                            }}/>
                        </span>
                    )}
                </div>
            </Row>
        <Row className={'mt-4 mx-0'}>

            {renderItemsForStep(renderedItems, step)}

        </Row>
            <Row className={'justify-content-center align-self-end text-center mb-0 mx-0 position-absolute bottom-0' }>
                <div className={'text-center'}>

                    {step < totalSteps && (
                        <span onClick={() => setStep(step + 1)}>
                            {/* Aggiungi l'icona per passare al prossimo step */}
                            <BiSolidDownArrow style={{
                                fontSize: '30px',
                                color: 'var(--light-blue)',
                                filter: 'drop-shadow(0px 0px 1px rgba(0, 0, 0, 1))'
                            }}/>
                        </span>
                    )}
                </div>
            </Row>

        </>

    );

};

export default AdvancedSettings;
