import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaChevronRight} from "react-icons/fa";

const MoreInformationNav = (props) => {
    return (
        <Row className={'bg-white shadow  mx-0 text-black p-2 text-start subMenu px-0'}>
            <Col md={12}>
                <ul className={'list-unstyled text-start py-0 mb-0'}>
                    <li className={'py-2 '}>
                        <Link to={'/important-informations'} onClick={props.handleClickInner}  className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('importantSafety')}
                          {/*  <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'py-2 '}>
                        <Link  to={'/reader-symbols'} onClick={props.handleClickInner}  c className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('readerSymbols')}
                          {/*  <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    {/*<li className={'py-2 '}>
                        <Link  to={'/app-symbols'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('appSymbols')}
                            <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>
                        </Link>
                    </li>*/}
                    <li className={'py-2 '}>
                        <Link  to={'/system-specifications'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('systemSpecifications')}
                          {/*  <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                </ul>
            </Col>

        </Row>
    )
}
export default MoreInformationNav