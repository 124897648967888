import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import {Link} from "react-router-dom";
import parse from "html-react-parser";
import ReaderBottomButtons from "../../../../../Layout/Elements/ReaderBottomButtons";

const UnitRangeOptional = (props) => {
    const {t, i18n} = useTranslation();
    const [unit, setUnit] = useState(props.unit);
    const [unitInitial, setUnitInitial] = useState(true)
    const [ratioSelect, setRatioSelect] = useState('')

    console.log(unit)
    useEffect(() => {
        setUnit(props.unit)
    }, [props.unit])

    useEffect(() => {
        console.log('ratio', ratioSelect)
        console.log('property', props.property)
    }, [props.property])

    useEffect(() => {
        console.log(props.calculatorSettings)
    }, [])
    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden align-content-stretch`}>

            <>
                <Col md={8} className={'align-items-center px-0'}>
                    <b className={'small'}> {t(props.title)}<br/>
                        {props.prevUnitText && <span className={''}>{t(props.prevUnitText)}</span>}</b>
                </Col>
                <Col md={4} className={'text-end px-0 position-relative'}>
                    <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                            style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                            onClick={() => {
                                props.setIsInfo(true)
                            }}>?</Button>
                </Col>

                <Col md={6} className={'offset-3 mt-1'}>
                    <Link
                        className={'btn btn-secondary w-100 text-black border-groove text-center px-1 small'}
                        onClick={(e) => {
                            e.preventDefault()
                            setRatioSelect('morning')
                            props.setOptionalSettingsAdv({
                                ...props.optionalSettingsAdv,
                                unit: props.property[`morning`],
                                initialValue: 14,
                                timeOfDay: 'morning'

                            })
                            props.navigateToComponent(localStorage.getItem('isFromAdv') ? 'optionalRangeTargetRange' : 'detailOptionalRange')

                        }}>
 <span className={'text-smaller d-block'}>
    {props.calculatorSettings.advancedMode[props.optionalSettingsAdv.type].morning !== null && (() => {
        const {morning} = props.calculatorSettings.advancedMode[props.optionalSettingsAdv.type];
        if (typeof morning === 'object' && morning !== null && morning.min !== null && morning.max !== null) {
            // Se morning è un oggetto e min e max non sono null
            return `${morning.min} - ${morning.max} ${parse(props.t('dynamicValues.unit'))}`;
        } else if (typeof morning !== 'object' && morning !== null) {
            // Se morning è un valore singolo
            return `${parse(morning + 'g ')}`;
        } else {
            // Se morning è null o non rientra in nessuno dei casi precedenti
            return '';
        }
    })()}
</span>
                        <span
                            className={'small'}> {props.t("morning")}</span></Link>

                </Col>

                <Col md={6} className={'mt-1'}>
                    <Link
                        className={'btn btn-secondary w-100 text-black border-groove text-center px-1 small'}
                        onClick={(e) => {
                            e.preventDefault()
                            setRatioSelect('midday')
                            props.setOptionalSettingsAdv({
                                ...props.optionalSettingsAdv,
                                unit: props.property[`midday`],
                                initialValue: 14,
                                timeOfDay: 'midday'

                            })
                            props.navigateToComponent(localStorage.getItem('isFromAdv') ? 'optionalRangeTargetRange' : 'detailOptionalRange')

                        }}>
                        <span className={'text-smaller d-block'}>
    {props.calculatorSettings.advancedMode[props.optionalSettingsAdv.type].midday !== null && (() => {
        const {midday} = props.calculatorSettings.advancedMode[props.optionalSettingsAdv.type];
        if (typeof midday === 'object' && midday !== null && midday.min !== null && midday.max !== null) {
            // Se midday è un oggetto e min e max non sono null
            return `${midday.min} - ${midday.max} ${parse(props.t('dynamicValues.unit'))}`;
        } else if (typeof midday !== 'object' && midday !== null) {
            // Se midday è un valore singolo
            return `${parse(midday + 'g')}`;
        } else {
            // Se midday è null o non rientra in nessuno dei casi precedenti
            return '';
        }
    })()}
</span>
                        <span
                            className={'small'}> {props.t("midday")}</span></Link>

                </Col>
                <Col md={6} className={'mt-1'}>
                    <Link
                        className={'btn btn-secondary w-100 text-black border-groove text-center px-1 small'}
                        onClick={(e) => {
                            e.preventDefault()
                            setRatioSelect('evening')
                            props.setOptionalSettingsAdv({
                                ...props.optionalSettingsAdv,
                                unit: props.property[`evening`],
                                initialValue: 14,
                                timeOfDay: 'evening'

                            })
                            props.navigateToComponent(localStorage.getItem('isFromAdv') ? 'optionalRangeTargetRange' : 'detailOptionalRange')

                        }}>
<span className={'text-smaller d-block'}>
    {props.calculatorSettings.advancedMode[props.optionalSettingsAdv.type].evening !== null && (() => {
        const {evening} = props.calculatorSettings.advancedMode[props.optionalSettingsAdv.type];
        if (typeof evening === 'object' && evening !== null && evening.min !== null && evening.max !== null) {
            // Se evening è un oggetto e min e max non sono null
            return `${evening.min} - ${evening.max} ${parse(props.t('dynamicValues.unit'))}`;
        } else if (typeof evening !== 'object' && evening !== null) {
            // Se evening è un valore singolo
            return `${parse(evening + 'g')} `;
        } else {
            // Se night è null o non rientra in nessuno dei casi precedenti
            return '';
        }
    })()}
</span>

                        <span
                            className={'small'}> {props.t("evening")}</span></Link>

                </Col>

                <Col md={6} className={'offset-3 mt-1'}>
                    <Link
                        className={'btn btn-secondary w-100 text-black border-groove text-center px-1 small'}
                        onClick={(e) => {
                            e.preventDefault()
                            setRatioSelect('night')
                            props.setOptionalSettingsAdv({
                                ...props.optionalSettingsAdv,
                                unit: props.property[`night`],
                                initialValue: 14,
                                timeOfDay: 'night'

                            })
                            props.navigateToComponent(localStorage.getItem('isFromAdv') ? 'optionalRangeTargetRange' : 'detailOptionalRange')

                        }}>
<span className={'text-smaller d-block'}>
    {props.calculatorSettings.advancedMode[props.optionalSettingsAdv.type].night !== null && (() => {
        const {night} = props.calculatorSettings.advancedMode[props.optionalSettingsAdv.type];
        if (typeof night === 'object' && night !== null && night.min !== null && night.max !== null) {
            // Se night è un oggetto e min e max non sono null
            return `${night.min} - ${night.max} ${parse(props.t('dynamicValues.unit'))}`;
        } else if (typeof night !== 'object' && night !== null) {
            // Se night è un valore singolo
            return `${parse(night + 'g')}`;
        } else {
            // Se night è null o non rientra in nessuno dei casi precedenti
            return '';
        }
    })()}
</span>

                        <span
                            className={'small'}> {props.t("night")}</span></Link>

                </Col>
                <ReaderBottomButtons hideNext={!(
                    localStorage.getItem('isActivenight') ||
                    localStorage.getItem('isActivemorning') ||
                    localStorage.getItem('isActivemidday') ||
                    localStorage.getItem('isActiveevening')
                )}
                                     prevOnClick={() => {
                                         localStorage.setItem('stepForAdvanced', 1)
                                         props.navigateToComponent(props.previousComponent)
                                     }
                                     }
                                     nextOnClick={() => {
                                         localStorage.removeItem('stepForAdvanced');
                                         localStorage.removeItem('isFromAdv');

                                        /* if ((props.optionalSettingsAdv.type === 'servingsDef' || props.optionalSettingsAdv.type === 'servingsRatio')) {
                                             localStorage.setItem('stepForAdvanced', 2);
                                             localStorage.setItem('stepForRatio', 1);

                                         }*/

                                         props.navigateToComponent(localStorage.getItem('nextLink'))

                                     }
                                     }
                                     backBtnText={'back'} nextBtnText={'done'}/>

            </>

        </Row>

    )

        ;
}

export default UnitRangeOptional