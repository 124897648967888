import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {Link} from "react-router-dom";
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import TargetComponent from "./Elements/TargetComponent";

const ReportSettings = (props) => {

    const {t, i18n} = useTranslation();
    const [isInfo, setIsInfo] = useState(false)

    return (
        <Row className={`mx-0 h-100 overflow-hidden ${isInfo && 'align-content-start'}`}>
            <TargetComponent lowMinValue={"dynamicValues.targetRangeSetup.min"}
                             highMinValue={"dynamicValues.targetRangeSetup.max"}
                             targetRange={props.targetRange} setTargetRange={props.setTargetRange}
                             title={"reportSettings"} actionTodo={() => {
                props.navigateToComponent('settings')
            }} infoText={'targetGlucoseRange_Text'} infoTitle={'targetGlucoseRange'} btnText={'done'}/>


        </Row>

    )
        ;
}

export default ReportSettings