import TargetComponent from "../Elements/TargetComponent";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AdvancedUnitRanges from "../Elements/AdvancedUnitRanges";
import parse from "html-react-parser";

const CorrectionTargetAdv = (props) => {
    const t = props.t
    const [step, setStep] = useState(!localStorage.getItem('stepForAdvanced') ? 0 : parseInt(localStorage.getItem('stepForAdvanced')));
    const [unit, setUnit] = useState(props.calculatorSettings && props.calculatorSettings.advancedMode.correctionTarget)
    const [type, setType] = useState(localStorage.getItem('correctionType'))
    const [targetValues, setTargetValues] = useState({
        min: props.calculatorSettings && props.calculatorSettings.advancedMode.correctionTargetRange.min ? props.calculatorSettings.advancedMode.correctionTargetRange.min : parseInt(parse(t('dynamicValues.correctionTarget.low'))),
        max: props.calculatorSettings && props.calculatorSettings.advancedMode.correctionTargetRange.max ? props.calculatorSettings.advancedMode.correctionTargetRange.max : parseInt(parse(t('dynamicValues.correctionTarget.high')))
    })
    const [isByTime, setIsByTime] = useState(false)
    console.log(step)
    const handleChoose = (e) => {
        const updateValue = {...props.calculatorSettings}
        updateValue.advancedMode.servingsOrCarboRatio = e.currentTarget.value
        props.setCalculatorSettings(updateValue)
        props.setFoodType(e.currentTarget.value)

        // console.log(props.calculatorSettings)
    }

    const handleBackBtn = () => {
        setStep(0)
        props.navigateToComponent(props.previousComponent)
    }
    useEffect(() => {
        console.log(props.foodType)
        console.log(props.property)

    }, [props.foodType])

    const handleNextBtn = () => {
        const updatedSettings = {...props.calculatorSettings};
        // Modifichiamo solo la proprietà doseIncrement con il nuovo valore
        updatedSettings.advancedMode.correctionTarget = unit;
        // Aggiorniamo lo stato con il nuovo oggetto
        props.setCalculatorSettings(updatedSettings);


    }

    useEffect(() => {
        console.log(props.optionalSettingsAdv)
    }, [props.optionalSettingsAdv])
    useEffect(() => {
        console.log(props.calculatorSettings)
    }, [props.calculatorSettings])
    return (
        type === 'singleTarget' ? (
                <AdvancedUnitRanges t={props.t} title={t(localStorage.getItem('advTitle'))} infoText={'carboRatioInfo'}
                                    unit={unit}
                                    initialValue={99} handleBackBtn={handleBackBtn}
                                    handleNextBtn={handleNextBtn} unitText={"dynamicValues.unit"}
                                    prevUnitText={''}
                                    type={'correctionByTime'}
                                    typeMacro={'correctionTarget'}
                                    hasOption={true} navigateToComponent={props.navigateToComponent}
                                    optionalSettingsAdv={props.optionalSettingsAdv}
                                    setOptionalSettingsAdv={props.setOptionalSettingsAdv}
                                    property={props.property} setStep={setStep} isByTime={isByTime}
                                    setIsByTime={setIsByTime}
                                    calculatorSettings={props.calculatorSettings}
                                    setCalculatorSettings={props.setCalculatorSettings}
                                    nextLink={'correctionFactor'}/>)
            :
            (

                <TargetComponent lowMinValue={"dynamicValues.targetRangeSetup.min"}
                                 highMinValue={"dynamicValues.targetRangeSetup.max"}
                                 targetRange={targetValues} setTargetRange={setTargetValues}
                                 title={"correctionTarget"} actionTodo={() => {
                    props.navigateToComponent('correctionFactor')
                }} infoText={'targetGlucoseRange_Text'} infoTitle={'targetGlucoseRange'} btnText={'done'}
                                 hasOption={true}
                                 navigateToComponent={props.navigateToComponent}
                                 optionalSettingsAdv={props.optionalSettingsAdv}
                                 calculatorSettings={props.calculatorSettings}
                                 setCalculatorSettings={props.setCalculatorSettings}
                                 setOptionalSettingsAdv={props.setOptionalSettingsAdv} t={t}
                                 type={'correctionTargetRangeByTime'}
                                 typeMacro={'correctionTargetRange'} nextLink={'correctionFactor'}
                                 isTargetRangeAdv={true} showBack={true} backText={'back'}
                                 actionBack={() => {
                                     props.navigateToComponent(props.previousComponent)
                                 }}/>
            )

    )
}
export default CorrectionTargetAdv