import AdvancedUnitRanges from "../Elements/AdvancedUnitRanges";
import {useEffect, useState} from "react";

const ServingsSetup = (props) => {
    const t = props.t
    const [step, setStep] = useState(0)
    const [unit, setUnit]=useState(props.calculatorSettings.advancedMode.servingDef ? props.calculatorSettings.advancedMode.servingDef : null)
    useEffect(() => {
        if (localStorage.hasOwnProperty('')) {
            setStep(localStorage.getItem(''))
        }
    })
    useEffect(() => {
        setUnit(props.calculatorSettings.advancedMode.servingDef ? props.calculatorSettings.advancedMode.servingDef : null)
    },[])
    return (
        step === 0 ? (
            <AdvancedUnitRanges t={t} title={t('servingsDefinition')} infoText={'servingsInfo'}
                                unit={unit}
                                initialValue={14} handleBackBtn={props.handleBackBtn}
                                handleNextBtn={() => {
                                    setStep(1)
                                }
                                } unitText={"gramsCarbs"}
                                prevUnitText={'servingsFor'}
                                type={'servingDef'}
                                typeMacro={'servingDef'}
                                navigateToComponent={props.navigateToComponent}
                                calculatorSettings={props.calculatorSettings}
                                setCalculatorSettings={props.setCalculatorSettings}
                                property={props.property} setStep={props.setStep}
                               />
        ) : step === 1 && (
            <AdvancedUnitRanges t={t} title={t('servingRatio')} infoText={'servingsInfo'}
                                unit={props.calculatorSettings.advancedMode.servingsRatio?? null}
                                initialValue={14} handleBackBtn={() => {setStep(0)}}
                               unitText={"unitsInsulin"}
                                prevUnitText={'forServing'}
                                type={'servingsRatioByTime'}
                                typeMacro={'servingsRatio'}
                                typeMacroToDelete={'carbRatio'}
                                typeToDelete={'carbRatioByTime'}
                                hasOption={true} navigateToComponent={props.navigateToComponent}
                                optionalSettingsAdv={props.optionalSettingsAdv}
                                calculatorSettings={props.calculatorSettings}
                                setCalculatorSettings={props.setCalculatorSettings}
                                setOptionalSettingsAdv={props.setOptionalSettingsAdv}
                                property={props.property} setStep={props.setStep} isByTime={props.isByTime}
                                setIsByTime={props.setIsByTime} nextLink={'howCorrect'}/>
        )


    )
}

export default ServingsSetup