import {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import AdvancedUnitRanges from "../Elements/AdvancedUnitRanges";
import UnitRangeOptional from "../Elements/UnitRangeOptional";
import ServingsSetup from "./ServingsSetup";
import ReaderBottomButtons from "../../../../../Layout/Elements/ReaderBottomButtons";

const AdvancedSettingsSetup = (props) => {
    const t = props.t
    const [step, setStep] = useState(!localStorage.hasOwnProperty('stepForAdvanced') ? 0 : parseInt(localStorage.getItem('stepForAdvanced')));
    const [unit, setUnit] = useState(props.calculatorSettings && props.calculatorSettings.advancedMode.servingsOrCarboRatio === 'grams' ? props.calculatorSettings.advancedMode.carbRatio : props.calculatorSettings.advancedMode.servingsRatio)

    const [isByTime, setIsByTime] = useState(false)
    console.log(step)
    const handleChoose = (e) => {
        const updateValue = {...props.calculatorSettings}
        updateValue.advancedMode.servingsOrCarboRatio = e.currentTarget.value
        props.setCalculatorSettings(updateValue)
        props.setFoodtype(e.currentTarget.value)

        // console.log(props.calculatorSettings)
    }

    const handleBackBtn = () => {
        setStep(0)
    }
    useEffect(() => {
        console.log(props.foodType)
        console.log(props.property)

    }, [props.foodType])
    useEffect(() => {
        setUnit(props.calculatorSettings &&  props.calculatorSettings.advancedMode.carbRatio)
    }, [unit])
    const handleNextBtn = () => {
        const updatedSettings = {...props.calculatorSettings};
        // Modifichiamo solo la proprietà doseIncrement con il nuovo valore
        updatedSettings.advancedMode.carbRatio = parseInt(localStorage.getItem('unitFor' + t('carbohydrateRatio')));
        // Aggiorniamo lo stato con il nuovo oggetto
        props.setCalculatorSettings(updatedSettings);

    }

    useEffect(() => {
        console.log(props.optionalSettingsAdv)
    }, [props.optionalSettingsAdv])
    return (
        <Row className={`mx-0 h-100 text-start overflow-hidden align-content-stretch`}>
            {step === 0 && (
                <>
                    <Col md={12} className={'align-items-start px-0'}>
                        <p className={'text-start fw-bold px-2'}>  {props.t('enterFood')}</p>
                    </Col>

                    <Col md={12} className={`align-content-start d-inline-flex flex-column col-md-12`}>
                        <Row className={'align-items-center mx-0'}>
                            <Col md={12} className={'px-1'}>
                                <Form.Check type={'radio'} defaultChecked={props.calculatorSettings.advancedMode.servingsOrCarboRatio !== 'servings'} name={'foodby'} value={'grams'}
                                            label={props.t('gramsOf')}
                                            onChange={handleChoose}/>
                                <Form.Check type={'radio'} defaultChecked={props.calculatorSettings.advancedMode.servingsOrCarboRatio === 'servings'} name={'foodby'} value={'servings'}
                                            label={props.t('servings')} onChange={handleChoose}/>
                            </Col>
                        </Row>
                    </Col>
                    <ReaderBottomButtons prevOnClick={() => {
                        props.navigateToComponent('calculatorSetup')
                    }} nextOnClick={() => {
                        // props.navigateToComponent('calculatorSettings')
                        props.foodType === 'grams' ? setStep(1) : setStep(2)
                    }
                    } backBtnText={'back'} nextBtnText={'next'}/>

                </>
            )}
            {step === 1 && (
                <>
                    <AdvancedUnitRanges t={t} title={t('carbohydrateRatio')} infoText={'carboRatioInfo'}
                                        unit={unit}
                                        initialValue={14} handleBackBtn={handleBackBtn}
                                        handleNextBtn={handleNextBtn} unitText={"gramsCarbs"}
                                        prevUnitText={'insulinFor'}
                                        type={'carbRatioByTime'}
                                        typeMacro={'carbRatio'}
                                        typeMacroToDelete={'servingsRatio'}
                                        typeToDelete={'servingsRatioByTime'}
                                        hasOption={true} navigateToComponent={props.navigateToComponent}
                                        optionalSettingsAdv={props.optionalSettingsAdv}
                                        calculatorSettings={props.calculatorSettings}
                                        setCalculatorSettings={props.setCalculatorSettings}
                                        setOptionalSettingsAdv={props.setOptionalSettingsAdv}
                                        property={props.property} setStep={setStep} isByTime={isByTime}
                                        setIsByTime={setIsByTime} nextLink={'howCorrect'}/>


                </>

            )}
            {step === 2 && (
                <ServingsSetup t={t} title={t('servingsDefinition')} infoText={'carboRatioInfo'}
                               initialValue={14} handleBackBtn={handleBackBtn} unitText={"gramsCarbs"}
                               prevUnitText={'insulinFor'}
                               hasOption={true} navigateToComponent={props.navigateToComponent}
                               optionalSettingsAdv={props.optionalSettingsAdv}
                               calculatorSettings={props.calculatorSettings}
                               setCalculatorSettings={props.setCalculatorSettings}
                               setOptionalSettingsAdv={props.setOptionalSettingsAdv}
                               property={props.property} setStep={setStep} isByTime={isByTime}
                               setIsByTime={setIsByTime} nextLink={'howCorrect'}/>
            )}

        </Row>


    )
}
export default AdvancedSettingsSetup