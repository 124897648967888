import {Button, Col, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import CustomSwitcher from "../../../../../Layout/Elements/CustomSwitcher";
import {useEffect, useState} from "react";
import ReaderBottomButtons from "../../../../../Layout/Elements/ReaderBottomButtons";

const CalculatorSetupOption = (props) => {
    const [active, setActive] = useState();
    const [next, setNext] = useState()
    useEffect(() => {
        console.log(props.calculatorSettings)
    }, [props.calculatorSettings])
    return (
        <Row className={`mx-0 h-100 text-start align-items-stretch overflow-hidden`}>
            {!next ? (

                <>
                    <Col md={8} className={'align-items-center'}>
                        <b>{props.t('chooseSetup')}</b><br/>
                    </Col>

                    <Col md={12} className={`align-items-between d-inline-flex flex-column col-md-12 `}>
                        <Row className={'align-items-center mx-0'}>
                            <Col md={12} className={'px-1'}>
                                <CustomSwitcher active={active} setActive={setActive}
                                                alarmActivated={props.alarmActivated}
                                                setAlarmActivated={props.setAlarmActivated}
                                                alarmType={'calculatorSetup'}
                                                setCalculatorSettings={props.setCalculatorSettings}
                                                calculatorSettings={props.calculatorSettings} label={"Easy"}/>

                            </Col>


                        </Row>
                        <Row>

                        </Row>
                    </Col>
                    <Col md={12} className={'mt-3 align-self-start'}>
                        <p className={'small'}>
                            {props.calculatorSettings.calculatorMode === 'advanced' ? props.t("chooseSetupAdv_Text") : props.t("chooseSetupEasy_Text")}
                        </p>
                    </Col>

                    <ReaderBottomButtons prevOnClick={() =>{
                        props.navigateToComponent('calculatorSettings')
                    }} nextOnClick={() => {
                        setNext(true)
                    }
                    } backBtnText={'back'} nextBtnText={'next'} />
                    {/*<Col md={6} className={' mb-2 align-self-end'}><Link onClick={() => {

                        props.navigateToComponent('calculatorSettings')
                    }} className={'btn btn-primary w-100'} to={''}>{props.t('back')}</Link></Col>
                    <Col md={6} className={' mb-2  align-self-end'}><Link onClick={() => {

                        setNext(true)
                    }} className={'btn btn-primary w-100'} to={''}>{props.t('next')}</Link></Col>*/}
                </>

            ) : (
                <>
                    <Col md={12} className={'mt-3 align-self-start'}>
                        <p className={''}>
                            {props.t("thisSetup_explanation")}
                        </p>
                        <p className={''}>
                            {props.t("thisSetup_explanation_1")}
                        </p>
                        <p className={''}>
                            {props.t("thisSetup_explanation_2")}
                        </p>
                    </Col>
                    <ReaderBottomButtons prevOnClick={()=>{
                        setNext(false)
                    }} nextOnClick={() => {
                        props.navigateToComponent(props.calculatorSettings.calculatorMode && props.calculatorSettings.calculatorMode === 'easy' ? 'easyModeSetup' : 'advancedModeSetup')
                         }
                    } backBtnText={'back'} nextBtnText={'next'} />
                    {/*<Col md={6} className={' mb-2 align-self-end'}><Link onClick={() => {

                        setNext(false)
                    }} className={'btn btn-primary w-100'} to={''}>{props.t('back')}</Link></Col>
                    <Col md={6} className={' mb-2  align-self-end'}><Link onClick={() => {

                        props.navigateToComponent(props.calculatorSettings.calculatorMode && props.calculatorSettings.calculatorMode === 'easy' ? 'easyModeSetup' : 'advancedModeSetup')

                    }} className={'btn btn-primary w-100'} to={''}>{props.t('next')}</Link></Col>*/}
                </>
            )
            }
        </Row>
    )

}
export default CalculatorSetupOption