import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {Link} from "react-router-dom";

const TimeAndDate = (props) => {

    const {t, i18n} = useTranslation();


    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden align-content-start`}>


            <Col md={8} className={'align-items-center'}>
                <b>{t('timeDate')}  </b>
            </Col>
            <Col md={4} className={'text-end px-0 position-relative'}>
                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}} onClick={() => {
                    props.navigateToComponent('settings')
                }}>OK</Button>
            </Col>
            <Col className={'d-flex justify-content-around flex-column mt-4'}>
                <Row className={'align-items-center mx-0'}>
                    <Col md={12} className={'px-1 mb-2'}>
                        <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                              onClick={() => {
                                  props.setIsFromSettings(true)
                                  props.navigateToComponent('time')

                              }}>
                            <p
                                className={'text-center mb-0'}>{props.currentHour}:{props.currentMinute}</p></Link>

                    </Col>

                </Row>
                <Row className={'align-items-center mx-0'}>
                    <Col md={12} className={'px-1'}>
                        <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                              onClick={() => {
                                    props.setIsFromSettings(true)
                                  props.navigateToComponent('date')
                              }}>
                            <p
                                className={'text-center mb-0 small'}>{props.day} {props.months && props.months.format(new Date(props.currentYear, props.currentMonth))} {props.currentYear}</p>
                        </Link>
                    </Col>

                </Row>
            </Col>

        </Row>

    )
        ;
}

export default TimeAndDate