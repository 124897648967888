import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import TargetsArrow from "../../../../../Layout/Elements/TargetsArrow";
import {Link} from "react-router-dom";
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import ReaderBottomButtons from "../../../../../Layout/Elements/ReaderBottomButtons";

const InsulinDuration = (props) => {

    const {t, i18n} = useTranslation();
    const setupValues = ['breakfast', 'lunch', 'dinner'];
    const [currentIndex, setCurrentIndex] = useState(0);

    const [unitInitial, setUnitInitial] = useState(true)
    const [isInfo, setIsInfo] = useState(false)
    console.log( props.calculatorSettings.advancedMode.insulinDuration)



    const [hours, setHours] = useState(props.calculatorSettings && props.calculatorSettings.advancedMode.insulinDuration.hrs !== null ? props.calculatorSettings.advancedMode.insulinDuration.hrs : 4);
    const [minutes, setMinutes] = useState(props.calculatorSettings && props.calculatorSettings.advancedMode.insulinDuration.min !== null ? props.calculatorSettings.advancedMode.insulinDuration.min : 30);
    useEffect(() => {
        setHours(props.calculatorSettings && props.calculatorSettings.advancedMode.insulinDuration.hrs !== null ? props.calculatorSettings.advancedMode.insulinDuration.hrs : 4);;
        setMinutes(props.calculatorSettings && props.calculatorSettings.advancedMode.insulinDuration.min !== null ? props.calculatorSettings.advancedMode.insulinDuration.min : 30);
    }, [])
    const incrementTime = () => {
        const newMinutes = (minutes + 15) % 60;
        const additionalHours = Math.floor((minutes + 15) / 60);
        const newHours = (hours + additionalHours) % 24;
        setMinutes(newMinutes);
        setHours(newHours);
    };

    const decrementTime = () => {
        const newMinutes = (minutes - 15 + 60) % 60;
        const minusOneHour = newMinutes === 45 ? 1 : 0;
        const newHours = (hours - minusOneHour + 24) % 24;
        setMinutes(newMinutes);
        setHours(newHours);
    };

    useEffect(()=> {
        props.setCalculatorSettings({
            ...props.calculatorSettings,
            advancedMode: {
                ...props.calculatorSettings.advancedMode,
                insulinDuration : {
                    ['hrs'] : hours,
                    ['min'] : minutes
                }
            }
        })
    }, [minutes,hours])


    const handleNextBtn = () => {
        console.log('unitFor' + [setupValues[currentIndex]])
        const updatedSettings = {...props.calculatorSettings};
        // Modifichiamo solo la proprietà doseIncrement con il nuovo valore
        updatedSettings.easyMode[setupValues[currentIndex]] = parseInt(localStorage.getItem('unitFor' + [setupValues[currentIndex]]));
        // Aggiorniamo lo stato con il nuovo oggetto
        props.setCalculatorSettings(updatedSettings);
        if (setupValues[currentIndex] === 'dinner') {
            props.setIsFromCalculator(true)
            // props.navigateToComponent('reportSettings')
            props.navigateToComponent('correctionTarget')
        } else {
            /*handleNext()*/
        }
    }
    const handleBackBtn = () => {
        if (setupValues[currentIndex] === 'breakfast') {
            props.navigateToComponent('calculatorSetup')
        } else {
           /* handlePrev()*/
        }
    }
    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden align-content-stretch`}>

            {!isInfo ? (

                <>
                    <Col md={8} className={'align-items-center'}>
                        <b> {t('insulinDuration')}</b>
                    </Col>

                    <Col md={4} className={'text-end px-0 position-relative'}>
                        <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                onClick={() => {
                                    setIsInfo(true)
                                }}>?</Button>
                    </Col>

                    <Col md={12}
                         className={`align-items-start d-inline-flex mt-2 justify-content-between flex-row col-md-12`}>
                        <div className={'mt-2 justify-content-around'}>
                            <div className={'position-relative'}>
                    <span onClick={incrementTime} data-action={'grow'}>
                        <BiSolidUpArrow className={`arrowIcon `} style={{
                            fontSize: '50px',
                            color: 'var(--light-grey-2)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))',
                            cursor: 'pointer'
                        }}/>
                    <span className={'text-black fs-4 position-absolute'}
                          style={{left: '18px', top: '10px', cursor: 'pointer', userSelect: 'none'}}> + </span>

                    </span>

                            </div>
                            <div className={'position-relative'}>
                    <span onClick={decrementTime}>

                        <BiSolidDownArrow
                            className={`arrowIcon`}
                            style={{
                                fontSize: '50px',
                                color: 'var(--light-grey-2)',
                                filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                            }}/>
                    <span className={'text-black fs-3 position-absolute'}
                          style={{
                              left: '20px',
                              top: '2px',

                              userSelect: 'none'
                          }}> - </span>

                    </span>

                            </div>
                        </div>
                        <div className={'mt-2 text-end justify-content-around'}>
                            <h2>  {hours}:{minutes}
                                <br/>
                                <span className={'fs-6'}>hrs:min</span>
                            </h2>
                        </div>


                    </Col>
                    <ReaderBottomButtons  prevOnClick={()=>{
                        props.navigateToComponent(props.previousComponent)
                    }} nextOnClick={() => {
                        props.navigateToComponent('insulinSymbol')
                    }
                    } backBtnText={'back'} nextBtnText={'next'} />

                </>
            ) : (
                <>
                    <Col md={8} className={'align-items-start px-0'}>

                    </Col>

                    <Col md={4} className={'text-end px-0 position-relative px-0'}>
                        <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                onClick={() => {
                                    setIsInfo(false)
                                }}>{t("ok")}</Button>
                    </Col>
                    <Col md={12}>
                        <p className={'text-start'}>{t('insulinDurationInfo')}</p>
                    </Col>
                </>
            )
            }

        </Row>

    )
        ;
}

export default InsulinDuration