import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {VscTarget} from "react-icons/vsc";
import {PiSyringeThin} from "react-icons/pi";
import {FaRegBell} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import ReminderTypeIcon from "./ReminderTypeIcon";

const ShowReminderType = (props) => {
    const {t, i18n} = useTranslation();
    return (<Col className={'d-flex justify-content-start flex-column'}>
            <Row>
                <Col>
                    <b>{t("reminderType")}</b>
                </Col>
            </Row>
            <Row className={'align-items-center mx-0 mt-4 mb-2'}>

                <Col md={12} className={'px-1 text-start'}>
                    <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                          onClick={() => {
                              props.setIsFromSettings(true)
                              props.setReminderType(t('checkGlucose'))
                              props.setShowType(false)

                          }}>
                        <p
                            className={'text-start mb-0 small'}><VscTarget
                            className={'fs-6'}/> {t('checkGlucose')}</p></Link>

                </Col>

            </Row>
            <Row className={'align-items-center mx-0  mb-2'}>

                <Col md={12} className={'px-1 '}>
                    <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                          onClick={() => {
                              props.setIsFromSettings(true)
                              props.setReminderType(t('takeInsulin'))
                              props.setShowType(false)
                          }}>
                        <p
                            className={'text-start mb-0 small'}><PiSyringeThin
                            className={'fs-6'}/> {t('takeInsulin')}</p>
                    </Link>
                </Col>

            </Row>
            <Row className={'align-items-center mx-0  mb-2'}>

                <Col md={12} className={'px-1'}>
                    <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                          onClick={() => {
                              props.setIsFromSettings(true)
                              props.setReminderType(t('other'))
                              props.setShowType(false)
                          }}>
                        <p
                            className={'text-start mb-0 small'}><FaRegBell
                            className={'fs-6'}/> {t('other')}</p>
                    </Link>
                </Col>

            </Row>

        </Col>
    )
}

export default ShowReminderType