import {Col, Row} from "react-bootstrap";

import sensorOverview from "../../assets/img/ProductOverview/SystemOverview/system_overview_lg.png";
import {useTranslation} from "../../Utils/UseTranslation";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import parse from "html-react-parser";

const SystemOverview = () => {
    const {t, i18n} = useTranslation()
    /*const parsedSystemOverviewList_4 =  t('systemOverviewList_4', {
        settingsImageToken: '%BLOOD_IMAGE%',
        settingsImageValue: '/icons/icona-sangue.png'
    });*/


    return (
        <>
            <Col md={7} className={'text-start ps-4'}>

                <h2> {t("systemOverviewTitle")}</h2>
                <p>{t("systemOverviewText_1")}</p>
                <p>{t("systemOverviewText_2")}</p>

            </Col>
            <Col md={5} className={'align-self-center'}>


                <Row className={'align-self-center'}>
                  <Col className={'align-self-end '}>
                    <img src={sensorOverview} className={'img-fluid w-10'}/>


                </Col>

                </Row>
            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/home'} nextUrl={'/getting-to-know-reader'}/>
            </Col>


        </>


    )
}

export default SystemOverview