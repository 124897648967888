import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";
import {VscTarget} from "react-icons/vsc";
import {PiSyringeThin} from "react-icons/pi";
import {FaRegBell} from "react-icons/fa";
import {Link} from "react-router-dom";
import ShowReminderType from "./RemindersSettings/ShowReminderType";
import ShowReminderSchedule from "./RemindersSettings/ShowReminderSchedule";
import parse from "html-react-parser";
import ReminderTypeIcon from "./RemindersSettings/ReminderTypeIcon";
import CustomSwitcher from "../../../../Layout/Elements/CustomSwitcher";
import ReaderBottomButtons from "../../../../Layout/Elements/ReaderBottomButtons";

const ReminderSettings = (props) => {

    const {t, i18n} = useTranslation();
    const [showType, setShowType] = useState(false)
    const [showRepeat, setShowRepeat] = useState(false)

    useEffect(() => {
        console.log(props.allReminders)
    }, [props.allReminders])

    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden align-content-stretch`}>
            {!props.reminderAlreadySet ? (
                !showRepeat && !showType ? (
                    <>
                        <Col md={8} className={'align-items-center'}>
                            <b>{t('setReminders')}  </b>
                        </Col>
                        <Col className={'d-flex justify-content-between flex-column'}>
                            <Row className={'align-items-center mx-0  mb-2'}>
                                <Col md={4}>{t("Type")}</Col>
                                <Col md={8} className={'px-1 text-start'}>
                                    <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                          onClick={() => {
                                              props.setIsFromSettings(true)
                                              setShowType(true)

                                          }}>
                                        <p
                                            className={'text-start mb-0 text-smaller'}><ReminderTypeIcon
                                            reminderType={props.reminderType}/> {props.reminderType}</p></Link>

                                </Col>

                            </Row>
                            <Row className={'align-items-center mx-0  mb-2'}>
                                <Col md={4}>{t("Repeat")}</Col>
                                <Col md={8} className={'px-1 '}>
                                    <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                          onClick={() => {
                                              props.setIsFromSettings(true)
                                              setShowRepeat(true)
                                          }}>
                                        <p
                                            className={'text-start mb-0 text-smaller'}> {props.reminderRepeat}</p>
                                    </Link>
                                </Col>

                            </Row>
                            <Row className={'align-items-center mx-0  mb-2'}>
                                <Col md={4}>{t("Time")}</Col>
                                <Col md={8} className={'px-1'}>
                                    <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                          onClick={() => {
                                              props.setFromReminder(true)
                                              props.navigateToComponent('time')
                                          }}>
                                        <p
                                            className={'text-start mb-0 text-smaller'}>{props.reminderHour}:{props.reminderMinute}</p>
                                    </Link>
                                </Col>

                            </Row>
                            <Row>
                                <ReaderBottomButtons prevOnClick={()=>{
                                    props.navigateToComponent('settings')
                                }} nextOnClick={() => {
                                    props.setReminderAlreadySet(true)
                                    props.setAllReminders(prevState => {
                                        return [...prevState,{
                                            type: props.reminderType,
                                            time: props.reminderHour.toString() + ':' + props.reminderMinute.toString()
                                        }]
                                    })
                                    props.navigateToComponent('settings')

                                }
                                } backBtnText={'cancel'} nextBtnText={'save'} />

                                {/*<Col className={'text-start mt-2'}>
                                    <Link onClick={() => {
                                        props.navigateToComponent('settings')
                                    }} className={'btn btn-secondary w-100'}
                                          to={''}>{t('cancel')}</Link>
                                </Col>
                                <Col className={'text-end mt-2'}>
                                    <Link onClick={() => {
                                        props.setReminderAlreadySet(true)
                                        props.setAllReminders(prevState => {
                                            return [...prevState,{
                                                type: props.reminderType,
                                                time: props.reminderHour.toString() + ':' + props.reminderMinute.toString()
                                            }]
                                        })
                                        props.navigateToComponent('settings')
                                    }} className={'btn btn-primary w-100'} to={''}>{t('save')}</Link>
                                </Col>*/}
                            </Row>
                        </Col>

                    </>
                ) : (
                    showType ? (


                        <ShowReminderType setIsFromSettings={props.setIsFromSettings}
                                          setReminderType={props.setReminderType} setShowType={setShowType}/>

                    ) : (
                        <ShowReminderSchedule setIsFromSettings={props.setIsFromSettings}
                                              setReminderRepeat={props.setReminderRepeat} setShowRepeat={setShowRepeat}
                                              navigateToComponent={props.navigateToComponent} setFromReminder={props.setFromReminder}/>
                    )
                )
            ) : (
                <>
                    <Col md={8} className={'align-items-center'}>
                        <b>{t('reminders')}  </b>
                    </Col>
                    <Col className={'d-flex justify-content-start flex-column px-0 '}>
                        {props.allReminders.map((i, index) => {
                            return (
                                <>


                                    <Row className={'align-items-center mx-0  mb-2'}>
                                            <Col md={5} className={'px-1'}>
                                                <div
                                                    className={'btn btn-secondary w-100 text-black border-groove text-start'}>
                                                    <ReminderTypeIcon reminderType={i.type}/> <span
                                                    className={'small'}> {i.time}</span>
                                                </div>

                                            </Col>
                                            <Col md={7}
                                                 className={' px-0 justify-content-end align-content-end d-flex'}>
                                                <CustomSwitcher
                                                    alarmActivated={props.alarmActivated}
                                                    setAlarmActivated={props.setAlarmActivated}
                                                    alarmType={t(i.type)+'_'+index}

                                                    setSoundsActivated={props.setSoundsActivated}
                                                /></Col>

                                        </Row>

                                </>
                            )
                        })}

                    </Col>
                    <Row className={'mx-0 px-0'}>
                        <ReaderBottomButtons  prevOnClick={()=>{
                            props.setReminderAlreadySet(false)
                        }} nextOnClick={() => {
                            props.navigateToComponent('settings')
                        }
                        } backBtnText={'addNew'} nextBtnText={'done'} />
                       {/* <Col md={6} className={'text-start mt-2 px-1 align-self-end'}>
                            <Link onClick={() => {
                                props.setReminderAlreadySet(false)
                                // props.navigateToComponent('settings')
                            }} className={'btn btn-secondary w-100 small'}
                                  to={''}>{t('addNew')}</Link>
                        </Col>
                        <Col  md={6}  className={'text-end mt-2 px-1 align-self-end'}>
                            <Link onClick={() => {
                                props.navigateToComponent('settings')

                            }} className={'btn btn-primary w-100 small'} to={''}>{t('done')}</Link>
                        </Col>*/}
                    </Row>
                </>

            )
            }

        </Row>
    )

        ;
}

export default ReminderSettings