import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import parse from "html-react-parser";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import applicationSite from "../../assets/img/Sensor/application-site-selection-sm.png"
import './../../assets/css/Sensor/Sensor.css'
import ModalNote from "../../Components/Modal/ModalNote";
import {HiExclamationTriangle} from "react-icons/hi2";
import {CiCircleInfo} from "react-icons/ci";

const PreparingApplicationSite = () => {
    const {t, i18n} = useTranslation()
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const lang = localStorage.getItem('lang');
    const [video, setVideo] = useState(null);

    useEffect(() => {
        setVideo(`/translations/${lang}/video/application-site-prep-sm.mp4`)
    }, [lang])
    useEffect(() => {
        console.log(video)
    }, [video])
    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("applicationSitePrepare")}</h2>


                <p className={''}>
                    <span>{parse(t("applicationSitePrepare_Text_1"))}  </span>
                    <span>
                         <CiCircleInfo className={'fs-3 ms-2 text-end text-orange'} onClick={() => {
                             setShow(true)
                             setTitle(t('note'))
                             setText(t('applicationSitePrepare_Info'))
                         }}/>
                    </span>
                </p>


                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{parse(t("applicationSitePrepare_Text_2"))}</span>


                </p>


            </Col>
            <Col md={6} className={''}>

                {video && <video controls height={'auto'} width={'100%'}>
                    <source src={video} type="video/mp4"/>
                    Il tuo browser non supporta il tag video.
                </video>}
            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/application-site-selection'} nextUrl={'/applying-sensor'}/>
            </Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>


    )
}

export default PreparingApplicationSite