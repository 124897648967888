import {Col, Form, Row} from "react-bootstrap";
import {
    HiMiniArrowDown,
    HiMiniArrowDownRight,
    HiMiniArrowRight,
    HiMiniArrowUp,
    HiMiniArrowUpRight
} from "react-icons/hi2";

import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ReaderBottomButtons from "../../../Layout/Elements/ReaderBottomButtons";

const ArrowDesc = (props) => {
    const {t, i18n} = useTranslation();
    const [language, setLanguage] = useState()

    return (<Row className={'mx-0 h-100'}>
        <p className={'fw-bold text-start mb-1 small'}>{t('arrowDescTitle')}</p>
        <Col className={'text-start small'} md={12}><HiMiniArrowUp className={'fs-4'}/> {t('risingQuick')}</Col>
        <Col className={'text-start small'} md={12}><HiMiniArrowUpRight className={'fs-4'}/> {t('rising')}</Col>
        <Col className={'text-start small'} md={12}><HiMiniArrowRight className={'fs-4'}/> {t('changingSlow')}</Col>
        <Col className={'text-start small'} md={12}><HiMiniArrowDownRight className={'fs-4'}/> {t('falling')}</Col>
        <Col className={'text-start small'} md={12}><HiMiniArrowDown className={'fs-4'}/> {t('fallingQuick')}</Col>

        <ReaderBottomButtons prevOnClick={()=>{props.isFromSettings ? props.navigateToComponent('settings'): props.navigateToComponent('time')}} nextOnClick={() => {
            props.navigateToComponent('firstHomeBtn')
        }} backBtnText={'back'} nextBtnText={'next'} />

    </Row>)
}

export default ArrowDesc