import {useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import SetupComplete from "./SetupComplete";
import ReaderBottomButtons from "../../../../../Layout/Elements/ReaderBottomButtons";

const InsulinSymbol = (props) => {
    const [correctionInfo, setCorrectionInfo] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const [setupComplete, setSetupComplete] = useState(false)
    const handleRadioChange = (value) => {
        // Cloniamo lo stato corrente per evitare la mutazione diretta dello stato
        const updatedSettings = {...props.calculatorSettings};
        // Modifichiamo solo la proprietà doseIncrement con il nuovo valore
        updatedSettings.advancedMode.activeInsulinSymbol = value;
        // Aggiorniamo lo stato con il nuovo oggetto
        props.setCalculatorSettings(updatedSettings);
    };
    console.log(props.calculatorSettings)
    return (
        !isInfo ?
            !setupComplete ? (
            <Row className={`mx-0 h-100 text-start align-content-between overflow-hidden`}>
                <Col md={9} className={'align-items-center'}>
                    <p>{props.t('insulinDisplaySymbol_text')}</p><br/>
                </Col>
                <Col md={3} className={'text-end px-0 position-relative px-0'}>
                    <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                            style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                            onClick={() => {
                                setIsInfo(true)
                            }}> ? </Button>
                </Col>
                <Col md={12} className={`align-items-between d-inline-flex flex-column col-md-12 `}>
                    <Row className={'align-items-center mx-0'}>
                        <Col md={12} className={'px-1'}>
                            <Form.Check
                                name={'symbol'}
                                type={'radio'}
                                value={'enabled'}
                                label={ props.t("yes")}
                                onClick={() => handleRadioChange('enabled')}
                                checked={props.calculatorSettings.advancedMode.activeInsulinSymbol === 'enabled'}
                            /> {}
                            <Form.Check
                                name={'symbol'}
                                type={'radio'}
                                value={'disabled'}
                                label={props.t("no")}
                                onClick={() => handleRadioChange('disabled')}
                                checked={props.calculatorSettings.advancedMode.activeInsulinSymbol === 'disabled'}

                            />
                            {/* Aggiungi altri Form.Check per altri valori di doseIncrement se necessario */}
                        </Col>

                    </Row>
                </Col>
                <ReaderBottomButtons hideBack={true} nextOnClick={() => {
                    setSetupComplete(true) }
                } nextBtnText={'done'} />

            </Row>
        ) : (<Row className={`mx-0 h-100 text-start align-content-between overflow-hidden`}>
            <SetupComplete navigateToComponent={props.navigateToComponent} t={props.t}
                            setSetupComplete={setSetupComplete}/>
                </Row>
                ) : (<Row className={`mx-0 h-100 text-start align-content-start overflow-hidden`}>
            <Col md={8} className={'align-items-center'}>

            </Col>
            <Col md={4} className={'text-end px-0 position-relative px-0'}>
                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                        onClick={() => {
                            setIsInfo(false)
                        }}> {props.t("next")} </Button>
            </Col>
            <Col md={12} className={`align-content-start d-inline-flex flex-column col-md-12 mt-5`}>
                <p>{props.t("insulinSymbol_info")}</p>
            </Col>
        </Row>)

    );
}

export default InsulinSymbol