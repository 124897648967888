import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReaderBottomButtons from "../../../../../Layout/Elements/ReaderBottomButtons";

const SetupComplete = (props) => {
    return (
        <>
            <Col md={12} className={'align-items-start px-0'}>
                <p className={'text-start fw-bold px-2'}>  {props.t('setupComplete')}</p>
            </Col>


            <Col md={12}>
                <p className={'text-start'}>{props.t('calcSetupComplete_text')}</p>
            </Col>
            <ReaderBottomButtons prevOnClick={()=>{
                props.setSetupComplete(false)
            }} nextOnClick={() => {
                localStorage.removeItem('nextLink')
                localStorage.removeItem('advTitle')
                props.navigateToComponent('calculatorSettings')
            }
            } backBtnText={'back'} nextBtnText={'next'} />

               {/* <Col md={6} className={' mb-2  align-self-end'}><Link onClick={() => {

                    props.setSetupComplete(false)


                }} className={'btn btn-primary w-100'} to={''}>{props.t('back')}</Link></Col>
                <Col md={6} className={' mb-2 align-self-end'}>
                    <Link onClick={() => {
                    localStorage.removeItem('nextLink')
                    localStorage.removeItem('advTitle')
                    props.navigateToComponent('calculatorSettings')


                }} className={'btn btn-primary w-100'} to={''}>{props.t('done')}</Link></Col>*/}


        </>
    )
}

export default SetupComplete