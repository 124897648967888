import TargetComponent from "../Elements/TargetComponent";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const CorrectionTarget = (props) => {

    const [targetValues, setTargetValues] = useState({
        min: props.calculatorSettings && props.calculatorSettings[props.calculatorSettings.calculatorMode + 'Mode'].correctionTarget.min,
        max: props.calculatorSettings && props.calculatorSettings[props.calculatorSettings.calculatorMode + 'Mode'].correctionTarget.max
    })
    useEffect(() => {
        console.log(targetValues)
        console.log(props.calculatorSettings && props.calculatorSettings[props.calculatorSettings.calculatorMode + 'Mode'])

    }, [targetValues])
    return (

        <TargetComponent lowMinValue={targetValues && targetValues.min}
                         highMinValue={targetValues && targetValues.max}
                         targetRange={targetValues} setTargetRange={setTargetValues}
                         title={"correctionTarget"} actionTodo={() => {
            props.navigateToComponent('correctionFactor')
        }} infoText={'correctionTargetInfo'} infoTitle={''} btnText={'next'} showBack={true} backText={"back"}
                         actionBack={() => {
                             sessionStorage.setItem('backCalcSettings', true)
                             props.navigateToComponent('easyModeSetup')
                         }}/>
    )
}
export default CorrectionTarget