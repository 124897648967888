import {Col, Row} from "react-bootstrap";
import {useTranslation} from "../../../Utils/UseTranslation";
import {useEffect, useState} from "react";
import parse from "html-react-parser";
import {HiExclamationTriangle} from "react-icons/hi2";

const HiLoReader = (props) => {
    const {t} = useTranslation()
    const lang = localStorage.getItem('lang');
    const [goingHigh, setGoingHigh] = useState(null);
    const [goingLow, setGoingLow] = useState(null);
    const hiLoReadingApp_Text = parse(t('hiLOReadingApp_Text_3').replace('%WARNING_IMG%', '<img src="/icons/warning_icon.png" />'));
    useEffect(() => {
        setGoingHigh(!props.isApp ? `/translations/${lang}/ProductUse/hi_reading_lg.png` : `/translations/${lang}/ProductUse/hi_lo_reading_lg.png`)
        setGoingLow(`/translations/${lang}/ProductUse/lo_reading_lg.png`)
    }, [lang])
    return (
        <>
            <Row>
                <Col md={6}>
                    <p>{t('hiLOReading_Text')}</p>
                    <p>{t('hiLOReading_Text_2')}</p>
                    <p> {!props.isApp ? t('hiLOReading_Text_3') : hiLoReadingApp_Text}</p>
                    <b>{t('note')}</b>
                    <ul>
                        <li>
                            {t('goingHighLowReading_Note_1')}
                        </li>
                        <li>
                            {t('goingHighLowReading_Note_2')}
                        </li>
                    </ul>
                </Col>
                {props.isApp &&
                    <Col md={6} className={'text-start ps-4 pt-3'}>
                        <Row className={'text-white text-uppercase text-center py-3 w-75'}
                             style={{background: '#ED1C24'}}>
                            <p className={'fw-bold'}>
                                <HiExclamationTriangle className={'fs-5'}/> {t('Low')} ({t("outOfRange")})
                            </p>
                            <span className={'fw-bold'} style={{fontSize: '60px'}}>{t('Lo')}</span>
                        </Row>
                        <Row className={'text-white text-uppercase text-center py-3 w-75 ms-3'}
                             style={{background: 'orange', marginTop: '-20px'}}>
                            <p className={'fw-bold'}>
                                <HiExclamationTriangle className={'fs-5'}/> {t('High')} ({t("outOfRange")})
                            </p>
                            <span className={'fw-bold'} style={{fontSize: '60px'}}>{t('Hi')}</span>

                        </Row>

                    </Col>
                }
                {!props.isApp && (
                    <>
                        <Col md={6} className={'p-0'}>
                            <Row className={'mx-0 p-0'}>
                                <Col md={6} className={'text-start ps-4'}>
                                    <img src={goingHigh} className={'img-fluid'}/>
                                </Col>

                                <Col md={6} className={'text-start ps-4'}>
                                    <img src={goingLow} className={'img-fluid'}/>
                                </Col>
                                <p className={'fw-bold text-start mt-1 text-smaller'}>{t('note')}: {t('disclaimerGlucose')}</p>
                            </Row>

                        </Col>


                    </>
                )}

            </Row>

        </>
    )
}
export default HiLoReader