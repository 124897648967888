import {Col} from "react-bootstrap";

import parse from "html-react-parser";
import chargeReader from './../../assets/img/ProductUse/ChargingReader/reader_connect_cable.png'

import {useEffect, useState} from "react";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import {CiCircleInfo} from "react-icons/ci";
import ModalNote from "../../Components/Modal/ModalNote";
import {HiExclamationTriangle} from "react-icons/hi2";
import {useTranslation} from "../../Utils/UseTranslation";

const ChargingReader = () => {
    const {t, i18n} = useTranslation();
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [imageStep1, setImageStep1] = useState()
    const chargingReader_Info = parse(t("chargingReader_Info").replace('%LOW_BATTERY%', '<img src="/icons/low_batt.png" /> '))
    const lang = localStorage.getItem('lang');

    const controlSolutionsInfoTXT = []
    for (let i = 1; i < 10; i++) {
        controlSolutionsInfoTXT.push('controlSolutionTesting_Info_' + i)
    }
    useEffect(() => {
        setImageStep1(`/translations/${lang}/ProductUse/ControlSolutionTesting/step_1.png`)
    }, [lang])
    const handleClose = () => {
        setShow(false)
    }
    return (
       <>
           <Col md={12} className={'align-content-start text-start'}>
               <h2>{t("chargingReader")}</h2>
               <p>{t("chargingReader_Text")} <span>
                       {/* <CiCircleInfo className={'fs-3 text-end text-orange'} onClick={() => {
                            setShow(true)
                            setTitle(t('note'))
                            setText(chargingReader_Info)
                        }}/>*/}
                    </span></p>
{/*
               <hr className={'text-orange my-1 opacity-100'}/>*/}
              {/* <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                   <span className={'h2 me-2'}>1</span>
                   <span></span>
                   <span>{t("chargingReader_Step1")}
                       <ul>
                    <li>{t("chargingReader_Step1_1")} </li>
                    <li>{t("chargingReader_Step1_2")} </li>
                </ul>
                </span>
                   <span> <HiExclamationTriangle
                       className={'fs-3 text-end text-orange'} onClick={() => {
                       setShow(true)
                       setTitle(t('caution'))
                       setText(t("chargingReader_Step1_Warning"))
                   }}/></span>
               </p>*/}
               <hr className={'text-orange my-1 opacity-100'}/>
               <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                   <span className={'h2 me-2'}>1</span> <img src={chargeReader} className={'img-fluid me-5'}
                                                             style={{width: '170px'}}/>
                   <span>{t("chargingReader_Step2")} </span>
                   <span> <HiExclamationTriangle
                       className={'fs-3 text-end text-orange'} onClick={() => {
                       setShow(true)
                       setTitle(t('caution'))
                       setText(t("chargingReader_Step2_Warning"))
                   }}/></span>
               </p>
               <hr className={'text-orange my-1 opacity-100'}/>

               <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>

           </Col>
          <Col className={'align-self-end text-start'}> <NavigationButtons t={t} prevUrl={'/living-with-your-system'} nextUrl={'/reader-settings-info'}/></Col>
       </>
    )
}

export default ChargingReader