import parse from "html-react-parser";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import {Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import activeSensor from './../../assets/img/Icons/activeSensor.png'
import direction from './../../assets/img/Icons/directionGlucoseReader.png'
import caution from './../../assets/img/Icons/caution.png'
import prevNext from './../../assets/img/Icons/viewPrevNext.png'
import soundsOn from './../../assets/img/Icons/soundsVibrationON.png'
import soundsOffVibON from './../../assets/img/Icons/SoundOffVibrationON.png'
import soundsOnVibOff from './../../assets/img/Icons/SoundONVibrationOff.png'
import soundsOff from './../../assets/img/Icons/SoundAndVibrOFF.png'
import sensorCommunicating from './../../assets/img/Icons/SensorCommunicating.png'
import sensorNotCommunicating from './../../assets/img/Icons/SensorNotCommunicating.png'
import settings from './../../assets/img/Icons/settings_icon.png'
import notes from './../../assets/img/Icons/notes.png'
import addNotes from './../../assets/img/Icons/addNotes.png'
import timeChanged from './../../assets/img/Icons/timeChanged.png'
import bloodTest from './../../assets/img/Icons/bloodTest.png'
import controlSolution from './../../assets/img/Icons/controlSolutionResult.png'
import lowBattery from './../../assets/img/Icons/lowBattery.png'
import batteryCharging from './../../assets/img/Icons/batteryCharging.png'
import sensorHot from './../../assets/img/Icons/sensorHot.png'
import sensorCold from './../../assets/img/Icons/sensorCold.png'
import foodNote from './../../assets/img/Icons/foodNote.png'
import insulinNote from './../../assets/img/Icons/insulinNote.png'
import infoIcon from './../../assets/img/Icons/infoIcon.png'
import calculator from './../../assets/img/Icons/calculatorSettings.png'
import activeInsulin from './../../assets/img/Icons/activeInsulin_2.png'
const ReaderSymbols = () => {
    const {t} = useTranslation()

    return (
        <>
            <Col md={12} className={'text-start'}><h2> {t("readerSymbols")}</h2></Col>
            <Col md={6} className={'text-start justify-content-start ps-4 align-self-start'}>

                <p className={'d-flex'}><span><img src={activeSensor}/></span> {t("activeSensor")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={direction}/></span> {t("directionGlucose")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={caution}/></span> {t("caution")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={prevNext}/></span> {t("viewPrevNext")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={soundsOn}/></span> {t("soundsVibOn")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={soundsOnVibOff}/></span> {t("soundsOnVibOff")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={soundsOffVibON}/></span> {t("soundsOffVibOn")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={soundsOff}/></span> {t("soundsVibOff")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={sensorCommunicating}/></span> {t("sensorCommunicating")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={sensorNotCommunicating}/></span> {t("sensorNotCommunicating")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={settings}/></span> {t("settings")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={activeInsulin}/></span> {t("estimatedInsulin")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={calculator}/></span> {t("rapidActInsulineCalc")}</p>
            </Col>
            <Col md={6} className={'text-start justify-content-start ps-4 align-self-start'}>


                <p className={'d-flex'}><span><img src={notes}/></span> {t("notes")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={addNotes}/></span> {t("addInfoNotes")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={foodNote}/></span> {t("foodNote")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={insulinNote}/></span> {t("insulinNote")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={timeChanged}/></span> {t("timeChanged")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={bloodTest}/></span> {t("bloodGlucoseTest")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={controlSolution}/></span> {t("controlSolutionResult")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={lowBattery}/></span> {t("lowBattery")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={batteryCharging}/></span> {t("batteryCharging")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={sensorCold}/></span> {t("sensorCold")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={sensorHot}/></span> {t("sensorHot")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex'}><span><img src={infoIcon}/></span> {t("suggestedDose")}</p>

            </Col>
                <Col md={12} className={'text-start align-self-end'}>  <NavigationButtons t={t} prevUrl={'/important-informations'} nextUrl={'/system-specifications'}/>
                </Col>
        </>


    )
}

export default ReaderSymbols