import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {Link} from "react-router-dom";

import TargetsArrow from "../../../../../Layout/Elements/TargetsArrow";

const SetOptionalRange = (props) => {

    const {t, i18n} = useTranslation();
    const [unit, setUnit] = useState(props.optionalSettingsAdv.unit);
    const [unitInitial, setUnitInitial] = useState(true)
    const [isInfo, setIsInfo] = useState(false)
    const [isChanged,setIsChanged]=useState(false)
    useEffect(() => {
        console.log(props.optionalSettingsAdv)
    }, [props.optionalSettingsAdv])

    useEffect(() => {
        props.setCalculatorSettings({
            ...props.calculatorSettings,
            advancedMode: {
                ...props.calculatorSettings.advancedMode,

                [props.optionalSettingsAdv.type]: {
                    ...props.calculatorSettings.advancedMode[props.optionalSettingsAdv.type],
                    [props.optionalSettingsAdv.timeOfDay]: unit
                },
                [props.optionalSettingsAdv.typeMacro]: null,
            }
        });

    }, [unit]);
    useEffect(() => {
        console.log(props.isByTime)
    }, [props.isByTime])
    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden align-content-stretch`}>
            {!isInfo ? (

                <>

                    <>
                        <Col md={8} className={'align-items-center'}>
                            <b> {t(props.optionalSettingsAdv.title)}</b>
                        </Col>

                        {!props.isAddictionalOptions ? (<Col md={4} className={'text-end px-0 position-relative'}>
                            <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                    style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                    onClick={() => {
                                        localStorage.setItem('isActive'+props.optionalSettingsAdv.timeOfDay, true)
                                        props.navigateToComponent('optionalRange')

                                    }}>{t('ok')}</Button>
                        </Col>) : (<Col md={4} className={'text-end px-0 position-relative'}>
                            <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                    style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                    onClick={() => {
                                        localStorage.setItem('backFrom', props.optionalSettingsAdv.title)
                                        props.setIsByTime(true)
                                        console.log(props.isByTime)

                                    }}>{t("ok")}</Button>
                        </Col>)}

                        <Col md={12}
                             className={`align-items-start d-inline-flex mt-2 justify-content-between flex-row col-md-12`}>
                            <TargetsArrow unit={unit} setUnit={setUnit}
                                          initialValue={props.optionalSettingsAdv.initialValue}
                                          setUnitInitial={setUnitInitial} unitInitial={unitInitial} setIsChanged={setIsChanged}/>
                            <div className={'mt-2 text-end justify-content-around'}>
                                {props.prevUnitText &&
                                    <span className={'small'}>{t(props.optionalSettingsAdv.prevUnitText)}</span>}
                                <h2>  {unit === null ? '--' : unit}
                                    <br/>
                                    <span className={'fs-6'}>{t(props.optionalSettingsAdv.unitText)}</span>
                                </h2>
                            </div>


                        </Col>
                    </>

                    <Col md={12}>
                        <p className={'small text-center mt-2'}>
                            {t(props.optionalSettingsAdv.timeOfDay)}: <br/>
                            {t(`${props.optionalSettingsAdv.timeOfDay + 'Timing'}`)}
                        </p>
                    </Col>
                   {/* <Col md={6} className={' mb-2 align-self-end'}>
                        <Link onClick={() => {
                            localStorage.setItem('isActive'+props.optionalSettingsAdv.timeOfDay, true)
                            props.navigateToComponent('optionalRange')

                        }} className={'btn btn-primary w-100'} to={''}>{props.t('back')}</Link></Col>*/}
                  {/*  {isChanged && <Col md={6} className={' mb-2 align-self-end'}>
                        <Link onClick={() => {
                            localStorage.setItem('isActive'+props.optionalSettingsAdv.timeOfDay, true)
                            props.navigateToComponent(localStorage.getItem('nextLink'))

                        }} className={'btn btn-primary w-100'} to={''}>{props.t('back')}</Link></Col>}*/}
                </>
            ) : isInfo && (
                <>
                    <Col md={8} className={'align-items-start px-0'}>
                        <p className={'text-start fw-bold px-2'}>  {t(props.infoTitle)}</p>
                    </Col>

                    <Col md={4} className={'text-end px-0 position-relative px-0'}>
                        <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                onClick={() => {
                                    setIsInfo(false)
                                }}>{t("ok")}</Button>
                    </Col>
                    <Col md={12}>
                        <p className={'text-start'}>{t(props.infoText)}</p>
                    </Col>
                </>
            )}

        </Row>

    )

        ;
}
export default SetOptionalRange