import {Col, Row} from "react-bootstrap";
import sensor from "../../assets/img/ProductOverview/SensorKit/sensor.png";
import applicator from "../../assets/img/ProductOverview/sensor_applicator.png";
import {useTranslation} from "../../Utils/UseTranslation";
import parse from 'html-react-parser';
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import {CiCircleInfo} from "react-icons/ci";
import ModalNote from "../../Components/Modal/ModalNote";
import {useState} from "react";

const SensorKit = () => {
    const {t, i18n} = useTranslation()
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()

    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={5} className={'text-start ps-4'}>

                <h2> {t("sensorKitTitle")}</h2>
                <p>{t("sensorKitText_1")}</p>
                <p> <span>{t("sensorKitText_2")} </span>
                    {/*<span>
                        <CiCircleInfo className={'fs-3 text-end text-orange'} onClick={() => {
                            setShow(true)
                            setTitle(t('note'))
                            setText(t('sensorKit_note'))
                        }}/>
                    </span>*/}</p>
                <p> {t("sensorKitText_impo")}</p>

            </Col>
            <Col md={7} sm={7} className={'position-relative text-start align-self-center'}>
                <Row >
                    <Col  className={'align-self-end '}>
                        <p className={'fw-bold mb-0'}> {parse(t("sensor"))}</p>
                        <p> {t("sensor_upText")}</p>
                        <img src={sensor} className={'img-fluid'}/></Col>
                    <Col className={'align-self-start '}>
                        <p className={'fw-bold mb-0'}> {parse(t("sensorApplicator"))}</p>
                        <p> {t("sensorKit_upText")}</p>
                        <img src={applicator} className={'img-fluid'}/>
                    </Col>
                </Row>



            </Col>

            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/getting-to-know-reader'} nextUrl={'/sensor-glucose-reading'}/>
            </Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>

        </>


    )
}

export default SensorKit