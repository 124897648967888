import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";


const NotesDetails = (props) => {
    const {t, i18n} = useTranslation();
    const [unit, setUnit] = useState(0)
    const handleUnitChange = (e) => {
        const action = e.currentTarget.getAttribute('data-action')
        if (action === 'grow') {
            setUnit(prevState => prevState + 1)

        } else if (action != 'grow' && unit > 0) {
            setUnit(prevState => prevState - 1)
        }

    }

    const handleSaveUnit = () => {
        localStorage.setItem(props.noteDetails, unit)
        props.navigateToComponent('notes')

    }
    console.log(props.noteDetails, props.noteDetails === 'food')
    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden align-content-start`}>
            <Col md={8} className={'align-items-center'}>
                <b> {t('enter')} {t(props.noteDetails)}</b>
            </Col>
            <Col md={4} className={'text-end px-0 position-relative'}>
                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                        onClick={handleSaveUnit}>OK</Button>
            </Col>

            <Col md={12}
                 className={`align-items-center d-inline-flex justify-content-between flex-row col-md-12`}>
                <div className={'mt-2 justify-content-around'}>
                    <div className={'position-relative'}>
                    <span onClick={handleUnitChange} data-action={'grow'}>
                        <BiSolidUpArrow style={{
                            fontSize: '50px',
                            color: 'var(--light-blue)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))',
                            cursor: 'pointer'
                        }}/>
                    <span className={'text-black fs-4 position-absolute'}
                          style={{left: '18px', top: '10px', cursor: 'pointer', userSelect: 'none'}}> + </span>

                    </span>

                    </div>
                    <div className={'position-relative'}>
                    <span onClick={handleUnitChange}>

                        <BiSolidDownArrow style={{
                            fontSize: '50px',
                            color: 'var(--light-blue)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                        }}/>
                    <span className={'text-black fs-3 position-absolute'}
                          style={{left: '20px', top: '2px', cursor: 'pointer', userSelect: 'none'}}> - </span>

                    </span>

                    </div>
                </div>

                <div className={'mt-2 text-center justify-content-around'}>

                    <h2> {unit === 0 ? '--' : unit}<br/>
                        {t(props.noteDetails === 'food' ? 'grams' : 'units')} </h2>
                </div>


            </Col>
        </Row>
    )

}

export default NotesDetails