import {useEffect, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import HomeScreenHeader from "./HomeScreenHeader";
import sound from './../../../../assets/sounds/succesful-scan.mp3'
import {useTranslation} from "react-i18next";
import {FiArrowDown, FiArrowDownRight, FiArrowRight, FiArrowUpRight, FiArrowUp} from "react-icons/fi";
import useSound from "use-sound";


const GlucoseReadings = (props) => {
    const {t, i18n} = useTranslation();
    const lang = localStorage.getItem('lang');
    const [isDetail, setIsDetail] = useState(true)
    const [image, setImage] = useState(null);
    const [imageInactive, setImageInactive] = useState(`/translations/${lang}/noActive.jpg`)
    const [isNote, setIsNote] = useState(false)
    const [audio, setAudio] = useState(null)

    const [foodIcon, setFoodIcon] = useState(false)
    const [insulinIcon, setInsulinIcon] = useState(false)


    useEffect(() => {
        // setImage(require(`../../../../assets/img/translations/${lang}/${behavior}.jpg`))
        setImage(`/translations/${lang}/${props.behavior[props.behaviorIndex]}.jpg`)
        !props.isStatic && props.behavior[props.behaviorIndex] !== 'noActive' && localStorage.setItem('glucoseValue', props.behavior[props.behaviorIndex])
        !props.isStatic && props.behavior[props.behaviorIndex] !== 'noActive' && localStorage.setItem('glucoseNumericValue', t(`dynamicValues.${props.behavior[props.behaviorIndex]}`))
        !props.isStatic && props.behavior[props.behaviorIndex] !== 'noActive' && localStorage.setItem('date', new Date().toISOString())
    }, [lang, props.behavior])


    useEffect(() => {
        // Recupera l'array memorizzato in localStorage
        const arrayMemorizzato = JSON.parse(localStorage.getItem('checked'));

        if (Array.isArray(arrayMemorizzato)) {

            // Esegui altre operazioni con le voci dell'array qui, se necessario
            arrayMemorizzato.forEach(voce => {
                if (voce === 'rapidActing' || voce === 'longActing') {
                    setInsulinIcon(true)
                } else if (voce === 'food') {
                    setFoodIcon(true)
                }
                console.log(voce);
            });
        }
    }, [localStorage.getItem('checked')])

    useEffect(() => {

        const newAudio = new Audio(sound);

        if (!props.isStatic && !props.isFromAddingNotes) {
            newAudio.play();
            newAudio.loop = false
            // Aggiorna lo stato con la nuova istanza di Audio
            setAudio(newAudio);
        }


        // Funzione di cleanup per fermare il suono quando il componente viene smontato
        return () => {
            newAudio.pause();
            setAudio(null); // Rimuovi l'istanza di Audio quando il suono è stato fermato
        };

    }, [props.behavior, props.isStatic]);

    return (

        <Row className={'mx-0 h-100 text-start justify-content-start overflow-hidden'}>

            <Col md={12} className={'align-items-center'}>
                <HomeScreenHeader firstActivation={props.firstActivation} isDetail={isDetail}
                                  behavior={props.behavior}
                                  behaviorIndex={props.behaviorIndex} navigateToComponent={props.navigateToComponent}
                                  sensorIsActive={props.sensorIsActive}
                                  t={t} isStatic={props.isStatic} isFromAddingNotes={props.isFromAddingNotes}/>


            </Col>

            <Col md={12} className={'align-items-center px-0 text-end'}>
                {props.sensorIsActive && image &&

                    (<>
                            <Row className={`flex-row d-flex align-items-center ${props.isStatic && 'mx-0'}`} style={{background: '#fff'}}>
                                <Col>
                                    <h2 className={'d-inline-block mb-0'} style={{
                                        fontSize: (props.isStatic && props.behavior[props.behaviorIndex] === 'reader_110') ? '40px' : '65px',
                                        fontWeight: 800
                                    }}>

                                        {!props.isStatic ?
                                            (
                                                <span>{t(`dynamicValues.${props.behavior[props.behaviorIndex]}`)}</span>
                                            ) :
                                            <>
                                           <span> {props.behavior[props.behaviorIndex] === 'reader_110' &&

                                               <img
                                                   src={'/icons/icona-sangue.png'}
                                                   className={'bkg-ligth-grey-2 border-groove rounded-2 me-2'}/>
                                           }
                                               {t(`dynamicValues.treatmentDecisionsValue.${props.behavior[props.behaviorIndex]}`)}
                                               </span>
                                            </>
                                        } < /h2>
                                    <div className={'d-inline-block'}> <span>
                                {(props.behavior[props.behaviorIndex] === 'inRange' || props.behavior[props.behaviorIndex] === 'reader_110' || props.behavior[props.behaviorIndex] === 'reader_72' || props.behavior[props.behaviorIndex] === 'reader_204') && (
                                    <FiArrowRight className={'fs-1 fw-bold'}/>)}
                                        {props.behavior[props.behaviorIndex] === 'goingLow' && (
                                            <FiArrowDownRight className={'fs-1 fw-bold'}/>)}
                                        {props.behavior[props.behaviorIndex] === 'Low' && (
                                            <FiArrowDownRight className={'fs-1 fw-bold'}/>)}
                                        {(props.behavior[props.behaviorIndex] === 'High' || props.behavior[props.behaviorIndex] === 'reader_115') && (
                                            <FiArrowUpRight className={'fs-1 fw-bold'}/>)}
                                        {props.behavior[props.behaviorIndex] === 'GoingHigh' && (
                                            <FiArrowUpRight className={'fs-1 fw-bold'}/>)}

                            </span> <br/>
                                        <span>{t(`dynamicValues.unit`)}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={'position-relative mx-0'}>
                                <div style={{top: '10px', right: '25px'}} className={'position-absolute img-fluid'}>
                                    {foodIcon && <img src={'/icons/food.png'} className={'img-fluid'}/>}
                                    {insulinIcon && <img src={'/icons/insulin.png'} className={'img-fluid'}/>}

                                </div>
                                <img src={image} className={'img-fluid'}/>
                            </Row>


                        </>
                    )

                }
                {!props.sensorIsActive && <img src={imageInactive} className={'img-fluid'}/>}


            </Col>

        </Row>
    );
}

export default GlucoseReadings