import {Col, Tab, Tabs} from "react-bootstrap";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import {useTranslation} from "react-i18next";
import SensorSpecifications from "../../Components/MoreInformation/SensorSpecifications";
import ReaderSpecifications from "../../Components/MoreInformation/ReaderrSpecifications";
import InsulinCalcSpecifications from "../../Components/MoreInformation/InsulinCalcSpecifications";

const SystemSpecifications = ( ) => {
    const {t} = useTranslation()

    return (
   <>
       <Col md={12} className={'text-start ps-4 specifications'}>

           <h2> {t("systemSpecifications")} </h2>
           <Tabs
               defaultActiveKey="sensorSpec"
               id="uncontrolled-tab-example"
               className="mb-3"
               fill
               justify
           >
               <Tab eventKey="sensorSpec" title={t("sensorSpecifications")}>
                   <SensorSpecifications />
               </Tab>
               <Tab eventKey="readerSpec" title={t("readerSpecifications")} >
                   <ReaderSpecifications />
               </Tab>
               <Tab eventKey="insulinCalcSpec" title={t("insulinCalcSpecification")} >
                   <InsulinCalcSpecifications />
               </Tab>

           </Tabs>


       </Col>
       <Col md={12} className={'text-start align-self-end'}>  <NavigationButtons t={t} prevUrl={'/reader-symbols'} nextUrl={'/home'}/></Col>
   </>

    )
}

export default SystemSpecifications