import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import translationEn from '../locales/en/translations.json'
import languages from '../locales/languages.json'

import translationIt from '../locales/it/translations.json'
import translationItReader from '../locales/it/reader.json'
import translationDe from '../locales/de/translations.json'
import translationFr from '../locales/fr/translations.json'
import translationEs from '../locales/es/translations.json'

import translationCh_it from '../locales/ch_it/translations.json'
import translationCh_fr from '../locales/ch_fr/translations.json'
import translationCh_De from '../locales/ch_de/translations.json'


// the translations
const resources = {
        en: {
            translation: translationEn,
            languages: languages,
        },

        it: {
            translation: translationIt,

        },
        de: {
            translation: translationDe,

        },
        fr: {
            translation: translationFr,

        },
        es: {
            translation: translationEs,

        },
        ch_it: {
            translation: translationCh_it,
        },
        ch_fr: {
            translation: translationCh_fr,
        },
        ch_de: {
            translation: translationCh_De,
        }
    }
;

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('lang') || 'en', //lingua di default
        debug: true,
        interpolation: {
            escapeValue: false
        },
        ns: ['translation', 'reader', 'languages'],
        defaultNS: 'translation',
    })


export default i18n
