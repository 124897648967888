import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {Link} from "react-router-dom";
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import CustomSwitcher from "../../../../../Layout/Elements/CustomSwitcher";
import {use} from "i18next";
import AdvancedUnitRanges from "../Elements/AdvancedUnitRanges";
import TargetsArrow from "../../../../../Layout/Elements/TargetsArrow";
import UnitRangeOptional from "../Elements/UnitRangeOptional";
import ReaderBottomButtons from "../../../../../Layout/Elements/ReaderBottomButtons";

const EasySettingsSetup = (props) => {

    const {t, i18n} = useTranslation();
    const setupValues = ['breakfast', 'lunch', 'dinner'];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [unit, setUnit] = useState(
        props.calculatorSettings &&
        props.calculatorSettings.easyMode[setupValues[currentIndex]]
    );
    const [unitInitial, setUnitInitial] = useState(true)
    const [isInfo, setIsInfo] = useState(false)
    console.log(props.calculatorSettings.easyMode[setupValues[currentIndex]])
    useEffect(() => {
        if (sessionStorage.hasOwnProperty('backCalcSettings')) {
            setCurrentIndex(2);
            sessionStorage.removeItem('backCalcSettings')
        }
    }, [sessionStorage.getItem('backCalcSettings')])
    useEffect(() => {
        setUnit(props.calculatorSettings &&
            props.calculatorSettings.easyMode[setupValues[currentIndex]]);
        console.log(unit)
        console.log(currentIndex)
    }, [currentIndex, props.calculatorSettings]);
    useEffect(() => {
        console.log(unit);
    }, [currentIndex, props.calculatorSettings, setupValues]);

    const handleNext = () => {
        setCurrentIndex(prevIndex => prevIndex + 1); // Incrementa l'indice corrente
    };
    const handlePrev = () => {
        setCurrentIndex(prevIndex => prevIndex + -1); // Incrementa l'indice corrente
    };

    const handleNextBtn = () => {
        console.log('unitFor' + [setupValues[currentIndex]])
        const updatedSettings = {...props.calculatorSettings};
        // Modifichiamo solo la proprietà doseIncrement con il nuovo valore
        updatedSettings.easyMode[setupValues[currentIndex]] = parseInt(localStorage.getItem('unitFor' + [setupValues[currentIndex]]));
        // Aggiorniamo lo stato con il nuovo oggetto
        props.setCalculatorSettings(updatedSettings);
        if (setupValues[currentIndex] === 'dinner') {
            props.setIsFromCalculator(true)
            // props.navigateToComponent('reportSettings')
            props.navigateToComponent('correctionTarget')
        } else {
            handleNext()
        }
    }
    const handleBackBtn = () => {
        if (setupValues[currentIndex] === 'breakfast') {
            props.navigateToComponent('calculatorSetup')
        } else {
            handlePrev()
        }
    }
    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden align-content-stretch`}>

            {!isInfo ? (

                <>
                    <Col md={8} className={'align-items-center'}>
                        <b> {t(setupValues[currentIndex])}</b>
                    </Col>

                    <Col md={4} className={'text-end px-0 position-relative'}>
                        <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                onClick={() => {
                                    setIsInfo(true)
                                }}>?</Button>
                    </Col>

                    <Col md={12}
                         className={`align-items-start d-inline-flex mt-2 justify-content-between flex-row col-md-12`}>
                        <TargetsArrow unit={unit} setUnit={setUnit} initialValue={15}
                                      setUnitInitial={setUnitInitial} unitInitial={unitInitial}/>
                        <div className={'mt-2 text-end justify-content-around'}>
                            <h2>  {unit === 14 ? '--' : unit}
                                <br/>
                                <span className={'fs-6'}>{t('unitOfIns')}</span>
                            </h2>
                        </div>


                    </Col>
                    <ReaderBottomButtons prevOnClick={() =>{
                        handleBackBtn()
                    }} nextOnClick={() => {
                        localStorage.setItem('unitFor' + setupValues[currentIndex], unit)
                        handleNextBtn()
                    }
                    } backBtnText={'back'} nextBtnText={'next'} />

                </>
            ) : (
                <>
                    <Col md={8} className={'align-items-start px-0'}>

                    </Col>

                    <Col md={4} className={'text-end px-0 position-relative px-0'}>
                        <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                onClick={() => {
                                    setIsInfo(false)
                                }}>{t("ok")}</Button>
                    </Col>
                    <Col md={12}>
                        <p className={'text-start'}>{t('carboRatioInfo')}</p>
                    </Col>
                </>
            )
            }

        </Row>

    )
        ;
}

export default EasySettingsSetup