import {Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ReaderBottomButtons = (props) => {
    const {t} = useTranslation()
 return (
     <>
         {!props.hideBack && (
             <Col md={6} className={`text-start mt-2 align-content-end bottomBtn px-1 ${props.hideNext && ''}`}>
                 <Link onClick={props.prevOnClick} className={'btn btn-secondary w-100'} to={''}>{t(props.backBtnText)}</Link>
             </Col>
         )}
         {!props.hideNext && (
             <Col md={6} className={`text-end mt-2 ${props.hideBack && 'offset-6'} align-content-end px-1 bottomBtn`}>
                 <Link onClick={() => {props.nextOnClick()}} className={'btn btn-primary w-100'} to={''}>{t(props.nextBtnText)}</Link>
             </Col>
         )}

     </>

 )
}

export default ReaderBottomButtons