import reader from "../../assets/img/Reader/reader_empty.png";
import '../../assets/css/Reader/Reader.css'
import {Children, useEffect} from "react";
import {useOutletContext} from "react-router-dom";

const ReaderEmpty = ({children, ...props}) => {
    const [alarmSound, setAlarmSound] = useOutletContext();
    useEffect(() =>{
        if (alarmSound == 'low' || alarmSound == 'high' || alarmSound == 'loss') {

            setTimeout(function () {
                props.setCurrentStep('glucoseAlarms')
            },100)

        }
        console.log(alarmSound, props.turnOnOff)
    }, [alarmSound])

    useEffect(() => {
        if (!props.turnOnOff) {
            localStorage.removeItem('sensorIsActive')
        }

    }, [props.turnOnOff])
    const handleClick = () => {
        if (!props.homeBtnBehavior) {
            props.setTurnOnOff(!props.turnOnOff)
        } else {
            props.setCurrentStep('homeScreen')
        }

    }
    return (
        <div className={'readerContainer'}>
            <img src={reader} alt="ReaderHomeScreen" style={{width: "100%", height: "auto"}}/>
            <div className={`innerReaderContainer ${props.turnOnOff && 'bg-white'}`} >

                {children}

            </div>
            {/* Area cliccabile */}
            <div style={{ position: 'absolute', bottom: '7%', right: '8%', width: '100px', height: '100px' }} onClick={handleClick}></div>
        </div>
    )
}

export default ReaderEmpty