import {useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReaderBottomButtons from "../../../../../Layout/Elements/ReaderBottomButtons";

const HowCorrect = (props) => {
    const [correctionInfo, setCorrectionInfo] = useState(false)
    const [isInfo, setIsInfo] = useState(false)
    const handleRadioChange = (value) => {
        // Cloniamo lo stato corrente per evitare la mutazione diretta dello stato
        const updatedSettings = {...props.calculatorSettings};
        // Modifichiamo solo la proprietà doseIncrement con il nuovo valore
        updatedSettings.howCorrect = value;
        // Aggiorniamo lo stato con il nuovo oggetto
        props.setCalculatorSettings(updatedSettings);
        localStorage.setItem('correctionType', value)
    };
    console.log(props.calculatorSettings)
    return (
        !isInfo ? (
            <Row className={`mx-0 h-100 text-start align-content-between overflow-hidden`}>
                <Col md={8} className={'align-items-center'}>
                    <p>{props.t('howCorrect')}</p><br/>
                </Col>
                <Col md={4} className={'text-end px-0 position-relative px-0'}>
                    <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                            style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                            onChange={() => {
                                setIsInfo(true)
                            }}> ? </Button>
                </Col>
                <Col md={12} className={`align-items-between d-inline-flex flex-column col-md-12 `}>
                    <Row className={'align-items-center mx-0'}>
                        <Col md={12} className={'px-1'}>
                            <Form.Check
                                type={'radio'}
                                value={'1'}
                                label={ props.t("singleTarget")}
                                onChange={() => handleRadioChange('singleTarget')}
                                defaultChecked={true}
                                checked={props.calculatorSettings.howCorrect === 'singleTarget'}
                            /> {}
                            <Form.Check
                                type={'radio'}
                                value={'0.5'}
                                label={props.t("targetRange")}
                                onChange={() => handleRadioChange('targetRange')}
                                checked={props.calculatorSettings.howCorrect === 'targetRange'}
                            />
                            {/* Aggiungi altri Form.Check per altri valori di doseIncrement se necessario */}
                        </Col>

                    </Row>
                </Col>
                <ReaderBottomButtons hideBack={''} prevOnClick={()=>{
                    props.navigateToComponent(props.previousComponent)
                }} nextOnClick={() => {
                    localStorage.setItem('advTitle', 'correctionTarget')
                    props.navigateToComponent('correctionTargetAdv')
                }
                } nextBtnText={'done'} backBtnText={'back'}/>
            </Row>
        ) : (<Row className={`mx-0 h-100 text-start align-content-start overflow-hidden`}>
            <Col md={8} className={'align-items-center'}>
                <b>{props.t('doseIncrement')}</b><br/>
            </Col>
            <Col md={4} className={'text-end px-0 position-relative px-0'}>
                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                        onClick={() => {
                            setIsInfo(false)
                        }}> {props.t("ok")} </Button>
            </Col>
            <Col md={12} className={`align-content-start d-inline-flex flex-column col-md-12 mt-5`}>
                <p>{props.t("doseIncrementInfo")}</p>
            </Col>
        </Row>)

    );
}

export default HowCorrect