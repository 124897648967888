import {useTranslation} from "../../Utils/UseTranslation";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import treatmentGuide from '../../assets/img/ProductUse/treatmentGuide.jpg'
import TreatmentGuideScenarios from "./TreatmentGuideScenarios";

const TreatmentDecisionGuide = (props) => {
    const {t, i18n} = useTranslation()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState();
    const [imageReader, setImageReader] = useState();
    const [imageApp, setImageApp] = useState([]);
    const [step, setStep] = useState(0)
    const treatmentDecisionGuide_Text_3 =t('treatmentDecisionGuide_Text_3', {
        settingsImageToken: '%BLOOD_IMAGE%',
        settingsImageValue: '/icons/icona-sangue.png'
    });



    useEffect(() => {
        setImage(`/translations/${lang}/ProductUse/treatment_reader_lg.png`)
    }, [lang])

    useEffect(() => {
        console.log(image)
    }, [image, lang])
    useState(() => {

    }, [step])
    return (
        <>

            {step === 0 && (
                <Row>
                    <h2 className={'text-start'}> {t("treatmentDecisionGuide")}</h2>
                    <p className={'text-start'}>{t("treatmentDecisionGuide_Text")}</p>
                    <Col md={6} className={'offset-3'}>
                        <img src={image} className={'mx-2 img-fluid'}/>
                        <p className={'fw-bold  mt-2'}>  {t("reader")}</p>
                    </Col>

                </Row>
            )}
            {step === 1 && (
                <Row className={'align-content-start text-start align-self-start'}>
                    <h2> {t("treatmentDecisionGuide")}</h2>
                    <p className={'text-start'}>{t("treatmentDecisionGuide_Text_1")}</p>

                    <Col md={4} className={'ps-2 text-start'}>
                        <img src={treatmentGuide} className={'img-fluid'}/>
                    </Col>
                    <Col md={8}>
                        <p className={'text-start'}>{t("treatmentDecisionGuide_Text_2")}</p>
                        <p className={'text-start'}>{treatmentDecisionGuide_Text_3}</p></Col>
                </Row>
            )}

            <Col className={'text-start align-self-end'}>
                <NavigationButtons prevUrl={'/using-signalLoss-alarms'} nextUrl={'/checking-with-strip'} t={t}
                                                  />
            </Col>
        </>
    )
}
export default TreatmentDecisionGuide