import {Col, Row} from "react-bootstrap";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";

import {useTranslation} from "react-i18next";
import HomeScreen from "../../Components/Reader/FirstTimeSetup/HomeScreens/HomeScreen";
import {useEffect, useState} from "react";

const ReaderHomeScreen = () => {
    const {t, i18n} = useTranslation()
    const [image, setImage] = useState(null);
    const lang = localStorage.getItem('lang')

    useEffect(() => {
        setImage(`/translations/${lang}/readerHomeScreen.png`)
    }, [lang])
    // Array di oggetti testo con le informazioni per il posizionamento
    const textData = [
        {id: 1, text: t("currentTime"), x: "2.3%", y: "0"},
        {id: 2, text: t("sensorStatus"), text2: t('sensorStatusText'), x: "2.3%", y: "16.5%"},
        {id: 2, text: t("viewGlucose"), text2: t("viewGlucoseText"), x: "2.3%", y: "36.8%"},
        {id: 3, text: t("reviewHistory"), text2: t("reviewHistoryText"), x: "2.3%", y: "58%"},
        {id: 4, text: t("startNewSensor"), text2: t("startNewSensorText"), x: "2.3%", y: "83%"},
        {id: 5, text: t("batteryCharge"), x: "68%", y: "2%"},
        {id: 6, text: t("soundVibration"), x: "69%", y: "11.5%"},
        {
            id: 7,
            text: t("signalStatus"),
            text2: t("signalStatusText"),
            x: "69%",
            y: "20%",
            right: "3%",
            class: 'text-end d-flex flex-column align-items-end'
        },
        {
            id: 7,
            text: t("settings"),
            text2: t("settingText"),
            x: "65%",
            y: "47%",
            right: "3%",
            class: 'text-end d-flex flex-column align-items-end'
        }

    ];
    return (
        <>
            <Col md={4} className={'text-start ps-4'}>

                <h2> {t("readerHomeScreen")}</h2>
                <p>{t("readerHomeScreenText")}</p>


            </Col>
            <Col md={8} className={'position-relative text-start align-self-center'}>
                <div style={{width: '200px', position: 'absolute', top: '36%', left: '34%'}}>
                    {/*<HomeScreen navigateToComponent={''} firstActivation={''}
                                setHomeBtnBehavior={''}
                                setBehaviorIndex={''}
                                behaviorIndex={''}
                                behavior={''} setGlucoseReading={''}
                                glucoseReading={''} t={t} lang={''}
                                activeInsulin={''}/>*/}
                </div>
                <Row className={'image-container'}>


                    <img src={image} className={'img-fluid'}/>
                    <div>  {textData.map(item => (
                        <div
                            key={item.id}
                            className={`text-overlay ${item.class}`}
                            style={{left: item.x, top: item.y, right: item.right && item.right}}
                        >
                            <p className="text mb-1">{item.text}</p>
                            <p className="small secondaryText">{item.text2}</p>
                        </div>
                    ))}
                    </div>
                </Row>


            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/first-time-reader-setup'} nextUrl={'/setting-alarms'}/>
            </Col>
        </>


    )
}

export default ReaderHomeScreen