import {Col} from "react-bootstrap";
import './../../../assets/css/CustomSwitcher.css'
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const CustomSwitcher = (props) => {
    const {t} = useTranslation()
    const [label, setLabel] = useState(props.label || t("off"));
    const [activated, setActivated] = useState(false)


    useEffect(() => {
        if(props.alarmType !== 'calculatorSetup') {
            if (props.active || activated) {
                setLabel(t("on"))
            } else {
                setLabel(t("off"))
            }
        } else
        {
            if (props.active || activated) {
                setLabel(t("advanced"))
            } else {
                setLabel(t("easy"))
            }
        }

        console.log(props.alarmType, props.alarmActivated)
        if (props.alarmType && (props.alarmType === 'lowAlarm' || props.alarmType === 'highAlarm' || props.alarmType === 'signalLossAlarm')) {
            props.setAlarmActivated(prevValue => {
                // Verifica se props.alarmType è definito e non nullo
                if (props.alarmType && props.alarmType !== undefined && props.alarmType !== null) {
                    // Verifica se prevValue è definito prima di accedere alle sue proprietà
                    if (prevValue === undefined || prevValue === null) {
                        // Se prevValue non è definito, restituisci un nuovo oggetto con la nuova proprietà
                        return {[props.alarmType]: props.active};
                    } else {
                        // Se prevValue è definito, aggiorna o aggiungi la proprietà corrispondente
                        return {...prevValue, [props.alarmType]: props.active};
                    }
                } else {
                    // Se props.alarmType non è definito o nullo, restituisci prevValue senza modifiche
                    return prevValue;
                }
            });
        } else {
            if(props.alarmType !== 'calculatorSetup') {
                props.setAlarmActivated(prevValue => {
                    // Verifica se props.alarmType è definito e non nullo
                    if (props.alarmType && props.alarmType !== undefined && props.alarmType !== null) {
                        // Verifica se prevValue è definito prima di accedere alle sue proprietà
                        if (prevValue === undefined || prevValue === null) {
                            // Se prevValue non è definito, restituisci un nuovo oggetto con la nuova proprietà
                            return {[props.alarmType]: activated};
                        } else {
                            // Se prevValue è definito, aggiorna o aggiungi la proprietà corrispondente
                            return {...prevValue, [props.alarmType]: activated};
                        }
                    } else {
                        // Se props.alarmType non è definito o nullo, restituisci prevValue senza modifiche
                        return prevValue;
                    }
                });
            } else {

                const updatedSettings = {...props.calculatorSettings};
                // Modifichiamo solo la proprietà doseIncrement con il nuovo valore
                updatedSettings.calculatorMode = props.active || activated ? 'advanced' : 'easy';
                // Aggiorniamo lo stato con il nuovo oggetto
                props.setCalculatorSettings(updatedSettings);
                console.log(props.calculatorSettings)

                props.setAlarmActivated(prevValue => {
                    // Verifica se props.alarmType è definito e non nullo
                    if (props.alarmType && props.alarmType !== undefined && props.alarmType !== null) {
                        // Verifica se prevValue è definito prima di accedere alle sue proprietà
                        if (prevValue === undefined || prevValue === null) {
                            // Se prevValue non è definito, restituisci un nuovo oggetto con la nuova proprietà
                            return {[props.alarmType]: activated};
                        } else {
                            // Se prevValue è definito, aggiorna o aggiungi la proprietà corrispondente
                            return {...prevValue, [props.alarmType]: activated};
                        }
                    } else {
                        // Se props.alarmType non è definito o nullo, restituisci prevValue senza modifiche
                        return prevValue;
                    }
                });
            }

        }


    }, [props.active, activated])

    useEffect(() => {

            if (props.alarmType && props.alarmActivated && props.alarmActivated[props.alarmType]) {
                if (props.alarmType && (props.alarmType === 'lowAlarm' || props.alarmType === 'highAlarm' || props.alarmType === 'signalLossAlarm')) {
                    props.setActive(props.alarmActivated[props.alarmType])
                } else {
                    setActivated(props.alarmActivated[props.alarmType])
                }
            }
        }, [props.alarmActivated]
    )

    const handleChange = () => {
        if (props.alarmType && (props.alarmType === 'lowAlarm' || props.alarmType === 'highAlarm' || props.alarmType === 'signalLossAlarm')) {
            const newActive = !props.active;
            if (newActive !== props.active) {
                props.setActive(newActive);
            }
        } else {
            setActivated(!activated)
        }
    };

    return (
        <Col md={12} className={'d-flex justify-content-center position-relative'}>
            <label className="switch">
                <input
                    defaultChecked={props.alarmType && props.alarmActivated && props.alarmActivated[props.alarmType] ? true : false}
                    className={'sliderInput'} type="checkbox"
                    onChange={handleChange}/>
                <span className="slider"></span>
                <label className={`label ${props.alarmType === 'calculatorSetup' && 'text-smaller'}`}>{label}</label>
            </label>

        </Col>
    )
}

export default CustomSwitcher