import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {Link} from "react-router-dom";

import TargetsArrow from "../../../../../Layout/Elements/TargetsArrow";
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import parse from "html-react-parser";

const SetOptionalRangeTargetRange = (props) => {

    const {t, i18n} = useTranslation();
    const [unit, setUnit] = useState(props.optionalSettingsAdv.unit);
    const [unitInitial, setUnitInitial] = useState(true)
    const [isInfo, setIsInfo] = useState(false)
    const [isChanged, setIsChanged] = useState(false)
    const [targetRange, setTargetRange]= useState({
        min: parseInt(parse(t('dynamicValues.targetRangeSetup.min'))),
        max: parseInt(parse(t('dynamicValues.targetRangeSetup.max')))
    });
    useEffect(() => {
        console.log(props.optionalSettingsAdv)
    }, [props.optionalSettingsAdv])

    /*useEffect(() => {
        props.setCalculatorSettings({
            ...props.calculatorSettings,
            advancedMode: {
                ...props.calculatorSettings.advancedMode,

                [props.optionalSettingsAdv.type]: {
                    ...props.calculatorSettings.advancedMode[props.optionalSettingsAdv.type],
                    [props.optionalSettingsAdv.timeOfDay]: unit
                },
                [props.optionalSettingsAdv.typeMacro]: null,
            }
        });

    }, [unit]);*/
    useEffect(() => {
        console.log(props.isByTime)
    }, [props.isByTime])
    const handleRangeChange = (key, operation) => {
        // Verifica se props.targetRange è definito e contiene la proprietà 'min'
        if (targetRange && typeof targetRange === 'object' && 'min' in targetRange) {
            // Ottieni il valore minimo e massimo preimpostati
            const minValue = parseInt(targetRange.min);
            const maxValue = parseInt(targetRange.max);
            // Se stai modificando il valore minimo, assicurati che non scenda sotto il valore preimpostato
            // Se stai modificando il valore massimo, assicurati che non scenda sotto il valore preimpostato
            const newValue = operation === "increment" ? targetRange[key] + 1 : targetRange[key] - 1;
            const updatedValue = key === "min" ? Math.max(newValue, minValue) : Math.max(newValue, maxValue);
            console.log(props.optionalSettingsAdv)
            // Aggiorna lo stato con i nuovi valori
            console.log(props.optionalSettingsAdv.typeMacro, props.optionalSettingsAdv.type, props.calculatorSettings.advancedMode[props.optionalSettingsAdv.typeMacro])
            props.setCalculatorSettings({
                ...props.calculatorSettings,
                advancedMode: {
                    ...props.calculatorSettings.advancedMode,
                    [props.optionalSettingsAdv.typeMacro]: { // Imposta il range per typeMacro
                        min: null,
                        max: null
                    },
                    [props.optionalSettingsAdv.type]: {
                        ...props.calculatorSettings.advancedMode[props.optionalSettingsAdv.type],
                        [props.optionalSettingsAdv.timeOfDay]: {
                           min: minValue,
                            max: maxValue
                        }
                    },
                    // [props.optionalSettingsAdv.type]: null,
                }
            });
            console.log(props.calculatorSettings)

            setTargetRange((prevRange) => ({
                ...prevRange,
                [key]: newValue
            }));
        }
        else {
            console.error("props.targetRange non è definito o non contiene la proprietà 'min'");
        }
    };
    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden align-content-stretch`}>

                <>


                    <>
                        <Col md={8} className={'align-items-center'}>
                            <b> {t(props.optionalSettingsAdv.title)} </b>
                        </Col>

                        <Col md={4} className={'text-end px-0 position-relative'}>
                            <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                    style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                    onClick={() => {
                                        localStorage.setItem('isActive' + props.optionalSettingsAdv.timeOfDay, true)
                                        props.navigateToComponent('optionalRange')

                                    }}>{t("ok")}</Button>
                        </Col>
                        <>

                            <div className={'mt-2 d-flex justify-content-around align-items-center w-100'}>

                                <div className={'position-relative d-inline-flex'}>
                    <span onClick={() => handleRangeChange("min", "increment")}>
                        <BiSolidUpArrow style={{
                            fontSize: '50px',
                            color: 'var(--light-grey-2)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))',
                            cursor: 'pointer'
                        }}/>
                    <span className={'text-black fs-4 position-absolute'}
                          style={{left: '18px', top: '10px', cursor: 'pointer', userSelect: 'none'}}> + </span>

                    </span>

                                </div>
                                <div></div>
                                <div className={'position-relative d-inline-flex'}>
                      <span onClick={() => handleRangeChange("max", "increment")}>
                    <BiSolidUpArrow style={{
                        fontSize: '50px',
                        color: 'var(--light-grey-2)',
                        filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                    }}/>

                    <span className={'text-black fs-4 position-absolute'}
                          style={{left: '18px', top: '10px', userSelect: 'none'}}> + </span>
                            </span>
                                </div>
                                <div></div>
                            </div>
                            <div className={'mt-2 d-flex justify-content-around align-items-center'}>
                                <Col md={4} className={' position-relative text-center'}>

                                    <h2 className={'fw-bold mb-0'}>{targetRange && targetRange.min} </h2>


                                </Col>
                                <Col md={1} className={' position-relative text-center'}>
                                    <p className={'small mb-0'}>{t("to")}</p>
                                </Col>
                                <Col md={4} className={'position-relative  text-center'}>
                                    <h2 className={'fw-bold mb-0'}> {targetRange && targetRange.max} </h2>

                                </Col>
                                <Col md={2} className={'position-relative  text-center'}>
                                    <p className={'mb-0 fw-bold'}
                                       style={{borderBottom: "1px solid #333"}}> {t("dynamicValues.splittedUnit.first")}</p>
                                    <p className={'mb-0 fw-bold'}> {t("dynamicValues.splittedUnit.second")}</p>
                                </Col>
                            </div>

                            <div className={'d-flex justify-content-around align-items-center '}>
                                <div className={'position-relative d-inline-flex'}>
                    <span onClick={() => handleRangeChange("min", "decrement")}>

                        <BiSolidDownArrow o style={{
                            fontSize: '50px',
                            color: 'var(--light-grey-2)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                        }}/>
                    <span className={'text-black fs-3 position-absolute'}
                          style={{left: '20px', top: '2px', cursor: 'pointer', userSelect: 'none'}}> - </span>

                    </span>

                                </div>
                                <div></div>
                                <div className={'position-relative d-inline-flex'}>
                    <span onClick={() => handleRangeChange("max", "decrement")}>
                    <BiSolidDownArrow style={{
                        fontSize: '50px',
                        color: 'var(--light-grey-2)',
                        filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                    }}/>
                <span className={'text-black fs-3 position-absolute'} style={{left: '20px', top: '2px'}}> - </span>
                        </span>
                                </div>
                                <div></div>
                            </div>


                        </>
                    </>

                    <Col md={12}>
                        <p className={'small text-center mt-2'}>
                            {t(props.optionalSettingsAdv.timeOfDay)}: <br/>
                            {t(`${props.optionalSettingsAdv.timeOfDay + 'Timing'}`)}
                        </p>
                    </Col>
                    {/*<Col md={6} className={' mb-2 align-self-end'}>
                        <Link onClick={() => {
                            localStorage.setItem('isActive' + props.optionalSettingsAdv.timeOfDay, true)
                            props.navigateToComponent('optionalRange')

                        }} className={'btn btn-primary w-100'} to={''}>{props.t('back')}</Link></Col>*/}

                </>


        </Row>

    )

        ;
}
export default SetOptionalRangeTargetRange