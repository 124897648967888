import {Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";
import image from '../../../../assets/img/Reader/firstHomeBtn.jpg'
import {Link} from "react-router-dom";
import ReaderBottomButtons from "../../../Layout/Elements/ReaderBottomButtons";

const FirstHomeBtn = (props) => {
    const {t, i18n} = useTranslation();

    return (
        <Row className={'mx-0 h-100'}>
        <p className={'text-start mb-1 small'}>{t('firstHomeBtn')}</p>
        <img src={image} className={'img-fluid w-100 pb-1 px-0'}/>
            <ReaderBottomButtons prevOnClick={()=>{props.navigateToComponent('arrowDesc')}} nextOnClick={() => {
                if (props.isFromSettings) {
                    props.setIsFromSettings(false)
                    props.navigateToComponent('settings')
                } else {
                    props.navigateToComponent('homeScreen')
                }

            }} backBtnText={'back'} nextBtnText={'done'} />
    </Row>)
}

export default FirstHomeBtn