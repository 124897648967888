import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import SystemInfo from "./SystemInfo";
import ReaderTest from "./ReaderTest";

const SystemStatus = (props) => {
    const {t} = useTranslation()
    const [isSystemInfo, setIsSystemInfo] = useState(false)
    const [isReaderTest, setIsReaderTest] = useState(false)
    return (

        <Row className={`mx-0 h-100 overflow-hidden `}>
            {!isSystemInfo && !isReaderTest && (
                <>
                    <Col md={8} className={'align-items-center px-0 align-items-start'}>
                        <b>  {t("systemStatus")}</b>
                    </Col>

                    <Col md={4} className={'text-end px-0 position-relative px-0'}>
                        <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                onClick={() => {
                                    props.navigateToComponent('settings')
                                }}>{t("ok")}</Button>
                    </Col>
                    <Col md={12}
                         className={`align-content-start d-inline-flex flex-column col-md-12 justify-content-start `}>
                        <Row className={'align-items-center mx-0 mt-3'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                      onClick={() => {
                                       setIsSystemInfo(true)
                                      }}>
                                   <span
                                       className={'small'}>{t("systemInfo")}</span></Link>
                            </Col>

                        </Row>
                        <Row className={'align-items-center mx-0 mt-3'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                      onClick={() => {
                                          setIsReaderTest(true)
                                      }}
                                >
                                   <span
                                       className={'small'}>{t("readerTest")} </span></Link>
                            </Col>

                        </Row>
                        <Row className={'align-items-center mx-0 mt-3 mb-3'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                      onClick={() => {
                                          props.navigateToComponent('eventLog')
                                      }}>
                                   <span
                                       className={'small'}>{t("eventLog")}</span></Link>
                            </Col>

                        </Row>

                    </Col>
                </>
            ) }


            {isSystemInfo && (
                <SystemInfo setIsSystemInfo={setIsSystemInfo}/>
            )}

            {isReaderTest && (
                <ReaderTest setIsSystemInfo={setIsSystemInfo}/>
            )}

        </Row>
    )
}

export default SystemStatus