import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import CustomSwitcher from "../../../../Layout/Elements/CustomSwitcher";
import {Link} from "react-router-dom";


const SoundsAndVibration = (props) => {
    const {t, i18n} = useTranslation();
    const [unit, setUnit] = useState(props.alarmType === 'lowAlarm' ? parseInt(t('dynamicValues.alarmsSettings.low')) : parseInt(t('dynamicValues.alarmsSettings.high')))


    console.log(props.alarmActivated)


    console.log(props.alarmActivated)
    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden align-content-between`}>
            <Col md={8} className={'align-items-center'}>
                <b>  {t("soundsAndVib")}</b>
            </Col>

            <Col md={4} className={'text-end px-0 position-relative'}>
                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                        onClick={() => {
                            props.navigateToComponent('settings')
                        }}>{t("ok")}</Button>
            </Col>

            <Row className={'flex-row justify-content-between mx-0 align-items-center'}>
                <Col md={4}><p className={'mb-0 small d-inline mx-0'}>{t("systemSound")}</p></Col>
                <Col md={8} className={'text-end'}> <CustomSwitcher
                                                                    alarmActivated={props.alarmActivated}
                                                                    setAlarmActivated={props.setAlarmActivated}
                                                                    alarmType={t("systemSound")}

                                                                    setSoundsActivated={props.setSoundsActivated}
                /></Col>

            </Row>
            <Row className={'flex-row justify-content-between mx-0 align-items-center'}>
                <Col md={4}><p className={'mb-0 small d-inline mx-0'}>{t("volume")}</p></Col>
                <Col md={8} className={'text-end'}> <CustomSwitcher
                                                                    alarmActivated={props.alarmActivated}
                                                                    setAlarmActivated={props.setAlarmActivated}
                                                                    alarmType={t("volume")}
                                                                    setSoundsActivated={props.setSoundsActivated}/></Col>

            </Row>
            <Row className={'flex-row justify-content-between mx-0 align-items-center'}>
                <Col md={4}><p className={'mb-0 small d-inline mx-0'}>{t("vibration")}</p></Col>
                <Col md={8} className={'text-end'}> <CustomSwitcher
                                                                    alarmActivated={props.alarmActivated}
                                                                    setAlarmActivated={props.setAlarmActivated}
                                                                    alarmType={t("vibration")}
                                                                    setSoundsActivated={props.setSoundsActivated}/></Col>

            </Row>
            <Row className={'flex-row justify-content-between mx-0 align-items-center'}>
                <Col md={4}><p className={'mb-0 small d-inline mx-0'}>{t("touchSounds")}</p></Col>
                <Col md={8} className={'text-end'}> <CustomSwitcher
                                                                    alarmActivated={props.alarmActivated}
                                                                    setAlarmActivated={props.setAlarmActivated}
                                                                    alarmType={t("touchSounds")}
                                                                    setSoundsActivated={props.setSoundsActivated}/></Col>

            </Row>


            <Col>{props.alarmType === 'signalLossAlarm' && (
                <p className={'text-smaller'}>{t("signalLossAlarm_text")}</p>)}</Col>


        </Row>
    )

}

export default SoundsAndVibration