import {Col, Row} from "react-bootstrap";
import parse from "html-react-parser";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {CiCircleInfo} from "react-icons/ci";
import ModalNote from "../../Components/Modal/ModalNote";
import GlucoseReadings from "../../Components/Reader/FirstTimeSetup/HomeScreens/GlucoseReadings";
import ReaderEmpty from "../../Components/Reader/ReaderEmpty";
import ScreenContainerFirstTime from "../../Components/Reader/FirstTimeSetup/ScreenContainerFirstTime";

const AddingNotes = () => {
    const {t} = useTranslation()
    const [turnOnOff, setTurnOnOff] = useState(true)
    const [homeBtnBehavior, setHomeBtnBehavior] = useState()
    const [currentStep, setCurrentStep] = useState('viewGlucose');
    const [fromSettingAlarms, setFromSettingAlarms] = useState(true)
    const [behaviorIndex, setBehaviorIndex] = useState(0);
    const behavior = ['High']

    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [imageReader, setImageReader] = useState()
    const [imageApp, setImageApp] = useState()

    const addingNotesReader_List_2 = parse(t("addingNotesReader_List_2").replace('%PENCIL_READER%', '<img src="/icons/reader_notes.png" />'))
    const addingNotesApp_List_1 = parse(t("addingNotesApp_List_1").replace('%PENCIL_IMG%', '<img src="/icons/app_notes.png" />'))
    const addingNotesReader_Info_2 = parse(t("addingNotesReader_Info_2").replace('%APPLE_IMG%', '<img src="/icons/food.png" />').replace('%INSULIN_IMG%', '<img src="/icons/insulin.png" />'))

    const readerSettingInfo_List_1 = parse(t("readerSettingInfo_List_1").replace('%SETTINGS_IMG%', '<img src="/icons/settings.png" /> '))
    const lang = localStorage.getItem('lang');

    useEffect(() => {
        setImageReader(`/translations/${lang}/ProductUse/adding_notes_reader.jpg`)
        setImageApp(`/translations/${lang}//ProductUse/adding_notes_app.jpg`)
    }, [lang])
    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={12} className={'text-start ps-4'}>
                <h2> {t("addingNotes")}</h2>


                <p className={'mb-0'}>{parse(t("addingNotesReader_Text"))}</p>
            </Col>
            <Col md={6} className={'text-start ps-4 mb-2'}>

                <Row className={'mx-0'}>
                    <Col md={12}>
                        <p className={'text-orange fw-bold mb-0 mt-2'}> {t("howToWithReader")}</p>

                        <hr className={'text-orange my-1 opacity-100'}/>
                        <p className={'mb-0 '}>
                            <span>{parse(t("addingNotesReader_List_1"))} </span>
                        </p>
                        <hr className={'text-orange my-1 opacity-100'}/>

                        <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                            <span>{addingNotesReader_List_2}</span>
                        </p>
                        <hr className={'text-orange my-1 opacity-100 mb-1'}/>
                        <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                            <span>{parse(t("addingNotesReader_List_3"))}</span>
                        </p>
                        <hr className={'text-orange my-0 opacity-100 mb-1'}/>
                        <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                            <span>{parse(t("addingNotesReader_List_4"))}</span>
                        </p>

                        <hr className={'text-orange my-0 opacity-100 mb-1'}/>
                        <p className={'mb-2 d-flex align-items-center justify-content-between'}>
                            <span>{parse(t("addingNotesReader_List_5"))}</span>
                            <span><CiCircleInfo className={'fs-3 ms-2 text-end text-orange'} onClick={() => {
                                setShow(true)
                                setTitle(t('note'))
                                setText(<ul>
                                    <li>{addingNotesReader_Info_2}</li>
                                </ul>)
                            }}/></span>
                        </p>
                        <hr className={'text-orange my-0 opacity-100 mb-3'}/>
                    </Col>
                    <p className={'fw-bold text-start mt-4 small'}>{t('disclaimer')}</p>
                </Row>


            </Col>
            <Col md={4} className={'align-content-start jusify-content-center offset-1 mb-2'}>

                <ReaderEmpty turnOnOff={turnOnOff} setTurnOnOff={setTurnOnOff} homeBtnBehavior={homeBtnBehavior}
                             setCurrentStep={setCurrentStep} currentStep={currentStep}>
                    {turnOnOff &&
                        <ScreenContainerFirstTime setHomeBtnBehavior={setHomeBtnBehavior} currentStep={currentStep}
                                                  setCurrentStep={setCurrentStep} fromSettingAlarms={fromSettingAlarms}
                                                  isFromAddingNotes={true} sensorIsAlreadyActive={true}
                                                  firstActivation={false}
                                                  behavior={behavior} behaviorIndex={behaviorIndex}  />}

                </ReaderEmpty>


                {/*<img src={imageReader} className={'img-fluid w-50'}/>*/}

            </Col>
            {/* <Col md={6} className={'text-start ps-4'}>
                <Row className={'mx-0'}>
                    <Col md={12} className={'mb-3'}>
                        <p className={'text-orange fw-bold mb-0 mt-2'}> {t("howToWithApp")}</p>

                        <hr className={'text-orange my-1 opacity-100'}/>
                        <p className={'mb-2 d-flex align-items-center justify-content-between'}>
                            <span>{addingNotesApp_List_1}</span>
                            <span><CiCircleInfo className={'fs-3 ms-2 text-end text-orange'} onClick={() => {
                                setShow(true)
                                setTitle(t('note'))
                                setText(t('addingNotesApp_Info_1'))
                            }}/></span>
                        </p>
                        <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                        <p className={'mb-2 d-flex align-items-center justify-content-between'}>
                            <span>{parse(t("addingNotesApp_List_2"))}</span>
                        </p>
                        <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                        <p className={'mb-2 d-flex align-items-center justify-content-between'}>
                            <span>{parse(t("addingNotesApp_List_3"))}</span>
                        </p>

                        <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                        <p className={'mb-2 d-flex align-items-center justify-content-between'}>
                            <span>{parse(t("addingNotesApp_List_4"))}</span>
                            <span><CiCircleInfo className={'fs-3 ms-2 text-end text-orange'} onClick={() => {
                                setShow(true)
                                setTitle(t('note'))
                                setText(t('addingNotesApp_Info_2'))
                            }}/></span>
                        </p>
                    </Col>
                </Row>
            </Col>
            <Col md={4} className={'align-content-start offset-1'} style={{marginTop:'-20px'}} >
                <img src={imageApp} className={'img-fluid w-50'}/>

            </Col>*/}
            <Row></Row>
            <Col md={12} className={'text-start align-self-end'}> <NavigationButtons t={t}
                                                                                     prevUrl={'/maintenance'}
                                                                                     nextUrl={'/reviewing-history'}/></Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>
    )
}
export default AddingNotes