import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "../../../Utils/UseTranslation";

const SensorReadings = () => {
    const {t} = useTranslation()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState(null);
    const sensorReadingNote_2 = t('sensorReadingNote_2', {
        settingsImageToken: '%TIME_IMG%',
        settingsImageValue: '<img src="/icons/reader_time.png" />'
    });

    const textData = [
        {id: 1, text: t("message"), x: "12%", y: "11.5%", right: '70%', class: 'text-end d-flex flex-column align-items-end'},
        {id: 2, text: t("currentGlucose"), text2: t('currentGlucoseText'), x: "8%", y: "33.5%", right: '70%', class: 'text-end d-flex flex-column align-items-end'},
        {id: 2, text: t("foodNote"),x: "12%", y: "52%", right: '70%', class: 'text-end d-flex flex-column align-items-end'},
        {id: 3, text: t("targetGlucoseRange"), text2: t("targetGlucoseRangeText"), x: "0", y: "65.4%", right: '70%', class: 'text-end d-flex flex-column align-items-end'},
        {id: 5, text: t("addNotes"), text2: t("addNoteText"), x: "67%", y: "5%"},
        {id: 6, text: t("glucoseTrendArrow"),text2: t("glucoseTrendArrowText"), x: "67%", y: "22%"},
        {id: 7, text: t("rapidActInsuline"), x: "67%", y: "51%"},
        {id: 8,text: t("highGlucoseAlarm"),x: "67%",y: "60%"},
        {id: 9,text: t("glucoseGraph"),x: "67%",y: "69%",},
        {id: 10,text: t("lowGlucoseAlarm"), x: "67%",y: "90%"}

    ];

    useEffect(() => {
        setImage(`/translations/${lang}/ProductUse/sensor_readings.png`)
    }, [lang])
    return (
        <>
            <Row>
                <Col md={4}>
                    <ul>
                        <li>{t('sensorReadingNote_1')}</li>
                        <li>{sensorReadingNote_2}</li>
                        <li>{t('sensorReadingNote_3')}</li>
                        <li>{t('sensorReadingNote_4')}</li>
                    </ul>
                </Col>
                <Col md={8} sm={8} className={'position-relative text-start align-self-center '}>
                    <Row className={'image-container'}>
                        <img src={image} className={'img-fluid'}/>
                        <div>  {textData.map(item => (
                            <div
                                key={item.id}
                                className={`text-overlay ${item.class}`}
                                style={{left: item.x, top: item.y, right: item.right && item.right}}
                            >
                                <p className="text mb-1">{item.text}</p>
                                <p className="small secondaryText">{item.text2}</p>
                            </div>
                        ))}
                        </div>
                    </Row>
                </Col>
            </Row>


        </>
    )
}
export default SensorReadings