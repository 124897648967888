import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaChevronRight} from "react-icons/fa";

const ProductOverviewNav = (props) => {
    return (
        <Row className={'bg-white shadow  mx-0 text-black p-2 text-start subMenu px-0'}>
            <Col md={12}>
                <ul className={'list-unstyled text-start py-0'}>
                    <li className={'py-2 '}>
                        <Link to={'system-overview'} onClick={props.handleClickInner}
                              className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('systemOverviewTitle')}
                         {/*   <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'py-2 '}>
                        <Link to={'getting-to-know-reader'} className={'d-inline-flex w-100 justify-content-between '}
                              onClick={props.handleClickInner}>
                            {props.t('gettingToKnowTitle')}
                         {/*   <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'py-2 '}>
                        <Link to={'sensor-kit'} className={'d-inline-flex w-100 justify-content-between '}
                              onClick={props.handleClickInner}>
                            {props.t('sensorKitTitle')}
                         {/*   <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'py-2 '}>
                        <Link to={'reader-kit'} className={'d-inline-flex w-100 justify-content-between '}
                              onClick={props.handleClickInner}>
                            {props.t('readerKitTitle')}
                         {/*   <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'py-2 '}>
                        <Link to={'sensor-glucose-reading'} className={'d-inline-flex w-100 justify-content-between '}
                              onClick={props.handleClickInner}>
                            {props.t('sensorGlucoseReadingsTitle')}
                         {/*   <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                </ul>
            </Col>

        </Row>
    )
}
export default ProductOverviewNav