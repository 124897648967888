import {Col, Row} from "react-bootstrap";
import {useEffect} from "react";

const TouchScreenTest = () => {

    useEffect(() => {
        const elements = document.querySelectorAll('.numberCol');

        elements.forEach((element, index) => {
            let currentIndex = -1; // Inizializza currentIndex per ciascun elemento

            element.addEventListener('click', function () {
                // Nascondi il box attualmente visibile
                if (currentIndex !== -1) {
                    elements[currentIndex].querySelector('span').classList.add('invisible');
                }

                // Mostra il box cliccato
                const spanElement = element.querySelector('span');
                if (spanElement) {
                    spanElement.classList.remove('invisible');
                    currentIndex = index; // Aggiorna currentIndex per questo elemento
                }

                // Avvia un timeout per nascondere il box dopo un certo periodo di tempo
                setTimeout(function () {
                    if (currentIndex !== -1) {
                        elements[currentIndex].querySelector('span').classList.add('invisible');
                        currentIndex = -1;
                    }
                }, 3000);
            });
        });
        return () => {
            // Rimuovi gli event listener quando il componente viene smontato
            elements.forEach((element) => {
                element.removeEventListener('click', () => {});
            });
        };
    }, []); // Assicurati di passare un array vuoto come dipendenza per eseguire l'effetto solo una volta

    return (<>
        <Row className={'mx-0 px-0'}>
            <Col
                className={'fs-2 fw-bold d-inline-flex align-items-center justify-content-center numberCol '}><span
                className={'invisible'}>1</span></Col>
            <Col
                className={'numberCol fs-2 fw-bold d-inline-flex align-items-center justify-content-center '}><span
                className={'invisible'}>2</span></Col>
            <Col
                className={'numberCol fs-2 fw-bold d-inline-flex align-items-center justify-content-center'}><span
                className={'invisible'}>3</span></Col>
        </Row>
        <Row className={'mx-0 px-0'}><Col
            className={'numberCol fs-2 fw-bold d-inline-flex align-items-center justify-content-center'}><span
            className={'invisible'}>4</span></Col>
            <Col
                className={'numberCol fs-2 fw-bold d-inline-flex align-items-center justify-content-center'}><span
                className={'invisible'}>5</span></Col>
        </Row>
        <Row className={'mx-0 px-0'}><Col
            className={'numberCol fs-2 fw-bold d-inline-flex align-items-center justify-content-center'}><span
            className={'invisible'}>6</span></Col>
            <Col
                className={'numberCol fs-2 fw-bold d-inline-flex align-items-center justify-content-center'}><span
                className={'invisible'}>7</span></Col>
        </Row>
        <Row className={'mx-0 px-0'}>
            <Col
                className={'numberCol fs-2 fw-bold d-inline-flex align-items-center justify-content-center'}><span
                className={'invisible'}>8</span></Col>
            <Col
                className={'numberCol fs-2 fw-bold d-inline-flex align-items-center justify-content-center'}><span
                className={'invisible'}>9</span></Col>
        </Row>
        <Row className={'mx-0 px-0'}><Col md={6}
                                          className={'numberCol fs-2 fw-bold d-inline-flex align-items-center justify-content-center'}><span
            className={'invisible'}>10</span></Col>
            <Col md={3}
                 className={'numberCol fs-2 fw-bold d-inline-flex align-items-center justify-content-center'}><span
                className={'invisible'}>11</span></Col>
            <Col md={3}
                 className={'numberCol fs-2 fw-bold d-inline-flex align-items-center justify-content-center'}><span
                className={'invisible'}>12</span></Col>
        </Row>


    </>)
}

export default TouchScreenTest