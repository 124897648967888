import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaChevronRight} from "react-icons/fa";

const ProductUseNav = (props) => {
    return (
        <Row className={'bg-white shadow  mx-0 text-black p-2 text-start subMenu px-0'}>
            <Col md={12}>
                <ul className={'list-unstyled text-start py-0 mb-0'}>
                    <li className={'py-2 '}>
                        <Link to={'/understanding-readings'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('understandingSensor')} - {props.t('reader')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'py-2 '}>
                        <Link to={'/using-glucose-alarms'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('usingHiLoGlucose')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'py-2 '}>
                        <Link to={'/using-signalLoss-alarms'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('usingSignalLossAlarm')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'py-2 '}>
                        <Link to={'/treatment-decision-guide'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('treatmentDecisionGuide')}

                        </Link>
                    </li>
                   {/* <li className={'py-2 '}>
                        <Link to={'/treatment-knowledge-check'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('treatmentDecisionGuide')} - {props.t('knowledgeCheck')}
                            <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>
                        </Link>
                    </li>*/}
                    <li className={'py-2 '}>
                        <Link to={'/checking-with-strip'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('checkingWithTestStrip')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'py-2 '}>
                        <Link to={'/control-solution-testing'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('controlSolutionTesting')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>

                </ul>
            </Col>
            <Col>
                <ul className={'list-unstyled text-start'}>

                    <li className={'py-2 '}>
                        <Link to={'/living-with-your-system'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('livingWSystem')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'py-2 '}>
                        <Link to={'/charging-reader'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('chargingReader')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'py-2 '}>
                        <Link to={'/reader-settings-info'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('readerSettingInfo')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                  {/*  <li className={'py-2 '}>
                        <Link to={'/app-settings-info'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('appSettingsInfo')}
                            <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>
                        </Link>
                    </li>*/}
                    <li className={'py-2 '}>
                        <Link to={'/maintenance'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('maintenanceDisposal')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'py-2 '}>
                        <Link to={'/adding-notes'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('addingNotes')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'py-2 '}>
                        <Link to={'/reviewing-history'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('reviewingHistory')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                </ul>
            </Col>

        </Row>
    )
}
export default ProductUseNav