import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import controlSolution_1 from "../../../../../assets/img/Reader/Settings/control_Solution_1.jpg";
import controlSolution_2 from "../../../../../assets/img/Reader/Settings/control_Solution_2.jpg";
import controlSolution_3 from "../../../../../assets/img/Reader/Settings/control_Solution_3.jpg";
import waiting_1 from "../../../../../assets/img/Reader/Settings/waiting1.jpg";
import waiting_2 from "../../../../../assets/img/Reader/Settings/waiting2.jpg";
import waiting_3 from "../../../../../assets/img/Reader/Settings/waiting3.jpg";
import {Col, Row} from "react-bootstrap";
import {CiBatteryFull} from "react-icons/ci";
import {Link} from "react-router-dom";
import useSound from "use-sound";
import sound from './../../../../../assets/sounds/system-self-test.mp3'
import parse from "html-react-parser";
import TouchScreenTest from "./ReaderTest/TouchScreenTest";
import ReaderTestSteps from "./ReaderTest/ReaderTestSteps";

const ReaderTest = () => {
    const {t, i18n} = useTranslation();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const [loopImage, setLoopImage] = useState(false)
    let imageArray = [waiting_1, waiting_2, waiting_3];
    const intervalIdRef = useRef(null);
    const [repeatCount, setRepeatCount] = useState(0);

    const [step, setStep] = useState(0)
    const steps = [
        {
            title: t("selfTest"),
            text: "...",
            text2: t("pass"),
            buttonText: t("next"),
            nextStep: 1,
        },
        {
            title: t("displayTest"),
            text: t("displayTest_text"),
            buttonText: t("start"),
            nextStep: 2,
        },
        {

        },
        {
            title: t("displayTest"),
            text: t("displayTestFinish_text"),
            buttonText: t("next"),
            nextStep: 4,
        },
        {
            title: t("soundsTest"),
            text: t("soundsTest_text"),
            buttonText: t("start"),
            nextStep: 5,
        },
        {
            title: t("soundsTest"),
            text: t("soundsTestFinished_text"),
            buttonText: t("next"),
            nextStep: 6,
        },
        {
            title: t("touchTest"),
            text: parse(t("touchTest_text")),
            buttonText: t("next"),
            nextStep: 7,
        },
    ];
    const [playSound] = useSound(sound);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % imageArray.length);
        }, 1000); // Cambia immagine ogni 3 secondi

        // Salva intervalId nella variabile ref
        intervalIdRef.current = intervalId;

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {

        // Controlla se abbiamo visualizzato l'ultima immagine e ferma l'intervallo
        if (currentImageIndex >= imageArray.length - 1) {
            setTimeout(() => {
                if (repeatCount < 1) {
                    setCurrentImageIndex(0); // Ripeti le ultime tre immagini
                    setRepeatCount(prevCount => prevCount + 1);
                } else {
                    clearInterval(intervalIdRef.current);
                    setLoopImage(true);
                }
            }, 500);
        }
    }, [currentImageIndex, repeatCount]);

    useEffect(() => {
        const element = document.querySelector('.displayTest');

        if (step === 2 && element) {
            setTimeout(() => {
                element.style.backgroundColor = '#fff';
                setTimeout(() => {
                    element.style.backgroundColor = '#000';
                    setTimeout(() => {
                        setStep(3);
                    }, 2000);
                }, 2000);
            }, 10);
        }

        if (step === 5) {
            playSound()
        }


    }, [step])
    const handleButtonClick = (nextStep) => {
        setStep(nextStep);
    };

    return (
        <Row
            className={`mx-0 h-100 text-start align-content-stretch  overflow-hidden ${(step === 2 || step === 7) && 'px-0'}`}>
            {!loopImage && (
                <>
                    <Col><b>{t("selfTest")}</b></Col>
                    <Col md={12} className={'px-0'}>

                        <img src={imageArray[currentImageIndex]} alt="Immagine" className={'img-fluid'}/>
                    </Col>
                </>
            )}

            {loopImage && (
                <>
                    {step === 2 ?  (
                        <div className="displayTest"></div>
                    ) : steps.map((stepInfo, index) => (
                        step === index && (
                            <ReaderTestSteps stepInfo={stepInfo} handleButtonClick={handleButtonClick}/>
                        )
                    ))}

                    {step === 7 && (

                       <TouchScreenTest />
                    )
                    }
                </>
            )}

        </Row>
    )
}

export default ReaderTest