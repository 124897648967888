import {useEffect, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import HomeScreenHeader from "./HomeScreenHeader";
import sensorSteps_1 from './../../../../assets/img/Reader/startNewSensor/startSensor_1.jpg'
import sensorSteps_2 from './../../../../assets/img/Reader/startNewSensor/startSensor_2.jpg'
import sensorSteps_3 from './../../../../assets/img/Reader/startNewSensor/startSensor_3.jpg'
import {useTranslation} from "react-i18next";
import parse from "html-react-parser";
import {Link} from "react-router-dom";

const SensorCountdown = (props) => {
    const {t, i18n} = useTranslation();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showImage, setShowImage] = useState(true);
    const [countDown, setCountDown]= useState(60)
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [completedCycles, setCompletedCycles] = useState(0);
    useEffect(() => {
        const imageArray = [sensorSteps_1, sensorSteps_2, sensorSteps_3];
        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % imageArray.length);
        }, 1000); // Cambia immagine ogni 3 secondi

        // Incrementa il numero di cicli completati ogni volta che tutte le immagini hanno fatto il ciclo
        const cyclesIntervalId = setInterval(() => {
            setCompletedCycles(prevCycles => prevCycles + 1);
        }, (imageArray.length * 1500) * 2); // Intervallo di tempo per completare due cicli di tutte le immagini

        // Simulazione del caricamento delle immagini
        setTimeout(() => {
            setImagesLoaded(true);
        }, (imageArray.length * 900) * 2); // Simulare il caricamento delle immagini subito dopo che sono stati completati due cicli

        // Pulisci l'intervallo quando il componente viene smontato
        return () => {
            clearInterval(intervalId);
            clearInterval(cyclesIntervalId);
        };
    }, []); // Effetto si attiva solo all'avvio del componente

// Avvia il countdown solo quando le immagini sono state caricate
    useEffect(() => {
        if (imagesLoaded) {
            const interval = setInterval(() => {
                setCountDown(prevCount => {
                    if (prevCount === 0) {
                        clearInterval(interval); // Fermare il contatore quando raggiunge 0
                        // Puoi anche aggiungere qui altre azioni da eseguire quando il contatore raggiunge 0
                    }
                    return prevCount > 0 ? prevCount - 1 : 0; // Assicurarsi che il contatore non scenda sotto 0
                });
            }, 200); // Intervallo di aggiornamento di 1 secondo

            return () => clearInterval(interval); // Pulizia dell'intervallo quando il componente viene smontato
        }
    }, [imagesLoaded]);

// Azioni quando il countdown raggiunge 0
    useEffect(() => {
        if (countDown === 0) {
            props.setFirstActivation(false);
            props.setSensorIsActive(true);
            localStorage.setItem('sensorIsActive', true);
            props.navigateToComponent('homeScreen');
        }
    }, [countDown]);

// Questo effetto viene eseguito solo una volta all'inizio
    useEffect(() => {
        localStorage.setItem('sensorIsActive', props.sensorIsActive);
    }, [props.sensorIsActive]);

    useEffect(() => {
        if(props.sensorIsActive) {
            setShowImage(false)

        }
        // Dopo la visualizzazione dell'ultima immagine, passa alla schermata successiva
        if (currentImageIndex === 2) {
            setTimeout(() => {
                // Naviga alla schermata successiva dopo 3 secondi dall'ultima immagine
                setShowImage(false)
            }, 3000);
        }
        console.log(props.sensorIsActive)
    }, [currentImageIndex, props.sensorIsActive]);

    return (
        <Row className={'mx-0 h-100 text-start justify-content-start overflow-hidden'}>
            <Col md={12} className={'align-items-center px-0'}>
                {props.sensorIsActive && (
                    <Row className={'mx-0 justify-content-stretch h-100'}>
                        <Col md={12}><b> {t('startNewSensor')}</b>
                            <p>{parse(t('startingNewSensorMex'))}</p>
                            <p>{parse(t('startingNewSensorMex_2'))}</p></Col>
                        <Col className={'text-start mt-2 align-items-end d-inline-flex pb-1'}>
                            <Link onClick={() => {
                                props.navigateToComponent('homeScreen')
                            }} className={'btn btn-secondary w-100'} to={''}>{t('no')}</Link>
                        </Col>
                        <Col className={'text-end mt-2 align-items-end d-inline-flex pb-1'}>
                            <Link onClick={() => {
                                props.setSensorIsActive(false)
                                props.setFirstActivation(true)
                                setShowImage(true)

                            }} className={'btn btn-primary w-100'} to={''}>{t('yes')}</Link>
                        </Col>
                    </Row>
                )}
                {showImage &&
                    <>
                        <b> {t('startNewSensor')}</b>
                        <img
                            src={currentImageIndex === 0 ? sensorSteps_1 : currentImageIndex === 1 ? sensorSteps_2 : sensorSteps_3}
                            alt="Immagine" className={'img-fluid '}/>
                        <p className={'px-1 pt-2'}> {t('startSensor')}</p>
                    </>
                }
                {!showImage && !props.sensorIsActive && (
                    <Row className={'mx-0 '}>
                        <Col md={8} className={'px-0 tex-start align-content-start'}><span
                            className={'text-start fw-bold mb-3'}> {t('newSensorStarting')}</span></Col>
                        <Col md={4} className={'text-end px-0 position-relative'}>
                            <Button className={'position-absolute py-1 pe-3'}
                                    style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}} onClick={() => {
                                props.navigateToComponent('homeScreen')
                                props.setFirstActivation(false)

                                props.setSensorIsActive(true)
                                localStorage.setItem('sensorIsActive', true)
                            }}>OK</Button>
                        </Col>
                        <Col className={'text-center mx-0 mt-3'}>
                            <p className={'px-1 pt-2 text-start'}> {t('sensorCanBeUsed')}</p>
                            <h2 className={'fw-bold mb-0'} style={{fontSize: '80px'}}>{countDown}</h2>
                            <p className={'px-1 '}> {t('minutes')}</p>
                        </Col>


                    </Row>)}

            </Col>
        </Row>
    );
}

export default SensorCountdown