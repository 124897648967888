import React, {useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import en from './../../assets/img/Flags/en.png'
import it from './../../assets/img/Flags/it.png'
import de from './../../assets/img/Flags/de.png'
import fr from './../../assets/img/Flags/fr.png'
import ch from './../../assets/img/Flags/ch.png'
import es from './../../assets/img/Flags/es.png'
import no from './../../assets/img/Flags/no.png'
import fi from './../../assets/img/Flags/fi.png'
import at from './../../assets/img/Flags/at.png'
import se from './../../assets/img/Flags/se.png'
import us from './../../assets/img/Flags/us.png'
import {useTranslation} from "react-i18next";
// import es from './../../assets/img/Flags/es.png'
const LanguageSelector = (props) => {
    const {t} = useTranslation('languages')
    return (

        <Row className={'h-100 justify-content-center mt-5 mx-0'}>
            <h2>Select Your Language</h2>
            <Col md={6} className={'bkg-ligth-grey-2 rounded-5 mt-5'}>
                <Row className={'justify-content-center my-3 align-self-center mt-5'}>
                    {/*<Col md={3} className={' mt-3'} >
                       <Link  onClick={() =>  props.onSelectLanguage('us')}> <img src={us} alt={'us'} className={'img-fluid w-50'}/> <br /> {t('us')}</Link>

                   </Col>*/}
                    <Col md={3} className={' mt-3'}>
                        <Link onClick={() => props.onSelectLanguage('en')}> <img src={en} alt={'en'}
                                                                                 className={'img-fluid w-50'}/>
                            <br/> {t('en')}</Link>

                    </Col>
                    <Col md={3} className={' mt-3'}>
                        <Link  style={{filter: 'grayscale(1)', cursor: 'initial'}}> <img src={it} alt={'en'}
                                                                                                 className={'img-fluid w-50 '}
                                                                                                 />
                            <br/> {t('it')} </Link>

                    </Col>


                    <Col md={3} className={' mt-3'}>
                        <Link  style={{filter: 'grayscale(1)', cursor: 'initial'}}> <img src={fr} alt={'de'}
                                                                                 className={'img-fluid w-50'}/>
                            <br/> {t('fr')} </Link>

                    </Col>
                    <Col md={3} className={' mt-3'}>
                        <Link  style={{filter: 'grayscale(1)', cursor: 'initial'}}> <img src={de} alt={'de'}
                                                                                 className={'img-fluid w-50'}/>
                            <br/> {t('de')} </Link>

                    </Col>
                    <Col md={3} className={' mt-3'}>
                        <Link style={{filter: 'grayscale(1)', cursor: 'initial'}}> <img src={de} alt={'de'}
                                                                                 className={'img-fluid w-50'}/>
                            <br/> {t('de_mmoll')} </Link>

                    </Col>


                    <Col md={3} className={' mt-3'}>
                        <Link  style={{filter: 'grayscale(1)', cursor: 'initial'}}> <img src={es} alt={'es'}
                                                                                 className={'img-fluid w-50'}/>
                            <br/> {t('es')} </Link>

                    </Col>
                    <Col md={3} className={' mt-3'}>
                        <Link  style={{filter: 'grayscale(1)', cursor: 'initial'}}> <img src={fi} alt={'fi'}
                                                                                 className={'img-fluid w-50'}/>
                            <br/> {t('fi')} </Link>

                    </Col>
                    <Col md={3} className={' mt-3'}>
                        <Link style={{filter: 'grayscale(1)', cursor: 'initial'}}> <img src={at} alt={'at'} className={'img-fluid w-50'}/>
                            <br/> {t('at')} </Link>

                    </Col>


                    <Col md={3} className={' mt-3'}>
                        <Link  style={{filter: 'grayscale(1)', cursor: 'initial'}}> <img src={ch} alt={'es'}
                                                                                    className={'img-fluid w-50'}/>
                            <br/> {t('ch_it')} </Link>

                    </Col>
                    <Col md={3} className={' mt-3'}>
                        <Link style={{filter: 'grayscale(1)', cursor: 'initial'}}> <img src={ch} alt={'fi'}
                                                                                    className={'img-fluid w-50'}/>
                            <br/> {t('ch_fr')} </Link>

                    </Col>
                    <Col md={3} className={' mt-3'}>
                        <Link  style={{filter: 'grayscale(1)', cursor: 'initial'}}> <img src={ch} alt={'at'}
                                                                                    className={'img-fluid w-50'}/>
                            <br/> {t('ch_de')} </Link>

                    </Col>

                </Row>
                <Row className={'justify-content-center my-3'}>

                    <Col></Col>
                </Row>
            </Col>


        </Row>
    );
}

export default LanguageSelector;