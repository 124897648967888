import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import {HiOutlineBookOpen} from "react-icons/hi2";
import {BsGraphUp} from "react-icons/bs";
import {VscGraph} from "react-icons/vsc";
import {FiWifi} from "react-icons/fi";
import {TfiExchangeVertical, TfiTarget} from "react-icons/tfi";
import {BsArrowDownSquare} from "react-icons/bs";
import {Link} from "react-router-dom";


const ReviewHistory = (props) => {
    const {t, i18n} = useTranslation();
    const lang = localStorage.getItem('lang');
    const [triggerRender, setTriggerRender] = useState(false); // Stato di trigger per forzare il re-rendering
    const [isDetail, setIsDetail] = useState(true)
    const [steps, setStep] = useState(1)


    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden ${steps === 1 ? 'align-content-stretch ' : 'align-content-start '}`}>
            <Col md={8} className={'align-items-center'}>
                <b> {t('reviewHistory')}</b>
            </Col>


            <Col md={12}
                 className={`align-content-start d-inline-flex flex-column col-md-12 ${steps === 1 ? 'justify-content-around' : ''}`}>

                {steps === 1 ? (
                    <>
                        <Row className={'align-items-center mx-0'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}  onClick={() => {props.navigateToComponent('logbook')}}>
                                    <HiOutlineBookOpen className={'fs-4 me-1'} onClick={() => {props.navigateToComponent('logbook')}}/> <span
                                    className={'small'}>{t("logbook")}</span></Link>

                            </Col>

                        </Row>
                        <Row className={'align-items-center mx-0'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'} onClick={() => {
                                    props.navigateToComponent('graphs')
                                    props.setGraphType('dailyGraph')
                                }}>
                                    < BsGraphUp className={'fs-4 me-1'}/><span
                                    className={'small'}>{t("dailyGraph")}</span></Link>
                            </Col>

                        </Row>
                        <Row className={'align-items-center mx-0'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'} onClick={() => {
                                    props.navigateToComponent('graphs')
                                    props.setGraphType('averageGlucose')
                                }}>
                                    <VscGraph className={'fs-4 me-1'}/><span
                                    className={'small'}> {t("averageGlucose")}</span></Link>
                            </Col>

                        </Row>


                        <Row className={'justify-content-center text-center mb-0 '}>
                            <div className={'text-center'}>
                    <span onClick={() => setStep(2)}>
                    <BiSolidDownArrow style={{
                        fontSize: '30px',
                        color: 'var(--light-blue)',
                        filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                    }}/>

                        </span>
                            </div>

                        </Row>
                    </>
                ) : (
                    <>
                        <Row className={'justify-content-center text-center mb-0 '}>
                            <div className={'text-center'}>
                           <span onClick={() => setStep(1)}>
                                 <BiSolidUpArrow style={{
                                     fontSize: '30px',
                                     color: 'var(--light-blue)',
                                     filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                                 }}/>
                            </span>
                            </div>
                        </Row>

                        <Row className={'align-items-center mx-0 '}>
                            <Col md={12} className={'px-1'}>
                                <Link
                                    className={'btn btn-secondary w-100 text-black border-groove text-start'} onClick={() => {
                                    props.navigateToComponent('graphs')
                                    props.setGraphType('dailyPatterns')
                                }}><TfiExchangeVertical  className={'fs-4 me-1'} /> <span
                                    className={'small'}>{t("dailyPatterns")}</span></Link>
                            </Col>

                        </Row>
                        <Row className={'align-items-center mx-0 mt-3'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'} onClick={() => {
                                    props.navigateToComponent('graphs')
                                    props.setGraphType('timeInTarget')
                                }}>
                                    <TfiTarget className={'fs-4 me-1'}/> <span
                                    className={'small'}> {t("timeInTarget")} </span></Link>
                            </Col>

                        </Row>
                        <Row className={'align-items-center mx-0 mt-3'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'} onClick={() => {
                                    props.navigateToComponent('graphs')
                                    props.setGraphType('lowGlucoseEvents')
                                }}>
                                    <BsArrowDownSquare className={'fs-4 me-1'}/> <span
                                    className={'small'}>{t("lowGlucoseEvents")}</span></Link>
                            </Col>

                        </Row>
                        <Row className={'align-items-center mx-0 mt-3'}>
                            <Col md={12} className={'px-1'}>
                                <Link onClick={() => {
                                    props.navigateToComponent('graphs')
                                    props.setGraphType('sensorUsage')
                                }}
                                      className={'btn btn-secondary w-100 text-black border-groove text-start'}>
                                    <FiWifi className={'fs-4 me-1'}/> <span
                                    className={'small'}>{t("sensorUsage")} </span></Link>
                            </Col>

                        </Row>

                    </>
                )}


            </Col>
        </Row>
    )
        ;

}

export default ReviewHistory