import {Modal} from "react-bootstrap";

const ModalNote = (props) => {
    return (
        <Modal show={props.show} onHide={props.handleClose} className={`${props.dimension}`}>
            <Modal.Header closeButton><span className={'text-orange fw-bold'}> {props.title}</span></Modal.Header>
            <Modal.Body>
                {props.text}
            </Modal.Body>
        </Modal>
    )
}

export default ModalNote