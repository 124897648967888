import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Row} from "react-bootstrap";
import TargetComponent from "./Elements/TargetComponent";

const ReportingLimits = (props) => {
    const {t, i18n} = useTranslation();
    const [isInfo, setIsInfo] = useState(false)

    return (
        <Row className={`mx-0 h-100 overflow-hidden ${isInfo && 'align-content-start'}`}>
            <TargetComponent lowMinValue={"dynamicValues.reportLimitSetup.min"}
                             highMinValue={"dynamicValues.reportLimitSetup.max"}
                             lowMaxRange={"dynamicValues.reportLimitSetupMaxValues.min"}
                             highMaxRange={"dynamicValues.reportLimitSetupMaxValues.max"}
                             targetRange={props.reportLimit} setTargetRange={props.setReportLimit}
                             title={"reportingLimits"} actionTodo={() => {
                props.navigateToComponent('settings')
            }} infoText={'reportingLimitsInfoText'} infoTitle={'reportingLimits'} btnText={'done'} incrementMinValue={5} incrementMaxValue={10}/>


        </Row>

    )
        ;
}

export default ReportingLimits