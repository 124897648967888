import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";

const NavigationButtons = (props) => {

    return (
        <Row className={'mx-0 '}>
            <Col className={'px-0 mb-1'}>
                <Link to={props.prevUrl} onClick={props.onClickPrev}
                      className={'btn bkg-primary  pe-3 me-1 btnNav'}>{props.t('previous')}</Link>
                {/*<span className={'text-orange pe-3 '}>| </span>*/}
                <Link to={props.nextUrl} onClick={props.onClickNext}
                      className={`btn bkg-primary ms-1 btnNav ${props.disabled && 'disabled'}`}
                      style={{pointerEvents: props.disabled && 'none' }}>{props.t('next')}</Link>
            </Col>
        </Row>
    )
}

export default NavigationButtons