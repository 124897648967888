import {useTranslation} from "../../Utils/UseTranslation";
import {Col, Row} from "react-bootstrap";
import readerKit from '../../assets/img/ProductOverview/ReaderKit/readerKit.png'

import './../../assets/css/ProductOverview/SensorGlucoseReading.css'
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";

const ReaderKit = () => {
    const {t, i18n} = useTranslation()
    let readersStrings = []
    for (let i = 2; i < 9; i++) {
        readersStrings.push('readerKit_Text_' + i);
    }
    // Array di oggetti testo con le informazioni per il posizionamento
    const textData = [
        {id: 1, text: t("usbPort"), text2:t("usbPortText"), x: "4%", y: "14%"},
        {id: 2, text: t("testStripPort"), text2: t('testStripPortText'), x: "21%", y: "48%"},
        {id: 3, text: t("touch"), x: "67%", y: "5%"},
        {id: 5, text: t("homeBtn"),text2: t('homeBtnText'), x: "70%", y: "34.5%"},
        {id: 7, text: t("cable"), x: "70%", y: "69%"},
        {id: 8, text: t("powerAdapter"),text2: t('powerAdapterText'), x: "14%", y: "80%"}

    ];
    return (
        <>
            <Col md={4} className={'text-start ps-4'}>

                <h2> {t("readerKitTitle")}</h2>
                <p>{t("readerKit_Text_1")}</p>
                <ul>
                {readersStrings.map((i, index) => {
                    return <li key={index} className={'h5'}>{t(i)}</li>
                })}
                </ul>

            </Col>
            <Col md={8} sm={8} className={'position-relative text-start align-self-center'}>
                <Row className={'image-container'}>


                    <img src={readerKit} className={'img-fluid'}/>
                    <div>  {textData.map(item => (
                        <div
                            key={item.id}
                            className="text-overlay"
                            style={{left: item.x, top: item.y}}
                        >
                            <p className="text mb-1">{item.text}</p>
                            <p className="small secondaryText">{item.text2}</p>
                        </div>
                    ))}
                    </div>
                </Row>


            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/sensor-kit'} nextUrl={'/sensor-glucose-reading'}/>
            </Col>
        </>


    )
}

export default ReaderKit