import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useState} from "react";

const InsulinCalculator = (props) => {
    const [calcOffInfo, setCalcOffInfo] = useState(false)
    return (
        <Row className={`mx-0 h-100 overflow-hidden ${calcOffInfo ? 'align-content-between': 'align-content-start'}`}>
            {!calcOffInfo ? (
                <>

                    <Col md={8} className={'align-items-center px-0 align-items-start text-start ps-2'}>
                        <b>  {props.t("calculatorSettings")}</b>
                    </Col>

                    <Col md={4} className={'text-end px-0 position-relative px-0'}>
                        <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                onClick={() => {
                                    props.navigateToComponent('settings')
                                }}>{props.t("ok")}</Button>
                    </Col>
                    <Row className={'align-items-center mx-0 mt-3'}>
                        <Col md={12} className={'px-1'}>
                            <Link className={'btn btn-secondary w-100 text-black border-groove text-center'}
                                  onClick={() => {
                                      setCalcOffInfo(true)
                                      props.setCalculatorOff(true)
                                  }}>
                                   <span
                                       className={'small'}>{props.t("turnOffCalc")}</span></Link>

                        </Col>

                    </Row>
                    <Row className={'align-items-center mx-0  mt-3'}>
                        <Col md={12} className={'px-1'}>
                            <Link className={'btn btn-secondary w-100 text-black border-groove text-center'}
                                  onClick={() => {
                                      props.navigateToComponent('calculatorSetup')

                                  }}>
                                    <span
                                        className={'small'}>{props.t("changeCalcSettings")}</span></Link>
                        </Col>

                    </Row>

                </>) : (
                <>
                    <Col md={12} className={`align-content-start d-inline-flex text-start flex-column col-md-12 mt-5`}>
                        <p>{props.t("calcOffInfo")}</p>
                        <p>{props.t("calcOffInfo_2")}</p>
                    </Col>
                    <Col md={6} className={'offset-6 mb-2 align-self-end'}><Link onClick={() => {

                        props.navigateToComponent('settings')
                    }} className={'btn btn-primary w-100'} to={''}>{props.t('done')}</Link></Col>
                </>

            )}
        </Row>
    )
}
export default InsulinCalculator