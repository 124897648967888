import {VscTarget} from "react-icons/vsc";
import {PiSyringeThin} from "react-icons/pi";
import {FaRegBell} from "react-icons/fa";
import {useTranslation} from "react-i18next";
const ReminderTypeIcon = (props) => {
    const {t} = useTranslation()
    return (
        props.reminderType ===  t('checkGlucose') ? (
            <VscTarget  className={'fs-6'}/>
        ) : props.reminderType === t('takeInsulin')  ? (
            <PiSyringeThin
                className={'fs-6'}/>
        ) : (
            <FaRegBell className={'fs-6'} />
        )
    )
}

export default ReminderTypeIcon