import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FaHome} from "react-icons/fa";
import {Link} from "react-router-dom";
import '../../assets/css/Header.css'
import ProductOverviewNav from "./HeaderMenu/ProductOverviewNav";
import {useEffect, useState} from "react";
import ReaderNav from "./HeaderMenu/ReaderNav";
import SensorNav from "./HeaderMenu/SensorNav";
import ProductUseNav from "./HeaderMenu/ProductUseNav";
import MoreInformationNav from "./HeaderMenu/MoreInformationNav";
import AppNav from "./HeaderMenu/AppNav";

const Header = () => {
    const {t, i18n} = useTranslation()
    const [openSubMenu, setOpenSubMenu] = useState(null);
    const [isOpen, setIsOpen] = useState(false);


    // Funzione per gestire il click sul link e aprire/chiudere il sottomenu
    const handleLinkClick = (subMenu) => {
        if (openSubMenu === subMenu) {
            // Chiudi il sottomenu se è già aperto
            setOpenSubMenu(null);
        } else {
            // Altrimenti, apri il nuovo sottomenu
            setOpenSubMenu(subMenu);
        }
    };

    const handleClickInner = (e) => {
         setOpenSubMenu(null); // Chiude il menu quando viene cliccato un link
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Chiudi il menu se il click avviene al di fuori di esso
            if (openSubMenu && !event.target.closest('.header')) {
                setOpenSubMenu(null);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [openSubMenu]);
    return (
        <>
            <Row className={`text-dark-blue header justify-content-stretch position-relative mx-0 shadow`}>
                <Col style={{maxWidth: '10px'}}></Col>
                <Col className={`py-2 position-relative mainNavLink ${openSubMenu === 'productOverview' && 'active'}`}>
                    <Link to={'#'} className={``} onClick={() => handleLinkClick('productOverview')}>
                        {t("productOverview")}
                    </Link>
                    <Col className={'position-absolute left-0'} style={{top: '45px', left: 0}}>
                        {openSubMenu === 'productOverview' && <ProductOverviewNav t={t} handleClickInner={handleClickInner}/>}
                    </Col>
                </Col>
                <Col className={`py-2 mainNavLink position-relative ${openSubMenu === 'reader' && 'active'}`}>
                    <Link to={'#'}  onClick={() => handleLinkClick('reader')}>
                        {t("reader")}
                    </Link>
                    <Col className={'position-absolute left-0'} style={{top: '45px', left: 0}}>
                        {openSubMenu === 'reader' && <ReaderNav t={t} handleClickInner={handleClickInner}/>}
                    </Col>
                </Col>
               {/* <Col className={`py-2 mainNavLink position-relative ${openSubMenu === 'app' && 'active'}`}>
                    <Link to={'#'}  onClick={() => handleLinkClick('app')}>
                        {t("app")}
                    </Link>
                </Col>*/}
                <Col className={`py-2 mainNavLink position-relative ${openSubMenu === 'sensor' && 'active'}`}>
                    <Link to={'#'}  onClick={() => handleLinkClick('sensor')}>
                        {t("sensor")}
                    </Link>
                    <Col className={'position-absolute left-0'} style={{top: '45px', left: 0}}>
                        {openSubMenu === 'sensor' && <SensorNav t={t} handleClickInner={handleClickInner}/>}
                    </Col>
                </Col>
                <Col className={`py-2 mainNavLink position-relative ${openSubMenu === 'productUse' && 'active'}`}>
                    <Link to={'#'} onClick={() => handleLinkClick('productUse')}>
                        {t("productUse")}
                    </Link>
                    <Col className={'position-absolute left-0'} style={{top: '45px', left: 0}}>
                        {openSubMenu === 'productUse' && <ProductUseNav t={t} handleClickInner={handleClickInner}/>}
                    </Col>
                </Col>
                <Col className={`py-2 mainNavLink position-relative ${openSubMenu === 'moreInformation' && 'active'}`}>
                    <Link to={'#'}  onClick={() => handleLinkClick('moreInformation')}>
                        {t("moreInformation")}
                    </Link>
                    <Col className={'position-absolute left-0'} style={{top: '45px', left: 0}}>
                        {openSubMenu === 'moreInformation' && <MoreInformationNav t={t} handleClickInner={handleClickInner}/>}
                    </Col>
                </Col>
                <Col className={' py-2'} md={1} sm={1}>
                    <Link className={'text-grey'} to={''}>
                        <FaHome className={'fs-4'}/>
                    </Link>
                </Col>

               {/* <Col className={'position-absolute left-0'} style={{top: '58px'}}>
                    <Row>
                        {openSubMenu === 'productOverview' && <ProductOverviewNav t={t} handleClickInner={handleClickInner}/>}
                        {openSubMenu === 'reader' && <ReaderNav t={t} handleClickInner={handleClickInner}/>}
                        {openSubMenu === 'sensor' && <SensorNav t={t} handleClickInner={handleClickInner}/>}
                        {openSubMenu === 'app' && <AppNav t={t} handleClickInner={handleClickInner}/>}
                        {openSubMenu === 'productUse' && <ProductUseNav t={t} handleClickInner={handleClickInner}/>}
                        {openSubMenu === 'moreInformation' && <MoreInformationNav t={t} handleClickInner={handleClickInner}/>}
                    </Row>
                </Col>*/}
            </Row>


        </>

    )
}

export default Header