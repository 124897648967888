import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import parse from "html-react-parser";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import applicationSite from "../../assets/img/Sensor/application-site-selection-sm.png"
import './../../assets/css/Sensor/Sensor.css'
import ModalNote from "../../Components/Modal/ModalNote";
import {HiExclamationTriangle} from "react-icons/hi2";
import {CiCircleInfo} from "react-icons/ci";
const ApplyingSensor = () => {
    const {t, i18n} = useTranslation()
    const [show, setShow]= useState(false);
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const lang = localStorage.getItem('lang');
    const [video, setVideo] = useState(null);

    useEffect(() => {
        setVideo(`/translations/${lang}/video/apply-sensor-sm.mp4`)
    }, [lang])
    useEffect(() => {
        console.log(video)
    }, [video])
    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("applicationSensor")}</h2>

                <p className={'text-orange fw-bold mb-0 mt-4'}> {t("howTo")}</p>
                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{parse(t("applicationSensor_List_1"))}  </span>
                    <span>
                          <HiExclamationTriangle className={'fs-3 text-end text-orange'} onClick={() => {
                              setShow(true)
                              setTitle(t('caution'))
                              setText(t("applicationSensor_Alert_1"))
                          }}/>
                    </span>
                </p>
                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{parse(t("applicationSensor_List_2"))}  </span>
                    <span>
                           <HiExclamationTriangle className={'fs-3 text-end text-orange'} onClick={() => {
                               setShow(true)
                               setTitle(t('caution'))
                               setText(t("applicationSensor_Alert_2"))
                           }}/>
                    </span>
                </p>
                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{parse(t("applicationSensor_List_3"))}  </span>
                    <span>
                         <CiCircleInfo className={'fs-3 ms-2 text-end text-orange'} onClick={() => {
                             setShow(true)
                             setTitle(t('note'))
                             setText(t('applicationSensor_Info'))
                         }}/>
                    </span>
                </p>
                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{parse(t("applicationSensor_List_4"))}  </span>
                </p>
                <hr className={'text-orange my-1 opacity-100 mb-3'}/>


            </Col>
            <Col md={6} className={'align-self-start position-relative'}>

                {video && <video controls height={'auto'} width={'100%'}>

                    <source src={video} type="video/mp4" />
                    Il tuo browser non supporta il tag video.
                </video>}
            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/preparing-application-site'} nextUrl={'/starting-sensor'}/>
            </Col>
            <ModalNote show={show}  handleClose={handleClose} text={text} title={title}></ModalNote>
        </>


    )
}

export default ApplyingSensor