import {Button, Col, Row} from "react-bootstrap";
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import {Link} from "react-router-dom";
import {useState} from "react";
import {useTranslation} from "react-i18next";

const SystemInfo = (props) => {
    const [steps, setStep] = useState(1)
    const {t} = useTranslation()
    const handleNextStep = () => {
        setStep(prevStep => prevStep < 4 ? prevStep + 1 : prevStep)

    }

    const handlePrevStep = () => {
        setStep(prevStep => prevStep > 1 ? prevStep - 1 : prevStep)

    }
    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden align-content-stretch`}>
            <Col md={8} className={'align-items-center'}>
                <b> {t('systemInfo')}</b>
            </Col>
            <Col md={4} className={'text-end px-0 position-relative px-0'}>
                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                        onClick={() => {
                            props.setIsSystemInfo(false)
                        }}>{t("ok")}</Button>
            </Col>
            <Row className={'justify-content-center text-center mb-0 mx-0'}
                 style={steps === 1 ? {visibility: 'hidden'} : {}}>
                <div className={'text-center'}>
                           <span onClick={handlePrevStep}>
                                 <BiSolidUpArrow style={{
                                     fontSize: '30px',
                                     color: 'var(--light-blue)',
                                     filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                                 }}/>
                            </span>
                </div>
            </Row>

            <Col md={12}
                 className={`align-content-start d-inline-flex flex-column col-md-12 justify-content-between `}>

                {steps === 1 && (
                    <>
                        <Row className={'align-items-center mx-0 '}>
                            <Col md={5} className={'px-1'}>
                                <p
                                    className={'small'}>{t("sensorEndInfo")} : </p>


                            </Col>
                            <Col><p className={'small'}> 14/05/2025</p></Col>

                        </Row>
                        <Row className={'align-items-center mx-0  '}>
                            <Col md={5} className={'px-1'}>
                                <p
                                    className={'small'}>{t("readerSN")} : </p>


                            </Col>
                            <Col><p className={'small'}> 14/05/2025</p></Col>

                        </Row>
                        <Row className={'align-items-center mx-0   mb-3'}>
                            <Col md={5} className={'px-1'}>
                                <p
                                    className={'small'}>{t("readerVersion")} : </p>


                            </Col>
                            <Col><p className={'small'}> 1.36A <br/> 0.91</p></Col>

                        </Row>


                    </>
                )}
                {steps === 2 && (
                    <>
                        <Row className={'align-items-center mx-0 '}>
                            <Col md={12} className={'px-1'}>
                                <p
                                    className={'small mb-0'}>{t("sensorSNStatus")} : </p>


                            </Col>
                            <Col><p className={'text-smaller my-0'}> (1) 0MNPQRUVWM <br/>
                                0MNPQRUVWM</p>
                                <p className={'text-smaller my-0'}> (2) 0MNPQRUVWM <br/>
                                    0MNPQRUVWM</p>
                                <p className={'text-smaller my-0'}> (3) 0MNPQRUVWM <br/>
                                    0MNPQRUVWM</p></Col>

                        </Row>
                        <Row className={'align-items-center mx-0  '}>
                            <Col md={5} className={'px-1'}>
                                <p
                                    className={'small'}>{t("sensorVersion")} : </p>


                            </Col>
                            <Col><p className={'small'}> 210</p></Col>

                        </Row>



                    </>
                )}
                {steps === 3 &&
                    (

                        <>
                            <Row className={'align-items-center mx-0   mb-3'}>
                                <Col md={5} className={'px-1'}>
                                    <p
                                        className={'small'}>{t("sensorCount")} : </p>


                                </Col>
                                <Col><p className={'small'}> 7 </p></Col>

                            </Row>
                            <Row className={'align-items-center mx-0   mb-3'}>
                                <Col md={5} className={'px-1'}>
                                    <p
                                        className={'small'}>{t("stripCount")} : </p>


                                </Col>
                                <Col><p className={'small'}> 78 </p></Col>

                            </Row>



                        </>
                    )}


            </Col>
            <Row className={'justify-content-center text-center mb-0 mx-0'}
                 style={steps == 4 ? {visibility: 'hidden'} : {}}>
                <div className={'text-center'}>
                    <span onClick={handleNextStep}>
                    <BiSolidDownArrow style={{
                        fontSize: '30px',
                        color: 'var(--light-blue)',
                        filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                    }}/>

                        </span>

                </div>

            </Row>
            <div>{steps && (
                <p className={'text-end small mb-0'}>{steps} / 3 </p>
            )}</div>
        </Row>
    )

}

export default SystemInfo