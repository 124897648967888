import parse from "html-react-parser";
import { useTranslation as useTranslationBase } from 'react-i18next';

export const useTranslation = (namespace) => {
    const { t: translate } = useTranslationBase(namespace);

    const t = (key, options = {}) => {
        const { settingsImageToken, settingsImageValue } = options;
        let translation = translate(key);

        if (typeof translation === 'string') {
            // Sostituisci il token %SETTINGS_IMG% solo se è stato fornito un valore
            if (settingsImageToken && settingsImageValue) {
                translation = translation.replace(new RegExp(settingsImageToken, 'g'), settingsImageValue);
            }
            return parse(translation);
        } else {
            return translation;
        }
    };

    return { t };
};