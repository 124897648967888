import {Col, Nav, Row, Tab} from "react-bootstrap";

import parse from "html-react-parser";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";

import {useTranslation} from "react-i18next";

import mrIcon from './../../assets/img/Icons/mr.png'
import caution from './../../assets/img/Icons/caution.png'

const ImportantInformations = () => {
    let {t, i18n} = useTranslation();
    const whatToKnowApp_Text = []
    const whatToKnowApp_Text_2 = []
    for (let i = 1; i < 9; i++) {
        whatToKnowApp_Text.push('whatToKnowApp_Text_'+i)
    }
    for (let i = 10; i < 16; i++) {
        whatToKnowApp_Text_2.push('whatToKnowApp_Text_'+i)
    }
    return (<>
            <Col md={12} className={'align-content-start text-start importantInfo'}>
                <h2>{parse(t("safetyInformationsTitle"))}</h2>
                <Row className={'mt-4 mx-0'}>
                    <Col md={12}>
                        <h3> {t("indicationsUse")}</h3>
                        <p className={'fw-bold mb-0'}> {t("freeStyle3SensorUsers")}</p>
                        <p className={'mb-0'}> {t("indicationsUse_Text1")}</p>
                        <p> {t("indicationsUse_Text2")}</p>
                        <p className={'fw-bold mb-0'}> {t("freeStyle3PlusSensorUsers")}</p>
                        <p className={'mb-0'}> {t("indicationsPlusUse_Text1")}</p>
                        <p> {t("indicationsPlusUse_Text2")}</p>

                    </Col>
                    <Col md={12}>

                        <h3> {t("contraindications")}</h3>
                        <p>{t("mriCtDiathermy_Text")}
                        </p>

                    </Col>

                    <Col>

                        <h3> {t("warnings")}</h3>
                        <ul>
                            <li>{t("warnings_List_1")}</li>
                            <li>{t("warnings_List_2")}</li>
                        </ul>

                    </Col>
                    {/*<Tab.Container className={"mt-5"} id="left-tabs-example" defaultActiveKey="importantSafety" fill
                                   justify>
                        <Row>
                            <Col sm={3}>

                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="importantSafety">{t("importantSafety")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="sleeping">{t("contraindications")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="warnings">{t("warnings")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="cautionLimits">{t("cautionLimits")}</Nav.Link>
                                    </Nav.Item>

                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="importantSafety">
                                        <Row className={'mx-0'}>
                                            <Col>

                                                <h3> {t("indicationsUse")}</h3>
                                                <p> {t("indicationsUse_Text1")}</p>
                                                <p> {t("indicationsUse_Text2")}</p>
                                                <p> {t("indicationsUse_Text3")}</p>
                                                <h3> {t("compatiblesDevices")}</h3>
                                                <p> {t("compatiblesDevices_Text1")}</p>
                                                <p> {t("compatiblesDevices_Text2")}</p>
                                                <p> {t("compatiblesDevices_Text3")}</p>
                                                <p> {t("compatiblesDevices_Text4")}</p>

                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="sleeping">
                                        <Row className={'mx-0'}>
                                            <Col>

                                                <h3> {t("contraindications")}</h3>
                                                <p><span
                                                    className={'fw-bold'}>{t("automatedInsulin")}</span> {t("automatedInsulin_Text")}
                                                </p>
                                                <p><img src={mrIcon}/><span
                                                    className={'fw-bold'}> {t("diathermy")}</span> {t("mriCtDiathermy_Text")}
                                                </p>

                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="warnings">
                                        <Row className={'mx-0'}>
                                            <Col>

                                                <h3> {t("warnings")}</h3>
                                                <ul>
                                                    <li>{t("warnings_List_1")}</li>
                                                    <li>{t("warnings_List_2")}</li>
                                                    <li>{t("warnings_List_3")}</li>
                                                    <li>{t("warnings_List_4")}</li>
                                                </ul>

                                            </Col>
                                        </Row>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="cautionLimits">
                                        <Row className={'mx-0'}>
                                            <Col>

                                                <h3> {t("cautionLimits")}</h3>
                                                <p>{t("cautionLimits_Text")}</p>
                                                <h6 className={'fw-bold'}><img src={caution}/> {t("whatToKnow")}</h6>
                                                <p>{t("whatToKnow_Text")}</p>
                                                <p>{t("whatToKnow_Text_2")}</p>
                                                <h6 className={'fw-bold'}><img src={caution}/> {t("whatToKnowApp")}</h6>
                                                <ul>
                                                {whatToKnowApp_Text.map((text, index) => {
                                                    return <li key={index}>{t(text)}</li>
                                                })}
                                                    <li>{t("whatToKnowApp_Text_9")}
                                                    <ul>
                                                        <li>{t("whatToKnowApp_Text_9_1")}</li>
                                                        <li>{t("whatToKnowApp_Text_9_2")}</li>
                                                    </ul>
                                                    </li>
                                                    {whatToKnowApp_Text_2.map((text, index) => {
                                                        return <li key={index}>{t(text)}</li>
                                                    })}
                                                </ul>
                                                <h6 className={'fw-bold'}><img src={caution}/> {t("whatToKnowBefore")}</h6>
                                                <ul>
                                                    <li>{t("whatToKnowBefore_List_1")}</li>
                                                    <li>{t("whatToKnowBefore_List_2")}</li>
                                                    <li>{t("whatToKnowBefore_List_3")}</li>
                                                </ul>
                                                <h6 className={'fw-bold'}><img src={caution}/> {t("whoShouldNotUse")}</h6>
                                                <ul>
                                                    <li>{t("whoShouldNotUse_List_1")}</li>
                                                    <li>{t("whoShouldNotUse_List_2")}</li>
                                                    <li>{t("whoShouldNotUse_List_3")}</li>
                                                </ul>
                                                <h6 className={'fw-bold'}><img src={caution}/> {t("whatShouldKnow")}</h6>
                                                <ul>
                                                    <li>{t("whatShouldKnow_List_1")}</li>
                                                    <li>{t("whatShouldKnow_List_2")}</li>
                                                    <li>{t("whatShouldKnow_List_3")}</li>
                                                    <li>{t("whatShouldKnow_List_4")}</li>
                                                    <li>{t("whatShouldKnow_List_5")}</li>
                                                    <li>{t("whatShouldKnow_List_6")}</li>
                                                    <li>{t("whatShouldKnow_List_7")}</li>
                                                    <li>{t("whatShouldKnow_List_8")}</li>
                                                </ul>
                                                <h6 className={'fw-bold'}><img src={caution}/> {t("whenNotUse")}</h6>
                                                <ul>
                                                    <li>{t("whenNotUse_List_1")}</li>
                                                    <li>{t("whenNotUse_List_2")}</li>
                                                    <li>{t("whenNotUse_List_3")}</li>
                                                </ul>
                                                <h6 className={'fw-bold'}><img src={caution}/> {t("whatKnowSystem")}</h6>
                                                <ul>
                                                    <li>{t("whatKnowSystem_List_1")}</li>
                                                    <li>{t("whatKnowSystem_List_2")}</li>
                                                </ul>
                                                <h6 className={'fw-bold'}><img src={caution}/> {t("whatKnowBeforeApply")}</h6>
                                                <ul>
                                                    <li>{t("whatKnowBeforeApply_List_1")}</li>
                                                    <li>{t("whatKnowBeforeApply_List_2")}</li>
                                                    <li>{t("whatKnowBeforeApply_List_3")}</li>
                                                    <li>{t("whatKnowBeforeApply_List_4")}</li>
                                                    <li>{t("whatKnowBeforeApply_List_5")}</li>
                                                </ul>
                                                <h6 className={'fw-bold'}><img src={caution}/> {t("whenGlucoseDifferent")}</h6>
                                                <ul>
                                                    <li>{t("whenGlucoseDifferent_List")}</li>
                                                </ul>
                                                <h6 className={'fw-bold'}><img src={caution}/> {t("whatToKnowRays")}</h6>
                                                <ul>
                                                    <li>{t("whatToKnowRays_List")}</li>
                                                </ul>
                                                <h6 className={'fw-bold'}><img src={caution}/> {t("whenToRemove")}</h6>
                                                <ul>
                                                    <li>{t("whenToRemove_List_1")}</li>
                                                    <li>{t("whenToRemove_List_2")}</li>
                                                </ul>
                                                <h6 className={'fw-bold'}><img src={caution}/> {t("whatToKnowReader")}</h6>
                                                <ul>
                                                    <li>{t("whatToKnowReader_List_1")}</li>
                                                    <li>{t("whatToKnowReader_List_2")}</li>
                                                    <li>{t("whatToKnowReader_List_3")}</li>
                                                    <li>{t("whatToKnowReader_List_4")}</li>
                                                    <li>{t("whatToKnowReader_List_5")}</li>
                                                </ul>
                                                <h6 className={'fw-bold'}><img src={caution}/> {t("whatToKnowCharging")}</h6>
                                                <ul>
                                                    <li>{t("whatToKnowCharging_List_1")}</li>
                                                    <li>{t("whatToKnowCharging_List_2")}</li>
                                                    <li>{t("whatToKnowCharging_List_3")}</li>
                                                    <li>{t("whatToKnowCharging_List_4")}</li>

                                                </ul>
                                                <p><span className={'fw-bold'}>{t("interferingSubstances")}</span></p>
                                                <p>{t("interferingSubstances_Text")}d</p>


                                            </Col>
                                        </Row>

                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>*/}
                </Row>


            </Col>
            <Col className={'text-start align-self-end'}> <NavigationButtons t={t} prevUrl={'/reviewing-history'}
                                                                             nextUrl={'/reader-symbols'}/></Col>



        </>
    )
}
export default ImportantInformations