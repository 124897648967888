import {Col, Nav, Row, Tab, Tabs} from "react-bootstrap";


import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import {HiExclamationTriangle} from "react-icons/hi2";
import {useEffect, useState} from "react";
import ModalNote from "../../Components/Modal/ModalNote";
import parse from "html-react-parser";
import {CiCircleInfo} from "react-icons/ci";
import LogbookTab from "../../Components/ProductUse/ReviewingHistory/Logbook";
import DailyGraph from "../../Components/ProductUse/ReviewingHistory/DailyGraph";
import AverageGlucose from "../../Components/ProductUse/ReviewingHistory/AverageGlucose";
import DailyPatterns from "../../Components/ProductUse/ReviewingHistory/DailyPatterns";
import TimeInTarget from "../../Components/ProductUse/ReviewingHistory/TimeInTarget";
import LowGlucoseEvents from "../../Components/ProductUse/ReviewingHistory/LowGlucoseEvents";
import SensorUsage from "../../Components/ProductUse/ReviewingHistory/SensorUsage";
import Gmi from "../../Components/ProductUse/ReviewingHistory/Gmi";
import {useTranslation} from "../../Utils/UseTranslation";


const ReviewingHistorySummary = () => {
    const {t, i18n} = useTranslation();
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [step, setStep] = useState(0);
    const [prevUrl, setPrevUrl] = useState( '/reviewing-history')
    const [nextUrl, setNextUrl] = useState('important-informations')
    const handleClose = () => {
        setShow(false)
    }
    useEffect(() => {
        setPrevUrl('/reviewing-history')
        setNextUrl( '/important-informations')
        /*setPrevUrl(step === 0 && '/reviewing-history')
        setNextUrl(step === 1 && '/important-informations')*/
        console.log(step)
    }, [prevUrl, step, nextUrl])
    const travelingByAir_info = parse(t('travelingByAir_info').replace('%CLOCK_IMG%', '<img src="/icons/reader_time.png" />').replace('%SETTINGS_IMG%', '<img src="/icons/settings.png" />'));

    return (
        <>
            <Col md={12} className={'align-content-start text-start'}>
                <Row className={'mx-0'}>
                    <Col><h2>{t("reviewingHistory")}</h2>
                        <p>{t("reviewingHistorySummary")} - {step === 1 ? t('app') : t("reader")}</p></Col>
                </Row>
                <Row className={'mt-4 mx-0'}>
                    <Tab.Container className={"mt-5"} id="left-tabs-example" defaultActiveKey="logbook" fill
                                   justify>
                        <Row>
                            <Col sm={3}>

                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="logbook">{t("logbook")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="dailyGraph">{t("dailyGraph")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="averageGlucose">{t("averageGlucose")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="dailyPatterns">{t("dailyPatterns")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="timeInTarget">{t("timeInTarget")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="lowGlucoseEvents">{t("lowGlucoseEvents")}</Nav.Link>
                                    </Nav.Item>
                                    {step === 1 &&   <Nav.Item>
                                        <Nav.Link eventKey="gmi">{t("gmi")}</Nav.Link>
                                    </Nav.Item>}
                                    <Nav.Item>
                                        <Nav.Link eventKey="sensorUsage">{t("sensorUsage")}</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="logbook">
                                        <LogbookTab step={step}/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="dailyGraph">
                                        <DailyGraph step={step}/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="averageGlucose">
                                        <AverageGlucose step={step}/>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="dailyPatterns">
                                        <DailyPatterns step={step}/>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="timeInTarget">
                                        <TimeInTarget step={step}/>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="lowGlucoseEvents">
                                        <LowGlucoseEvents step={step}/>

                                    </Tab.Pane>
                                    {step === 1 &&   <Tab.Pane eventKey="gmi">
                                      <Gmi step={step} />

                                    </Tab.Pane>}
                                    <Tab.Pane eventKey="sensorUsage">
                                        <SensorUsage step={step}/>
                                    </Tab.Pane>


                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Row>
                {/*<Tabs
                    defaultActiveKey="batShower"
                    id="uncontrolled-tab-example"
                    className="mb-3 flex-column"
                    fill
                    justify
                >
                    <Tab eventKey="batShower" title={t("batShower")}>
                        <Row className={'mx-0'}>
                            <Col>
                                <p> {t("batShower_Text")}</p>
                                <p><b>{t("note")}:</b>  {t("batShower_Text_2")}</p>
                                <HiExclamationTriangle className={'fs-3 text-end text-orange'} onClick={() => {
                                    setShow(true)
                                    setTitle(t('caution'))
                                    setText(t("settingOptionalAlarms_Alert"))
                                }}/>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="sleeping" title={t("sleeping")}>

                    </Tab>
                    <Tab eventKey="travelingByAir" title={t("travelingByAir")}>

                    </Tab>

                </Tabs>*/}


            </Col>

            <Col className={'text-start align-self-end'}> <NavigationButtons prevUrl={prevUrl} nextUrl={nextUrl} t={t}
                                                                             onClickPrev={() => {
                                                                                 /*setStep(prevState => prevState === 1 && 0);*/
                                                                             }
                                                                             } onClickNext={() => {
              /*  setStep(prevState => prevState === 0 && 1)*/
            }}/></Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title} on></ModalNote>
        </>
    )
}

export default ReviewingHistorySummary